import React, { Component } from 'react';
import {  Button, Grid, TextField } from "@material-ui/core";
import { isNotEmpty, isEmailAddress } from '../utils/Validator.js';
import { getValue } from '../utils/Utils.js';
import AuthenticationService from '../Services/AuthenticationService';
import LoadingMsgBar from "./LoadingMsgBar";
import GlobalMessage from "./GlobalMessage";

window.analytics ={pageName:'Forgot Username'};

class ForgotUsername extends Component {
    processMsg = `Please wait while we process your request.`;
    constructor(props) {
        super(props)
        this.state = {
            globalErrorMessage: '',
            processingMessage: '',
            emailAddress:'',
            emailAddressError: '',
            showProcessingIndicator:false,
            successMsg:'',
            signinMsg1:'',
            signinMsg2:'',
            signinMsg3:'',
            success:false,
            isSubmitEnabled: true
        }
    }



    validate = () => {
        let isValid = true;
        console.log(this.state.emailAddress);
       
        if (!isEmailAddress(this.state.emailAddress)) {
            this.setState({emailAddressError:"Error: Enter a valid Email Address"});
            this.setState({globalErrorMessage: 'An error has occurred. Please address all fields marked with “Error”.', processingMessage:''});
            isValid = false;
        }
        if(isValid){
            const emailObj={"emailAddress":this.state.emailAddress}
            const msg = `An email containing your username has been sent to ${this.state.emailAddress}.`
           
            this.setState({processingMessage:this.processMsg, globalErrorMessage:'',isSubmitEnabled: false});

            AuthenticationService.forgotUsername(emailObj).then((response) => {
               this.setState({processingMessage:'', globalErrorMessage:''});
                this.setState({successMsg:msg, signinMsg1:'Please check your email and ', signinMsg2:'sign in ',  signinMsg3:'with your username and password.'});
                this.setState({success:true,isSubmitEnabled: true});
                console.log("ERROR: " + JSON.stringify(response));
               
            }, (error) => {
                this.setState({processingMessage:''});
                console.log("ERROR: " + JSON.stringify(error));

                const message = getValue(error.response.data.message, '');

                if (message.startsWith("INVALID_EMAIL")) {
                    this.setState({globalErrorMessage: 'Error: Email Address does not exist.', processingMessage:''});
                } else if (message.startsWith("USER_ACCT_NEW")) {
                    this.setState({globalErrorMessage: 'Error: Please access the link in the Registration Email to finish setting up your account or contact your Account Administrator.', processingMessage:''});
                   
                } else {
                    this.setState({globalErrorMessage: 'Error: An error has occured. Please try again or contact Customer Service.', processingMessage:''});                  
                }
                this.setState({isSubmitEnabled: true});
               
            });
        }
    }

   
    
    componentDidMount() { 
        
      
    };

    handleCancel = () => {   
        this.props.history.push({
            pathname: '/wcpp/login'
        })
     }

    checkForEnter = (e) => {
        if(e.key === 'Enter') {
            this.validate();
        }
    }

    render() {
        
        return (
            
            <div>

                <div  >
                <h1 className='App-page-label'>Forgot Username</h1>
                <div hidden={this.state.success} onKeyPress={(e) => this.checkForEnter(e)}>


                    <LoadingMsgBar loadMsg={this.state.processingMessage}/>

                    <GlobalMessage  message={this.state.globalErrorMessage} />
                <div>

                
                <div className='App-success App-font' aria-live='polite'>{this.state.signinMsg}</div>
                    <div className='App-error-font App-error-color App-note-Field'> Note: Fields marked with
                    an asterisk(*) are required.
                </div>

                    <Grid container direction="column">
                        <Grid item  style={{width:'50%', textAlign:'left'}}>
                            <TextField id="emailAddress" name="emailAddress" label="Email Address"
                                error={isNotEmpty(this.state.emailAddressError)} helperText={this.state.emailAddressError} autoFocus
                                onChange={event => this.setState({emailAddress: event.target.value})}
                                inputProps={{ maxLength: 120 }}
                                fullWidth
                                required
                            />
                        </Grid>
                    </Grid>

                   
                </div>

                <nav>
                    <Button disabled={!this.state.isSubmitEnabled} onClick={this.validate} aria-label='Forgot Username: SUBMIT Button'>Submit</Button>
                    <Button variant="outlined" onClick={this.handleCancel} aria-label='Forgot Username: CANCEL Button'>Cancel</Button>
                </nav>
                </div>
              </div>
              
                
              <div style={{ padding: '0rem 1rem 0rem 1rem' }} aria-live='polite'>{this.state.successMsg}</div>
              <div style={{ padding: '0rem 1rem 1rem 1rem' }} aria-live='polite'>{this.state.signinMsg1}<a href='/wcpp/login' className='App-link'>{this.state.signinMsg2}</a>{this.state.signinMsg3}</div>
            
             
        </div>
                
          
        )
    }

    
}
export default ForgotUsername