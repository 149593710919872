// User Stories: 144473

import React, { Component } from 'react';
import { Grid,  Button, TextField } from "@material-ui/core";
import GlobalMessage from '../../GlobalMessage';
import {
    isEmpty, isNotEmpty, isNotEqual, hasLength, getNumericCharacterCount, getUpperCaseCharacterCount, getLowerCaseCharacterCount, containsSpecialCharacter, containsInvalidCharacters
} from '../../../utils/Validator.js';
import { getValue, setInitialFocus } from '../../../utils/Utils.js';
import ResetPasswordService from "../../../Services/ResetPasswordService";
import { NavLink } from "react-router-dom";
import "./ResetPassword.css";
import LoadingMsgBar from "../../LoadingMsgBar";
import PasswordRequirements from "../PasswordRequirements";
import PasswordRequirementsError from "../PasswordRequirementsError";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import InputAdornment from "@material-ui/core/InputAdornment";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import '../PasswordRequirements.css'


class ConfirmPasswordReset extends Component {

    constructor(props) {
        super(props);

        this.state = this.initializeState();
    }

    initializeState() {
        const initialState = {
            step: '',
            globalErrorMessage: '',
            processingMessage: '',
            tempPassword: '',
            activationToken: '',
            password: '',
            verifyPassword: '',
            isSubmitEnabled: true,
            showTempPassword: false,
            showPassword: false,
            showConfirmPassword: false,
            showPwdRequirementsError: false,
            pwdLengthError: false,
            pwdContainsUpperError: false,
            pwdContainsLowerError: false,
            pwdContainsNumberError: false,
            pwdContainsSpclCharacterError: false,
            showUserNameCheck: false,
            pwdContainsUserName: false
            //pwdRepeatedCharactersError: false,
            //pwdKeyboardSeqError: false,
            //pwdCommonWordsError: false
        };

        return initialState;
    }

    componentDidMount() {
        var urlParams = new URLSearchParams(this.props.location.search);

        const expirationTime = getValue(urlParams.get('expire'), 0);

        if (this.isLinkExpired(expirationTime)) {
            this.setState({ step: 'LinkExpired' });
        } else {
            this.setState({
                activationToken: urlParams.get('token'),
                step: 'ConfirmPasswordStep'
            });
        }

        setInitialFocus('div.resetPassword');
    }

    isLinkExpired(expirationTime) {
        return expirationTime < Date.now();
    }

    addErrorMessage(fieldName, errorMessage) {
        this.setState({
            [fieldName]: errorMessage
        });
    }

    clearErrorMessage(fieldName) {
        this.setState({
            [fieldName]: ''
        });
    }

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        if (isEmpty(name)) {
            console.error(`${target.id} has an empty name attribute. Entered: ${value}`);
        }

        this.setState({
            [name]: value
        });
    }

    // messageList = [{field: "fieldName", message: "Error message"}, {field: "fieldName", message: "Error message"}]
    showMessages(messageList) {
        messageList.forEach(message => {
            this.addErrorMessage(message.field, message.message);
        });
    }

    handleCancelButtonOnClick = () => {
        this.navigateToLoginPage();
    }

    handleDoneButtonOnClick = () => {
        this.navigateToLoginPage();
    }

    showProcessingIndicator(shouldShow) {
        this.setState({ processingMessage: shouldShow ? `Please wait while we process your request.` : ''});
    }

    navigateToLoginPage() {
        this.props.history.push('/wcpp/login');
    }

    clearErrorMessages() {
        const errorFieldList = [
            'tempPasswordError',
            'passwordError',
            'verifyPasswordError',
            'globalErrorMessage'
        ];

        errorFieldList.forEach(errorFieldName => {
            this.clearErrorMessage(errorFieldName);
        });
    }

    getFormData() {
        return {
            token: this.state.activationToken.trim(),
            tempPassword: this.state.tempPassword.trim(),
            newPassword: this.state.password.trim()
        }
    }

    // Returns  { hasSyntaxErrors: true, syntaxMessageList: [{field: "fieldName", message: "the message"}]}
    // Returns  { hasSyntaxErrors: false, syntaxMessageList: []}
    doSyntaxValidation() {
        const messageList = [];
        let isValid = false;

        /*if (!isPassword(this.state.tempPassword)) {
            messageList.push({ field: "tempPasswordError", message: "Error: Enter Temporary Password" });
        }*/
        if (isEmpty(this.state.tempPassword)) {
            messageList.push({ field: "tempPasswordError", message: "Error: Enter Temporary Password" });
        }
        if (isEmpty(this.state.password)) {
            messageList.push({ field: "passwordError", message: "Error: Enter New Password" });
        }
        // Brian doing password validation in service
        else if (isNotEqual(this.state.password, this.state.verifyPassword)) {
            messageList.push({ field: "passwordError", message: "Error: Passwords do not match" });
        }

        if (isEmpty(this.state.verifyPassword)) {
            messageList.push({ field: "verifyPasswordError", message: "Error: Confirm New Password" });
        }
      
        if (messageList.length === 0) {
            isValid = true;
        }

        return { isValid: isValid, messageList: messageList };
    }

    getPwdValidation() {
        let has8To14Chars = true;
        let hasAtLeastOneUpperCaseLetter = true;
        let hasAtLeastOneLowerCaseLetter = true;
        let hasAtLeastOneNumber = true;
        let hasAtLeastOneSpecialCharacter = true;
        let hasInvalidCharacter = false;
        let validSpclCharacter = true;
       
        this.setState({showPwdRequirementsError: false,
            pwdLengthError: false,
            pwdContainsUpperError: false,
            pwdContainsLowerError: false,
            pwdContainsNumberError: false,
            pwdContainsSpclCharacterError: false,
            pwdContainsUserName: false,
            showUserNameCheck: false
        });
        let password = this.state.password;  

        has8To14Chars = hasLength(password, 8, 20);
        hasAtLeastOneNumber = getNumericCharacterCount(password) > 0;
        hasAtLeastOneUpperCaseLetter = getUpperCaseCharacterCount(password) > 0;
        hasAtLeastOneLowerCaseLetter = getLowerCaseCharacterCount(password) > 0;
        //hasAtLeastOneSpecialCharacter = getSpecialCharacterCount(password) > 0;
        hasAtLeastOneSpecialCharacter = containsSpecialCharacter(password);
        hasInvalidCharacter = containsInvalidCharacters(password);
        validSpclCharacter = (hasAtLeastOneSpecialCharacter && !hasInvalidCharacter);
       
        return { has8To14Chars: has8To14Chars, hasAtLeastOneUpperCaseLetter: hasAtLeastOneUpperCaseLetter,  hasAtLeastOneLowerCaseLetter: hasAtLeastOneLowerCaseLetter, 
            hasAtLeastOneNumber: hasAtLeastOneNumber, 
            hasAtLeastOneSpecialCharacter: validSpclCharacter 
        };
    }

    checkPwdErrors(pwdResults){
        let isValid = true;
        if(!pwdResults.has8To14Chars){
            this.setState({pwdLengthError: true});
            isValid = false;
        }
        if(!pwdResults.hasAtLeastOneUpperCaseLetter){
            this.setState({pwdContainsUpperError: true});
            isValid = false;
        }
        if(!pwdResults.hasAtLeastOneLowerCaseLetter){
            this.setState({pwdContainsLowerError: true});
            isValid = false;
        }
        if(!pwdResults.hasAtLeastOneNumber){
            this.setState({pwdContainsNumberError: true});
            isValid = false;
        }
        if(!pwdResults.hasAtLeastOneSpecialCharacter){
            this.setState({pwdContainsSpclCharacterError: true});
            isValid = false;
        }
        
        return isValid;
    
    }

    validate = () => {
        this.setState({ isSubmitEnabled: false });
        const syntaxResults = this.doSyntaxValidation();
        if (!syntaxResults.isValid) {
                this.showMessages(syntaxResults.messageList);
                this.setState({ isSubmitEnabled: true });
         }
        let pwdResults  = this.getPwdValidation();
        const validPwd = this.checkPwdErrors(pwdResults);
        if(syntaxResults.isValid){
            if(!validPwd){
                this.addErrorMessage("globalErrorMessage", 'New Password does not meet requirements. Please try again.');
                this.addErrorMessage("passwordError", "Error: Password does not meet the Password Requirements");
                this.setState({showPwdRequirementsError:true, isSubmitEnabled: true });
            }
        }
        return (syntaxResults.isValid && validPwd);
    } 
    validatePwdForUserName = () => {
        this.setState({pwdLengthError: false});
        this.setState({pwdContainsUpperError: false});
        this.setState({pwdContainsLowerError: false});
        this.setState({pwdContainsNumberError: false});
        this.setState({pwdContainsSpclCharacterError: false});
        this.setState({pwdContainsUserName:true});
        this.addErrorMessage("globalErrorMessage", 'New Password does not meet requirements. Please try again.');
        this.setState({showUserNameCheck: true, showPwdRequirementsError:true, isSubmitEnabled: true }); 
    }

    completeConfirmPasswordRequest() {
        let formData = null;

        this.clearErrorMessages();

        if (this.validate()) {
            this.showProcessingIndicator(true);

            formData = this.getFormData();

            ResetPasswordService.completeResetPassword(formData).then(() => {
                this.setState({ isSubmitEnabled: true });
                this.showProcessingIndicator(false);
                this.setState({ step: "ThankYou" });
            }, (error) => {
                let message = "";

                if (error.response !== undefined) {
                    message = getValue(error.response.data.message, '');
                }

                this.showProcessingIndicator(false);
                this.setState({ isSubmitEnabled: true });
                this.dealWithBusinessErrors(message);
            });
        }
    }

    dealWithBusinessErrors(errorMessage) {
        if (errorMessage.startsWith("INVALID_PW")) {
            this.addErrorMessage("passwordError", "Error: Password does not meet the Password Requirements");
            this.validatePwdForUserName();
            //this.addErrorMessage("passwordError", "Error: Password cannot contain Username or First Name or Last Name spelled forward or backward.");
            //this.addErrorMessage("globalErrorMessage", 'An error has occurred. Please address all fields marked with “Error”.');
        } else if (errorMessage.startsWith("INVALID_TOKEN")) {
            this.addErrorMessage("tempPasswordError", "Error: Your Temporary Password does not match our records. Please check the Temporary Password and try again.");
            this.addErrorMessage("globalErrorMessage", 'An error has occurred. Please address all fields marked with “Error”.');
        } else if (errorMessage.startsWith("EXPIRED_TOKEN")) {
            this.addErrorMessage("tempPasswordError", "Error: Your Temporary Password has expired, please reset your password again");
            this.addErrorMessage("globalErrorMessage", 'An error has occurred. Please address all fields marked with “Error”.');
        } else {
            this.addErrorMessage("globalErrorMessage", "Error: An error has occurred. Please try again or contact Customer Service.");
        }
    }

    handleSubmitButtonOnClick = () => {
        this.completeConfirmPasswordRequest();
    }

    handleClickShowTempPassword= (event) => {
        console.log('password');
        if(this.state.showTempPassword){
            this.setState({showTempPassword : false})
        }
        if(!this.state.showTempPassword){
            this.setState({showTempPassword : true})
        }
      };

    handleClickShowPassword= (event) => {
        console.log('password');
        if(this.state.showPassword){
            this.setState({showPassword : false})
        }
        if(!this.state.showPassword){
            this.setState({showPassword : true})
        }
      };

      handleClickShowConfirmPassword= (event) => {
        console.log('password');
        if(this.state.showConfirmPassword){
            this.setState({showConfirmPassword : false})
        }
        if(!this.state.showConfirmPassword){
            this.setState({showConfirmPassword : true})
        }
      };
      
      handleMouseDownPassword = (event) => {
        event.preventDefault();
      };

    render() {
        if (this.state.step === "LinkExpired") {
            return this.renderLinkExpired();
        } else if (this.state.step === "ConfirmPasswordStep") {
            return this.renderConfirmPasswordForm();
        } else if (this.state.step === "ThankYou") {
            return this.renderThankYou();
        } else {
            return <p>OOPS!</p>
        }
    }

    renderConfirmPasswordForm() {
        return (
            <div className="resetPassword">
                <h1 className="App-page-label">Reset Password</h1>
                <LoadingMsgBar loadMsg={this.state.processingMessage}/>

                <div >
                    <GlobalMessage message={this.state.globalErrorMessage} />

                    <div className='App-error-font App-error-color App-note-Field'>Note: Fields marked with an asterisk(*) are required.</div>

                    <Grid container>
                        <Grid item xs={12} md={5}>
                            <Grid item xs={12}>
                                <TextField id="tempPassword" name="tempPassword" label="Temporary Password"
                                    type={this.state.showTempPassword ? "text" : "password"}
                                    error={isNotEmpty(this.state.tempPasswordError)} helperText={this.state.tempPasswordError}
                                    value={this.state.tempPassword}
                                    onChange={this.handleInputChange}
                                    inputProps={{ maxLength: 23 }}
                                    fullWidth
                                    autoFocus
                                    required
                                    InputProps={{
                                        endAdornment: (
                                          <InputAdornment position="end">
                                            <IconButton disableRipple={true} size="small"
                                            onClick={this.handleClickShowTempPassword}
                                           >
                                              {this.state.showTempPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                          </InputAdornment>
                                        )}}
                                    
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <TextField id="password" name="password" label="New Password"
                                    type={this.state.showPassword ? "text" : "password"}
                                    error={isNotEmpty(this.state.passwordError)} helperText={this.state.passwordError}
                                    value={this.state.password}
                                    onChange={this.handleInputChange}
                                    fullWidth
                                    required
                                    InputProps={{
                                        endAdornment: (
                                          <InputAdornment position="end">
                                            <IconButton disableRipple={true} size="small"
                                            onClick={this.handleClickShowPassword}
                                           >
                                              {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                          </InputAdornment>
                                        )}}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField id="verifyPassword" name="verifyPassword" label="Confirm New Password"
                                     type={this.state.showConfirmPassword ? "text" : "password"}
                                    error={isNotEmpty(this.state.verifyPasswordError)} helperText={this.state.verifyPasswordError}
                                    value={this.state.verifyPassword}
                                    onChange={this.handleInputChange}
                                    fullWidth
                                    required
                                    InputProps={{
                                        endAdornment: (
                                          <InputAdornment position="end">
                                            <IconButton disableRipple={true} size="small"
                                            onClick={this.handleClickShowConfirmPassword}
                                           >
                                              {this.state.showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                          </InputAdornment>
                                        )}}
                                />
                            </Grid>
                        </Grid>

                        <Grid container direction="column" justify="flex-start" alignItems="flex-start" item xs={12} md={7}>
                        {(!this.state.showPwdRequirementsError) ? 
                            <Grid item>
                            <PasswordRequirements/> 
                            </Grid>
                            :
                            <PasswordRequirementsError pwdLengthError={this.state.pwdLengthError} pwdContainsUpperError={this.state.pwdContainsUpperError}
                            pwdContainsLowerError={this.state.pwdContainsLowerError} pwdContainsNumberError={this.state.pwdContainsNumberError}
                            pwdContainsSpclCharacterError = {this.state.pwdContainsSpclCharacterError} showUserNameCheck = {this.state.showUserNameCheck} 
                            pwdContainsUserName={this.state.pwdContainsUserName}
                            />
                        }
                        </Grid>
                    </Grid>

                </div>

                <div className="buttonBar">
                    <Button disabled={!this.state.isSubmitEnabled} onClick={this.handleSubmitButtonOnClick}>Submit</Button>
                    <Button variant="outlined" onClick={this.handleCancelButtonOnClick}>Cancel</Button>
                </div>
            </div>
        );
    }

    renderThankYou() {
        return (
            <div className="resetPassword">
                <h1 className="pageTitle">Reset Password</h1>

                <div className="step-main">
                    <p className="globalSuccessMessage">Your password has successfully been changed.</p>

                    <p>Please <NavLink className='Blue-font' to='/wcpp/login'>click here to sign in</NavLink> to the application.</p>

                    <div className="buttonBar">
                        <Button onClick={this.handleDoneButtonOnClick}>Done</Button>
                    </div>
                </div>
            </div>
        );
    }

    renderLinkExpired() {
        return (
            <div className="resetPassword">
                <h1 className="pageTitle">Reset Password</h1>

                <div className="step-main">
                    <p>Your password reset link has expired. Please <NavLink className='Blue-font' to='/wcpp/resetPassword'>click here</NavLink> to restart reset password.</p>
                </div>
            </div>
        );
    }
}

export default ConfirmPasswordReset;
