import React from 'react';
import { Menu as MUIMenu, Button } from '@material-ui/core';

import './MainMenu.css';

  function Menu (props) {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleToggleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (event) => {
        event.preventDefault();
        setAnchorEl(null);
    };
    
    const style = {
        menuButton: {
            color: "#ffffff",
            '&:hover': {
                textDecoration: 'underline'
            }
        },
        menuStyle: {
            display: "inline-block"
        }  
      };
    
    return (
  		<div style={style.menuStyle}>
            <Button style={style.menuButton} className="mainMenuButton" variant="text" aria-controls="simple-menu" aria-haspopup="true" 
            onClick={handleToggleMenu}>
	            {props.title}
	        </Button>
	
			<MUIMenu
			  id={props.id}
	          anchorEl={anchorEl}
	          getContentAnchorEl={null}
	          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
	          open={Boolean(anchorEl)}
	          onClose={handleClose}
			  onClick={handleClose}
	        >
	          {props.children}
	        </MUIMenu>
        </div>
    );
}

Menu.defaultProps = {
    title: 'MenuTitle'
};


export default Menu;
