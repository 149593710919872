import React, {Component, Fragment} from 'react'
import AuthenticationService from "../Services/AuthenticationService";
import './ClntLstResponse.css'
import Excel from "exceljs";
import FileSaver from "file-saver";
import ClientLstRspTable from './ClientLstRspTable'
import Button from "@material-ui/core/Button";




class ClntLstResponse extends Component {

    constructor(props) {
        super(props);
        this.state = {clients:[], clientsFiltered: [],isBusy: true, isDownloadEnabled: true}
    }

    componentDidMount() {
        this.props.toggleLoadMsg(true);
        this.handleSearchClient();        
    }
   

    handleSearchClient = () => {
        let clients = [];

        //console.log(`get clients lob-->${this.props.lob}  provId---->${this.props.provId}  provState---->${this.props.provState} provProduct---->${this.props.prod} provTin---->${this.props.provTin}`)
        AuthenticationService.getClients(this.props.lob, this.props.provId, this.props.provState, this.props.prod.key, this.props.provTin)
            .then((response) => {                                   
                clients = response.data
                if(clients !== null && clients.length > 0) {
                    this.setState({clientsFiltered: clients})  
                    this.setState({clients: clients})  
                    this.setState({isBusy: false})
                    this.props.toggleLoadMsg(false);                                  
                } else {
                    this.setState({isBusy: false})
                    this.props.toggleLoadMsg(false);
                }
                this.props.enableDisableSearch(true)
            }).catch((error) => {    
                this.props.enableDisableSearch(true)           
                console.log(`Error getting clients ${error}`)
            //this.setState({ isBusy: false})
        })


    }

     setfilter = (filtered) => {
        console.log( `in fitler set state`)
        if(filtered && filtered.length > 0)
            this.setState({clientsFiltered:filtered})

    }

    getExportData = () => {
        if(this.state.clientsFiltered && this.state.clientsFiltered.length > 0) {
            return this.state.clientsFiltered;
        } else {
            return this.state.clients;
        }
    }


    getTable = () => {        
        if(this.state.clients.length > 0) {
           return (
              <ClientLstRspTable clients={this.state.clientsFiltered} setFilter={this.setfilter}
                                 unfilteredUsers={this.state.clients} cnt={this.state.clients.length}/>
                 );
        } else if (this.state.isBusy !== true ) {
           return(
               <div className='App-error-font' style={{paddingTop:'1rem'}}>There are no Clients for the selected criteria, please revise your search criteria and try again</div>
           );
        }
    }

    getDate = () => {

        // const month = new Date().getMonth() + 1; //Current Month
        // const year = new Date().getFullYear(); //Current Year
        // const hours = new Date().getHours(); //Current Hours
        // const min = new Date().getMinutes(); //Current Minutes
        // const amPm = new Date().getHours() % 12 || 12;
        // return `${month}/${new Date().getDate()}/${year} ${hours}:${min} ${amPm ? 'AM' : 'PM'}`;
        
        const today = Date.now();
        const formatedDate = new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(today);
        return `${formatedDate}`;
    }

    handleJS = () => {
        this.setState({isDownloadEnabled: false})
        console.log(`Look to see new update clicked download.....`)

        const workbook = new Excel.Workbook();
        const worksheet = workbook.addWorksheet("Client List");

        worksheet.mergeCells('C1', 'D1');
        worksheet.getCell('C1').value = 'Client List'
        worksheet.getCell('C1').font = {bold: true, size: 16};
        worksheet.getRow(11).values = ['Client Name', 'Affiliated With', 'Eligibility Benefits / Compensability Name',
            'Billing and Claims Attention', 'Billing and Claims Address', 'Eligibility Phone', 'Claim Phone', "ECS Payor ID"];

        worksheet.getRow(3).values = ['Line Of Business', this.props.lob];
        worksheet.getRow(4).values = ['Tax ID', this.props.provTin];
        worksheet.getRow(5).values = ['Provider ID', this.props.provId];
        worksheet.getRow(6).values = ['State', this.props.provState];
        worksheet.getRow(7).values = ['Product', this.props.prod.name];
        worksheet.getRow(8).values = ['Date', this.getDate() ];

        for (let i = 3; i < 9; i++) {
            worksheet.getCell('A' + i).font = {bold: true};
        }

        worksheet.columns = [
            {key: 'aeName', width: 35},
            {key: 'affltdPayorName', width: 35},
            {key: 'ecsName', width: 38},
            {key: 'billClmAttntn', width: 30},
            {key: 'billClmAddr', width: 40},
            {key: 'ecsPhoneNum', width: 16},
            {key: 'billClmPhoneNum', width: 12},
            {key: 'ecsPayorId', width: 12}
        ]

        this.getExportData().forEach((clnt) => {
            worksheet.addRow(clnt)
        })
        worksheet.getRow(11).eachCell((cell) => {
            cell.font = {bold: true};
            cell.border = {
                top: {style: 'thin'}, bottom: {style: 'double'},
            };
        });

        workbook.xlsx.writeBuffer()
            .then(buffer => {
                FileSaver.saveAs(new Blob([buffer]), `Client_List.xlsx`)
            })
            .catch(err => {
                console.log('Error writing excel export', err)
            })
            setTimeout(()=> {this.setState({isDownloadEnabled: true})},500);
    }

    render() {
        return (
            <Fragment>
                <div className='Manage-tins-divider' />
                <div hidden={this.props.lob !== 'AUTO' || this.state.clients.length <= 0} style={{paddingBottom:'1rem', paddingTop:'1rem'}}>
                    <div style={{color:'#b0170c',textAlign:'left',fontSize:'13px'}}><span style={{fontWeight: '700'}}>Steerage:</span> Although payers may not actively encourage their injured parties to seek treatment through a participating provider in the Coventry Integrated Network®, injured parties may locate you in a variety of ways including through their group health plan; after being treated by you, through Coventry’s network, for a prior workers’ comp injury; by locating you through an online provider directory or toll free number; or by recommendation of a trusted associate or family member.</div>

                </div>
                <div className='ClntLstResponse-border'/>
                <div  hidden={this.state.isBusy === true}  style={{textAlign:'right', paddingRight:'2%', paddingTop:'1%'}}>

                    <Button disabled={!this.state.isDownloadEnabled} variant="outlined" onClick={this.handleJS } style={{paddingRight:10, width:'15%'}} aria-label='Client List Result: DOWNLOAD Button'>
                    DOWNLOAD
                </Button>
                </div>
                <div className='ClntLstResponse'>
                {this.getTable()}             
                </div>

    </Fragment>
        );
    }
}

export default ClntLstResponse;


