import axios from 'axios';
import {config} from "./ConfigService";

class ResetPasswordService {

    submitResetPasswordRequest(userName, emailAddress) {
        return axios.post(`${config.API_URL}/public/resetPasswordByUser/start`, {userName: userName, emailAddress: emailAddress});
    }

    completeResetPassword(formInfo) {
        return axios.post(`${config.API_URL}/public/resetPassword/complete`, formInfo);
    }

    stub(paramName, value) {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                console.log("setTimeout completed");
                if (value === true) {
                    resolve({ response: { data: { [paramName]: value } } });
                } else {
                    reject({ response: { data: { message: "Something Happened!" } } });
                }
            }, 3000);
        });
    }
}

export default new ResetPasswordService();
