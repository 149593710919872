import axios from 'axios';
import {config} from "./ConfigService";

class FeeScheduleService {
   
    findRateCodesByLOB(productCd,cdpProvId,cpdTin) {
        let url = `${config.API_URL}/feeSchedule/getNationalRateCodes/${productCd}/${cdpProvId}/${cpdTin}`;
        return axios.get(url);
    }

    getFileContent(userName,rqstId) {
        let url = `${config.API_URL}/feeSchedule/returnFileContent/${userName}/${rqstId}`;
        return axios.post(url);
    }

    getUserFeeSchedrequests(userName) {
        let url = `${config.API_URL}/feeSchedule/getUserFeeSchedRequests/${userName}`;
        return axios.get(url);
    }

    postuserFeeSchedRequest(userName,nationalRateCd,cpdProvId,cpdLctnName,lctnZipCd) {
        return axios.post(`${config.API_URL}/feeSchedule/addFeeSchedRequest/${userName}/${nationalRateCd}/${cpdProvId}/${cpdLctnName}/${lctnZipCd}`);
    }
}

export default new FeeScheduleService();
