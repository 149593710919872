// User Stories: 144473

import React, { Component } from 'react';
import { Button, Grid, TextField } from "@material-ui/core";
import { isNotEmpty, isEmpty, isDate, isFutureDate } from '../../../utils/Validator.js';
import { daysBetweenDates, parseDate } from '../../../utils/Utils.js';
import { getEndDtMinPreset, getEndDtMaxPreset} from '../../../utils/Utils';
import BillSearchService from "../../../Services/BillSearchService";
import AuthenticationService from "../../../Services/AuthenticationService";

import "./BillSearch.css";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";


window.analytics = { pageName: 'Bill Search: Provider Information' };
class ProviderSearchInputComponent extends Component {
    constructor(props) {
        super(props);

        this.state = this.initializeState();
        this.billCtrInput = React.createRef();
    }

    // save any changes the user makes into the state variable so you can
    // get back to the state the user was in
    handleInputChange = (event) => {
        let target = event.target;
        let value = target.type === 'checkbox' ? target.checked : target.value;
        let name = target.name;

        this.setState({
            [name]: value
        });
    }

    validate = () => {
        this.props.enableDisableSearch(false);

        let syntaxResults = this.doSyntaxValidation();

        if (!syntaxResults.isValid) {
            this.setGlobalErrorMessage('An error has occurred. Please address all fields marked with “Error”.');
            this.showMessages(syntaxResults.messageList);
            this.props.enableDisableSearch(true);  
        }

        return syntaxResults.isValid;
    }

    showMessages(messageList) {
        messageList.forEach(message => {
            this.addErrorMessage(message.field, message.message);
        });
    }

    addErrorMessage(fieldName, errorMessage) {
        this.setState({
            [fieldName]: errorMessage
        });
    }

    clearErrorMessage(fieldName) {
        this.setState({
            [fieldName]: ''
        });
    }

    componentDidMount() {
        this.props.searchTypeChange('PROVIDER');
        this.loadTinsForLoggedInUser();
        this.props.enableDisableSearch(true);
        setTimeout(()=>{
            if(this.billCtrInput && this.billCtrInput.current)this.billCtrInput.current.focus();},1);
    }

    loadTinsForLoggedInUser() {
        let userName = null;

        userName = AuthenticationService.getLoggedInUserName();

        this.loadTins(userName);
    }

    initializeState() {
        const initialState = {
            processingMessage: 'Please wait while we process your request.',
            wasSubmitted: false,

            tinList: [],

            beginServiceDate: null,
            endServiceDate: null,
            providerFacility: '',
            providerFirstNm: '',
            providerLastNm: '',
            providerFacilityError: '',
            providerFnmError: '',
            providerLnmError: '',
        };

        return initialState;
    }

    // This assumes all data is syntatically valid
    getFormData() {
        let searchParams = null;

        if (this.state.providerFacility === '') {

            searchParams = {
                searchType: "PROVIDER_PHYSICIAN",
                isTinArray: true,
                tinArray: this.state.tinList,
                searchParams: {
                    billingProviderName:'',
                    physicianFirstName: this.state.providerFirstNm.trim(),
                    physicianLastName: this.state.providerLastNm.trim(),
                    beginServiceDate: this.state.beginServiceDate,
                    endServiceDate: this.state.endServiceDate
                }
            };
        } else if (this.state.providerFacility !== '') {
            searchParams = {
                searchType: "PROVIDER_FACILITY",
                isTinArray: true,
                tinArray: this.state.tinList,
                searchParams: {
                    billingProviderName: this.state.providerFacility.trim(),
                    beginServiceDate: this.state.beginServiceDate,
                    endServiceDate: this.state.endServiceDate
                }
            };
        }

        return searchParams;
    }

    clearForm() {
        this.clearFields();
        this.clearErrorMessages();
        this.props.clearSearchResults();
    }

    clearFields() {
        this.setState({
            wasSubmitted: false,
            beginServiceDate: null,
            endServiceDate: null,
            providerFacility: '',
            providerFirstNm: '',
            providerLastNm: '',
            providerFacilityError: '',
            providerFnmError: '',
            providerLnmError: '',
        });
    }

    handleResetButtonOnClick = () => {
        this.clearForm();
    }

    handleSearchButtonOnClick = () => {
        let searchParams = null;

        this.clearErrorMessages();
        this.props.clearSearchResults();

        // Used in setting focus on first error
        this.setState({ wasSubmitted: true });

        if (this.validate()) {
            searchParams = this.getFormData();
            this.props.handleSearch(searchParams);
        }
    }

    isLessWithCard = ( name) => {
        if (name) {
            const len = name.length;

            if (len <= 3 ) {
                if(name[len - 1] === '*') {
                    return true;
                } else if( len < 3) {
                    return true;
                }
            }
        }
        return false;
    }

    doSyntaxValidation() {
        let messageList = [];
        let isValid = true;

        if (isEmpty(this.state.providerFirstNm) && (isEmpty(this.state.providerLastNm))) {
            if(this.state.providerFacility === '') {
               this.setState({providerFacilityError: "Error: Enter Provider First Name and Last Name or Provider Facility Name"});
               isValid = false;
            }
            else if(this.state.providerFacility !== '' && this.isLessWithCard(this.state.providerFacility)) {
                this.setState({providerFacilityError: "Error: Enter at least 3 characters of  Facility Name"});
                isValid = false;
            }
        } else if(!isEmpty(this.state.providerFirstNm) || (!isEmpty(this.state.providerLastNm))) {
            if(isEmpty(this.state.providerFirstNm)) {
                this.setState({providerFnmError: 'Error: Enter Provider First Name'})
                isValid = false;
            } else if(!isEmpty(this.state.providerFirstNm) && this.isLessWithCard(this.state.providerFirstNm)) {
                this.setState({providerFnmError: 'Error: Enter at least 3 characters of First Name'});
                isValid = false;
            }
            if(isEmpty(this.state.providerLastNm)) {
                this.setState({providerLnmError: ' Error: Enter Provider Last Name'})
            }else if(!isEmpty(this.state.providerLastNm) && this.isLessWithCard(this.state.providerLastNm)) {
                this.setState({providerFnmError: 'Error: Enter at least 3 characters of Last Name'});
                isValid = false;
            }
        }

        if (isEmpty(this.state.beginServiceDate)) {
            messageList.push({ field: "beginServiceDateError", message: "Error: Enter Begin Service Date" });
        } else if (!isDate(this.state.beginServiceDate)) {
            messageList.push({ field: "beginServiceDateError", message: "Error: Enter a valid Begin Service Date" });
        } else if (isFutureDate(this.state.beginServiceDate)) {
            messageList.push({ field: "beginServiceDateError", message: "Error: Begin Service Date cannot be a future date" });
        }

        if (isEmpty(this.state.endServiceDate)) {
            messageList.push({ field: "endServiceDateError", message: "Error: Enter End Service Date" });
        } else if (!isDate(this.state.endServiceDate)) {
            messageList.push({ field: "endServiceDateError", message: "Error: Enter a valid End Service Date" });
        } else if (isFutureDate(this.state.endServiceDate)) {
            messageList.push({ field: "endServiceDateError", message: "Error: End Service Date cannot be a future date" });
        }

        let beginServiceDate = parseDate(this.state.beginServiceDate);
        let endServiceDate = parseDate(this.state.endServiceDate);

        if ((beginServiceDate !== null) && (endServiceDate !== null)) {
            if (endServiceDate < beginServiceDate) {
                messageList.push({ field: "endServiceDateError", message: "Error: End Service Date cannot be before Begin Service Date" });
            } else if (daysBetweenDates(beginServiceDate, endServiceDate) > 30) {
                messageList.push({ field: "endServiceDateError", message: "Error: Service Date range cannot exceed more than 30 days" });
            }
        }

        if (messageList.length > 0) {
            isValid = false;
        }

        return { isValid: isValid, messageList: messageList };
    }

    clearErrorMessages() {
        let errorFieldList = [

            'beginServiceDateError',
            'endServiceDateError'

        ];

        errorFieldList.forEach(errorFieldName => {
            this.clearErrorMessage(errorFieldName);
        });
       this.setState( {providerFacilityError: '',
            providerFnmError: '',
            providerLnmError: '',});
        this.clearGlobalErrorMessage();
    }

    loadTins(userName) {

     //   this.props.showProcessingIndicator(true);

        BillSearchService.findTinsForUser(userName).then((response) => {
            let tins =[];
            response.data.forEach(tin =>{
                tins.push(tin.ftin);
            })
           this.setState({ tinList: tins });

        }, (error) => {
            console.log(error);
            this.setGlobalErrorMessage('Error: There was an error retrieving data. Please try again or contact Customer Service.');
        });
    }



    setGlobalErrorMessage(message) {
        this.props.updateGlobalMessage(message, 'error');
    }

    clearGlobalErrorMessage() {
        this.props.updateGlobalMessage('', 'error');
    }


    render() {
        return (
            <div className="providerSearchInputComponent">

                <p className='App-error-font App-note-Field'>Note: Fields marked with an asterisk(*) are required.</p>
                <Grid container direction='column'  item xs={12}>
                <Grid container   spacing={3} style={{paddingBottom:'.75rem'}}>

                    <Grid item xs={12} md={5}>

                            <TextField
                                id="outlined-uncontrolled"
                                label="Provider First Name"
                                defaultValue=" "
                                disabled={this.state.providerFacility !== ''}
                                margin="normal"
                                inputProps={{ maxLength: 40}}
                                variant="outlined"
                                onChange={event => this.setState({providerFirstNm: event.target.value})}
                                value={this.state.providerFirstNm}
                                error={this.state.providerFnmError !== ''}
                                helperText={this.state.providerFnmError}
                                fullWidth
                                inputRef={this.billCtrInput}
                            />

                        </Grid>
                            <Grid item xs={12} md={5}>

                            <TextField
                                id="providerLastNm"
                                label="Provider Last Name"
                                disabled={this.state.providerFacility !== ''}
                                defaultValue=" "
                                inputProps={{ maxLength: 40 }}
                                margin="normal"
                                variant="outlined"
                                onChange={event => this.setState({providerLastNm: event.target.value})}
                                value={this.state.providerLastNm}
                                error={this.state.providerLnmError !== ''}
                                helperText={this.state.providerLnmError}
                                fullWidth
                            />

                            </Grid>
                        </Grid>

                        <div style={{fontWeight: 'bold', fontSize:'13px', paddingTop:'.5rem',paddingBottom:'.5rem'}}>
                            OR
                        </div>


                <Grid item xs={10}>


                            <TextField
                                id="providerFacility"
                                error={this.state.providerFacilityError !== ''}
                                helperText={this.state.providerFacilityError}
                                disabled={this.state.providerLastNm !== '' || this.state.providerFirstNm !== ''}
                                inputProps={{ maxLength: 120}}
                                defaultValue=" "
                                label="Provider Facility Name"
                                margin="normal"
                                fullWidth
                                onChange={event => this.setState({providerFacility: event.target.value})}
                                value={this.state.providerFacility}
                            />


                </Grid>

                <Grid container xs={20} spacing={3}>
                    <Grid item xs={12} md={5} >
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>

                            <KeyboardDatePicker
                                error={isNotEmpty(this.state.beginServiceDateError)} helperText={this.state.beginServiceDateError}
                                value={this.state.beginServiceDate}
                                onChange={ date => {
                                    if(date instanceof Date) {
                                        date.setHours(8, 0, 0,0);
                                        this.setState({beginServiceDate: date});
                                    } else {
                                        this.setState({beginServiceDate: null, endServiceDate:null});
                                    }
                                }}

                                format="MM/dd/yyyy"
                                margin="normal"
                                id="beginServiceDate"
                                label="Begin Service Date"
                                fullWidth
                                disableFuture
                                placeholder='MM/DD/YYYY'
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                                required
                            />

                        </MuiPickersUtilsProvider>

                    </Grid>

                    <Grid item xs={12} md={5}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>

                            <KeyboardDatePicker
                                error={isNotEmpty(this.state.endServiceDateError)} helperText={this.state.endServiceDateError}
                                value={this.state.endServiceDate}
                                onChange={ date => {
                                    if(date instanceof Date) {
                                        date.setHours(8, 0, 0,0);
                                        this.setState({endServiceDate: date});
                                    }
                                }}
                                format="MM/dd/yyyy"
                                margin="normal"
                                id="endServiceDate"
                                label="End Service Date"
                                fullWidth
                                minDate={getEndDtMinPreset(this.state.beginServiceDate)}
                                maxDate={getEndDtMaxPreset(this.state.beginServiceDate, 31)}
                                placeholder='MM/DD/YYYY'
                                KeyboardButtonProps={{
                                    'aria-label': 'change date'
                                }}

                                disabled={!isNotEmpty(this.state.beginServiceDate)}
                                required
                            />

                        </MuiPickersUtilsProvider>

                    </Grid>
                </Grid>
                </Grid>
                <div className="buttonBar">
                    <Button disabled={!this.props.isSearchEnabled} onClick={this.handleSearchButtonOnClick} aria-label='Bill Search: Provider Information SEARCH Button'>Search</Button>
                    <Button variant="outlined" onClick={this.handleResetButtonOnClick} aria-label='Bill Search: Provider Information RESET Button'>Reset</Button>
                </div>
            </div>
        );
    }
}

export default ProviderSearchInputComponent;
