import React, {Component, Fragment} from 'react';
import Grid from "@material-ui/core/Grid";
import {  Button, TextField, MenuItem, withStyles } from "@material-ui/core";
import AuthenticationService from "../../Services/AuthenticationService";
import './UserProfile.css';

import { isNotEmpty,  isAlpha, isEmailAddress, validatePhone, hasLength, isName, isEmpty} from '../../utils/Validator.js';
import LoadingMsgBar from "../LoadingMsgBar";
import GlobalMessage from "../GlobalMessage";

const styles = () => ({
    root: {
        width: '100%',
    },

});
window.analytics ={pageName:'Add User'};
class AddUser extends Component {
    constructor(props) {
        super(props);
        this.state = {
           userDetailsInfo: null, firstName: '',  lastName: '',  middleInitial : '', phoneNumber : '', isPhoneNumValid : true, 
           emailAddress : '', enlyteEmpId: '',
           securityQuestionList : [],  secQuestionId : 1,  accountTypeList : [], accountType:'Select One',
           valid: true, securityQuestion : '',
           progMsg:'',successMsg: '', errorMsg: '', loadingMessage: '',
           isNextEnabled: true
        }
        

        this.usrfirstnameInput = React.createRef();
    };

    addErrorMessage(fieldName, errorMessage) {
        this.setState({
            [fieldName]: errorMessage
        });
    }

    clearErrorMessage(fieldName) {
        this.setState({
            [fieldName]: ''
        });
    }

    // save any changes the user makes into the state variable so you can
    // get back to the state the user was in
    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checkecd : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    showLoadingIndicator(shouldShow) {
        this.setState({ loadingMessage: shouldShow === true ? `Please wait while we validate the data.` : '' });
    }

    clearErrorMessages() {
        const errorFieldList = [
           
            'firstNameError',
            'lastNameError',
            'middleInitialError',
            'phoneNumberError',        
            'emailAddressError',
            'enlyteEmpIdError',
            'accountTypeError',
           
        ];

        errorFieldList.forEach(errorFieldName => {
            this.clearErrorMessage(errorFieldName);
        });
    }

    handleCancel = () => {
        this.props.history.push({
            pathname: '/wcpp/ManageUsers'
        })
    }
    getFormData() {
        const plainPhoneNm = this.state.phoneNumber.replace(/[^\d]/g, "");

        return {
            userFirstName: this.state.firstName.trim(),
            userLastName: this.state.lastName.trim(),
            userMidName: this.state.middleInitial.trim(),
            emailAddr: this.state.emailAddress.trim(),
            phoneNum: plainPhoneNm,
            typeCd: this.state.accountType.trim(),
            grpInfoId: this.state.userDetailsInfo.grpInfoId,
            mgrUserInfoId: this.state.userDetailsInfo.id,
            statusDesc: this.state.userDetailsInfo.userNm,
            userFtins:[{"ftin":"160743029"},{"ftin":"231352213"}],
            employeeId: ((this.state.enlyteEmpId === "") ? null : this.state.enlyteEmpId),

        }
    }

     handleReset = () => {
        this.clearErrorMessages();
        this.setState({
             firstName: '',  lastName: '',  middleInitial : '', phoneNumber : '', isPhoneNumValid : true,
             emailAddress : '', enlyteEmpId: '', accountType:'Select One',
             valid: true, securityQuestion : '',
             progMsg:'',successMsg: '', errorMsg: '', loadingMessage: '',
             isNextEnabled: true
         });

     }

     handleCreate = () => {
        console.log(`create new user ===>${JSON.stringify(this.getFormData())}`)
         const newUserDetails = this.getFormData();
         AuthenticationService.createNewUser(newUserDetails)
             .then(response => {
                 if(response.status === 200) {
                     this.handleReset();
                     this.setState({successMsg: 'User has been successfully created!'})
                 }


             }).catch((error) => {
                if(null!= error.response){
                    if(null!=error.response.data){
                        if(error.response.data.message === 'INVALID_ENLYTE_EMPLOYEE_ID'){
                            this.setState({ errorMsg: `Error: An error has occurred. Please try again or contact Customer Service.`}); 
                            this.addErrorMessage("enlyteEmpIdError","Error: Enter a valid Enlyte Employee ID");
                        }
                    }
                }
             console.log(`update user from settings ${error.message}`);
             this.setState({ errorMsg: `Error: An error has occurred. Please try again or contact Customer Service.`});
             this.setState({isNextEnabled:true})
             console.log(`update user ${error}`);
         })

     }

     handleNext = () => {
        this.setState({errorMsg: '',successMsg: ''});
       const isValidSyntax = this.doSyntaxValidation();
      
       if(isValidSyntax){
            this.setState({isNextEnabled: false})
           const emailaddr = this.state.emailAddress;
           AuthenticationService.checkEmailAddressExists(emailaddr).then((response) => {
            const emailExist = response.data;
            if(emailExist){
                this.setState({isNextEnabled: true})
                this.setState({errorMsg: `An error has occurred. Please address all fields marked with “Error”.`, emailAddressError: `Error: The Email Address is associated to another account`});
               }else{
                if(this.props.isAdmin) {
                    this.handleCreate();
                } else {
                    this.gotoNextPage();
                }
               }
           
        }, (error) => {
            console.log(error);
            this.setState({progMsg:'', errorMsg: `Error: An error has occurred. Please try again or contact Customer Service.`})
        });
     }
     else{
        this.setState({errorMsg: `An error has occurred. Please address all fields marked with “Error”.`});
        }  
    }
    

    gotoNextPage(){
            const prvScreen = {"firstName": this.state.firstName, "lastName": this.state.lastName, "midIntl":this.state.middleInitial, "phone": this.state.phoneNumber,
            "email":this.state.emailAddress, "enlyteEmpId":this.state.enlyteEmpId, "acctType":this.state.accountType, "typeCd": this.state.accountType, "loggedInUserInfo": this.state.userDetailsInfo};
           this.props.history.push({
            pathname: '/wcpp/AddUserManageTin', state: {previousScreen: prvScreen}
            })
        }
        
        
    
    

    handleOnBlurPhoneNum=(event) =>  {
        const changed = event.target.value;
        this.setState({phoneNumber : changed});
        if(changed.length < 10){
            
            this.setState({valid:false});
        }
        else{
            const phone = validatePhone(changed)
            if(phone.length < 10){
                
                this.setState({valid:false});
            }
            else
            {
                this.setState({phoneNumber : phone});
                this.setState({isPhoneNumValid:true, valid:true, phoneNumberError:''});
            }
        }
    }

    doSyntaxValidation() {
        let isValid = true;
        this.clearErrorMessages();

        /*if (!isAlpha(this.state.firstName)) {
            this.addErrorMessage("firstNameError", "Error: Enter First Name" );
            isValid = false;
        }
        if (!isAlpha(this.state.lastName)) {
            this.addErrorMessage("lastNameError","Error: Enter Last Name");
            isValid = false;
        }*/

        if (!isName(this.state.firstName) || !hasLength(this.state.firstName, 1, 40)) {
            this.addErrorMessage("firstNameError", "Error: Enter valid First Name" );
            isValid = false;
        }

        if (!isName(this.state.lastName) || !hasLength(this.state.lastName, 1, 40)) {
            this.addErrorMessage("lastNameError","Error: Enter valid Last Name");
            isValid = false;
        }

        if (isNotEmpty(this.state.middleInitial) && !isAlpha(this.state.middleInitial)) {
            this.addErrorMessage("middleInitialError","Error: Enter Middle Initial" );
           
        }

        if ( validatePhone (this.state.phoneNumber) === '') {
            this.addErrorMessage("phoneNumberError", "Error: Enter a valid Phone Number");
            isValid = false;
        }
        if(this.props.isAdmin) {
                if (isEmpty(this.state.enlyteEmpId) ) {
                    this.addErrorMessage("enlyteEmpIdError","Error: Enter a valid Enlyte Employee ID");
                    isValid = false;
                }
        }
      
        if (!isEmailAddress(this.state.emailAddress)) {
            this.addErrorMessage("emailAddressError","Error: Enter a valid Email Address");
            isValid = false;
        }
        if(this.state.accountType === 'Select One'){
            this.addErrorMessage("accountTypeError","Error: Select Account Type");
            isValid = false;
        }
            
        return isValid;

    }

    getUser = ()=> {

        AuthenticationService.getUsrDtls(AuthenticationService.getLoggedInUserName())
            .then((response) => {
                this.setState({userDetailsInfo: response.data})
                //console.log(`header user dtls'===>${JSON.stringify(this.state.userDetailsInfo)}`);
            }).catch(function (error) {
            console.log('header getting usr dtl error:' + error);
        })
        return true
    }

   
    loadAccountTypes() {
        const accountTypList = [];
        let typeList = null;

        if(this.props.isAdmin) {
            accountTypList.push({
                value: 'IRAD',
                label: 'Internal Read-Only Admin'
            });
            accountTypList.push({
                value: 'ISAD',
                label: 'Internal Administrator'
            });
            this.setState({ accountTypeList: accountTypList });
            return;
        }
        AuthenticationService.getUserAccountTypes().then((response) => {

            typeList = response.data;
            
            if (isNotEmpty(typeList)) {

                typeList.forEach(type => {
                    
                    accountTypList.push({
                        value: type.userTypeCd,
                        label: type.userTypeDesc
                    });
                   
                  
                });
            }

            this.setState({ accountTypeList: accountTypList });
            
        }, (error) => {            
            console.log(error)
            this.setState({progMsg:'', errorMsg: `Error: An error has occurred. Please try again or contact Customer Service.`})
            this.setState({ accountTypeList: accountTypList });
        });
    }

    componentWillUnmount() {

    }
    

    componentDidMount() { 
        this.loadAccountTypes();
        this.setState({accountType: 'Select One'});
        this.getUser();
        this.setState({isNextEnabled: true})
        if(this.props.location.state !== undefined){
            const loadPrvScreen = this.props.location.state.previousScreen;
             if(loadPrvScreen !== null){
                this.setState({firstName:loadPrvScreen.firstName, lastName:loadPrvScreen.lastName, middleInitial:loadPrvScreen.midIntl, phoneNumber:loadPrvScreen.phone,
                    emailAddress:loadPrvScreen.email, accountType:loadPrvScreen.acctType});
             }
        }
    };

    showSuccessErrorMessage = () =>{
        return(
            <>
                <LoadingMsgBar loadMsg={this.state.loadingMessage}/>
                <GlobalMessage  message={this.state.errorMsg} />
                <GlobalMessage type ='success' message={this.state.successMsg} />

            <div className='App-note-Field App-error-font App-error-color'>
                Note: Fields marked with an asterisk(*) are required.
            </div></>
        );
    }
    showButtons =() =>{
        if(this.props.isAdmin) {
            return(
            <nav>
                <Button disabled={!this.state.isNextEnabled} onClick={this.handleNext}>CREATE USER</Button>
                <Button variant="outlined" onClick={this.handleReset}>RESET</Button>
            </nav>
            );
        }
        return(
            <nav>
            <Button disabled={!this.state.isNextEnabled} onClick={this.handleNext}>NEXT</Button>
            <Button variant="outlined" onClick={this.handleCancel}>CANCEL</Button>
        </nav>
        );
    }
    
    render() {

        return(
            
            <Fragment>

               <div>
                   <h1 className='App-page-label'>{this.props.isAdmin ? '' : 'Manage Users:'} Add User </h1>

                   {this.showSuccessErrorMessage()}

                <div className="step-main">
                <Grid container direction="column">      

                     <Grid item style={{width:'50%', textAlign:'left'}}>
                         <TextField id="firstName" name="firstName" label="First Name"
                             error={isNotEmpty(this.state.firstNameError)} helperText={this.state.firstNameError}  autoFocus 
                             value={this.state.firstName}
                             onChange={this.handleInputChange}
                             inputProps={{ maxLength: 40 }}
                             fullWidth
                             required
                         />
                     </Grid>

                     <Grid item style={{width:'50%', textAlign:'left'}}>
                         <TextField id="lastName" name="lastName" label="Last Name"
                             error={isNotEmpty(this.state.lastNameError)} helperText={this.state.lastNameError}
                             value={this.state.lastName}
                             onChange={this.handleInputChange}
                             inputProps={{ maxLength: 40 }}
                             fullWidth
                             required
                         />
                     </Grid>

                     <Grid item style={{width:'50%', textAlign:'left'}}>
                         <TextField id="middleInitial" name="middleInitial" label="Middle Initial"
                             error={isNotEmpty(this.state.middleInitialError)} helperText={this.state.middleInitialError}
                             value={this.state.middleInitial}
                             onChange={this.handleInputChange}
                             inputProps={{ maxLength: 1 }}
                             fullWidth
                         />
                     </Grid>

                     <Grid item style={{width:'50%', textAlign:'left'}}>
                         <TextField id="phoneNumber" name="phoneNumber" label="Phone Number"
                             error={isNotEmpty(this.state.phoneNumberError)} helperText={this.state.phoneNumberError}
                             value={this.state.phoneNumber}
                             onChange={this.handleInputChange} onBlur={this.handleOnBlurPhoneNum}
                             inputProps={{ maxLength: 15 }}
                             fullWidth
                             required
                         />
                     </Grid>

                     <Grid item style={{width:'50%', textAlign:'left'}}>
                         <TextField id="emailAddress" name="emailAddress" label="Email Address"
                             error={isNotEmpty(this.state.emailAddressError)} helperText={this.state.emailAddressError}
                             value={this.state.emailAddress}
                             onChange={this.handleInputChange}
                             inputProps={{ maxLength: 120 }}
                             fullWidth
                             required
                         />
                     </Grid>
                     {(this.props.isAdmin) ?
                     <Grid item style={{width:'50%', textAlign:'left'}}>
                         <TextField id="enlyteEmpId" name="enlyteEmpId" label="Enlyte Employee ID"
                             error={isNotEmpty(this.state.enlyteEmpIdError)} helperText={this.state.enlyteEmpIdError}
                             value={this.state.enlyteEmpId}
                             onChange={this.handleInputChange}
                             inputProps={{ maxLength: 120 }}
                             fullWidth
                             required
                         />
                     </Grid> : null} 
                    
                     <Grid item style={{width:'50%', textAlign:'left'}}>
                            <TextField name="accountType" id="accountType" label="Account Type"
                                error={isNotEmpty(this.state.accountTypeError)} helperText={this.state.accountTypeError}
                                defaultValue="Select One"
                                select
                                value={this.state.accountType}
                                onChange={this.handleInputChange}
                                fullWidth
                                required
                            >
                                 <MenuItem key={'Select One'} value={'Select One'}>
                                        {'Select One'}
                                    </MenuItem>
                                {this.state.accountTypeList.map(type => (
                                    <MenuItem key={type.value} value={type.value}>
                                        {type.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>

                   

                 </Grid>
            </div>
   
  
               {this.showButtons()}
                </div>
            </Fragment>
         
        );

    }
}
export default withStyles(styles)(AddUser);