import "./Registration.css";
import React, { Component } from 'react';
import "./Registration.css";
import {NavLink} from "react-router-dom";
import Button from "@material-ui/core/Button";


window.analytics = { pageName: 'Registration Complete' };

class RegistrationComplete extends Component {

    render() {

        return(


                <div >
                    <h1 className="pageTitle">Coventry Provider Portal Registration</h1>

                    <p>Thank you for registering. Please <NavLink className='Blue-font' to='/wcpp/login'>click here</NavLink> to sign in to the application.</p>

                    <NavLink to='/wcpp/login' className='Header-icon-link' aria-label='Opens Registration complete as current window'>
                        <div className="buttonBar">
                            <Button >Done</Button>
                        </div>
                    </NavLink>

                </div>


        );

    }

}
 /*   constructor(props) {
        super(props);

    }


    render() {

        return(

        <div className="registration">
            <h1 className="pageTitle">Coventry Provider Portal Registration</h1>

            <p>Thank you for registering. Please <NavLink className='Blue-font' to='/wcpp/login'>click here</NavLink> to sign in to the application.</p>

            <div className="buttonBar">
                <Button onClick={ this.props.history.push('/wcpp/login')}>Done</Button>
            </div>
        </div>

    );
   }
}*/
export default RegistrationComplete;