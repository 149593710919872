import React, {useEffect} from 'react';
import './LiveChat.css'
import useScript from 'react-script-hook';




export default function LiveChat(props) {
    useScript('https://service.force.com/embeddedservice/5.0/esw.min.js');

    useEffect(() => {
        startChat()
    },[]);
  
    const startChat = () => {
        if (!window.embedded_svc) {
            var s = document.createElement('script');
            s.setAttribute('src', 'https://mitchell.my.salesforce.com/embeddedservice/5.0/esw.min.js');
            s.onload = function() {
                initESW(null);
            };
            document.body.appendChild(s);
        } else {
            initESW('https://service.force.com');
        }
    }



    return (
        <div className='LiveChat' style={{display:'inline-block'}}>



        </div>
    );

};


function initESW(gslbBaseURL){

    window.embedded_svc = window.embedded_svc || {};
    window.embedded_svc.settings.displayHelpButton = true; //Or false
    window.embedded_svc.settings.language = ''; //For example, enter 'en' or 'en-US'

    window.embedded_svc.settings.defaultMinimizedText = 'Tech Support'; //(Chat)
    //embedded_svc.settings.disabledMinimizedText = '...'; //(Defaults to Agent Offline)

    //embedded_svc.settings.loadingText = ''; //(Defaults to Loading)
    //embedded_svc.settings.storageDomain = 'yourdomain.com'; //(Sets the domain for your deployment so that visitors can navigate subdomains during a chat session)

    // Settings for Chat
    //embedded_svc.settings.directToButtonRouting = function(prechatFormData) {
    // Dynamically changes the button ID based on what the visitor enters in the pre-chat form.
    // Returns a valid button ID.
    //};
    window.embedded_svc.settings.prepopulatedPrechatFields = {
        SuppliedName: window.chatUser.userFirstName + ' '+ window.chatUser.userLastName,
        SuppliedEmail: window.chatUser.emailAddr,
        FirstName: window.chatUser.userFirstName, // gets sent to agent in Contact Details
        LastName: window.chatUser.userLastName,  // gets sent to agent in Contact Details
        Email: window.chatUser.emailAddr, // gets sent to agent in Contact Details
        Phone: window.chatUser.phoneNum,
        type: window.chatUser.typeCd

    }; //Sets the auto-population of pre-chat form fields
    //embedded_svc.settings.fallbackRouting = []; //An array of button IDs, user IDs, or userId_buttonId
    window.embedded_svc.settings.offlineSupportMinimizedText = 'Contact Us'; //(Defaults to Contact Us)

    window.embedded_svc.settings.enabledFeatures = ['LiveAgent'];
    window.embedded_svc.settings.entryFeature = 'LiveAgent';

    window.embedded_svc.init(
        'https://mitchell.my.salesforce.com',
        'https://mitchell.my.site.com/',
        gslbBaseURL,
        '00D300000000cy9',
        'COV_Provider_Portal',
        {
            baseLiveAgentContentURL: 'https://c.la4-c2-ia5.salesforceliveagent.com/content',

            deploymentId: '5720e000000PBY8',
            buttonId: '5737V000000Cb71',
            baseLiveAgentURL: 'https://d.la4-c2-ia5.salesforceliveagent.com/chat',
            eswLiveAgentDevName: 'COV_Provider_Portal',
            isOfflineSupportEnabled: false
        }
    );

};