// User Stories: 133079

import React, { Component } from 'react';
import {  Button, Checkbox, Grid, TextField } from "@material-ui/core";
import GlobalMessage from '../../GlobalMessage';
import { isNotEmpty, isEmailAddress } from '../../../utils/Validator.js';
import { getValue } from '../../../utils/Utils.js';
import RegistrationService from "../../../Services/RegistrationService";
import "./Registration.css";
import LoadingMsgBar from "../../LoadingMsgBar";
window.analytics ={pageName:'Registration: Email Address'};
class Registration extends Component {
    constructor(props) {
        super(props);
        let search = props.location.search;


        const campaign = new URLSearchParams(search).get('campaign');

        this.state = {
            globalErrorMessage: '',
            processingMessage: '',
            step: "EmailStep",
            termsAccepted: false,
            isSubmitButtonDisabled: true,
            emailAddress: '',
            emailAddressError: '',  campaign:campaign
        }
    }

    handleIAgreeCheckboxOnChange = (event) => {
        const target = event.target;

        this.setState({
            termsAccepted: target.checked,
            isSubmitButtonDisabled: !target.checked
        });
    }

    handleCancelButtonOnClick = () => {
        this.navigateToLoginPage();
    }

    handleDoneButtonOnClick = () => {
        this.navigateToLoginPage();
    }

    handleSubmitButtonOnClick = () => {
        this.completeInvitationRequest();
    }

    // save any changes the user makes into the state variable so you can
    // get back to the state the user was in
    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    completeInvitationRequest() {
        this.clearErrorMessages();

        if (this.validate()) {
            this.showProcessingIndicator(true);

            RegistrationService.submitRegistrationRequest(this.state.emailAddress, this.state.campaign).then(() => {
                this.showProcessingIndicator(false);
                this.setState({ step: "ThankYou" });
            }, (error) => {
                console.log("ERROR: " + JSON.stringify(error));
                let message = "";

                if (error.response !== undefined) {
                    message = getValue(error.response.data.message, '');
                }

                if (message.startsWith("INVALID_EMAIL")) {
                    this.addErrorMessage("globalErrorMessage", 'An error has occurred. Please address all fields marked with “Error”.');
                    this.addErrorMessage("emailAddressError", "Error: The Email Address is associated to another account");
                } else {
                    this.addErrorMessage("globalErrorMessage", "An error has occured. Please try again or contact Customer Service");
                }

                this.showProcessingIndicator(false);
                this.setState({ isSubmitButtonDisabled: false});
            });
        }
    }

    navigateToLoginPage() {
        this.props.history.push('/wcpp/login');
    }

    validate = () => {
        this.setState({isSubmitButtonDisabled : true});
        const syntaxResults = this.doSyntaxValidation();

        if (!syntaxResults.isValid) {
            this.addErrorMessage("globalErrorMessage", 'An error has occurred. Please address all fields marked with “Error”.');
            this.showMessages(syntaxResults.messageList);
            this.setState({isSubmitButtonDisabled : false});
        }

        return syntaxResults.isValid;
    }

    doSyntaxValidation() {
        const messageList = [];
        let isValid = false;

        if (!isEmailAddress(this.state.emailAddress)) {
            messageList.push({ field: "emailAddressError", message: "Error: Enter a valid Email Address" });
        }

        if (messageList.length === 0) {
            isValid = true;
        }

        return { isValid: isValid, messageList: messageList };
    }

    // messageList = [{field: "fieldName", message: "Error message"}, {field: "fieldName", message: "Error message"}]
    showMessages(messageList) {
        messageList.forEach(message => {
            this.addErrorMessage(message.field, message.message);
        });
    }

    clearErrorMessages() {
        const errorFieldList = [
            'emailAddressError',
            'globalErrorMessage'
        ];

        errorFieldList.forEach(errorFieldName => {
            this.clearErrorMessage(errorFieldName);
        });
    }

    addErrorMessage(fieldName, errorMessage) {
        this.setState({
            [fieldName]: errorMessage
        });
    }

    clearErrorMessage(fieldName) {
        this.setState({
            [fieldName]: ''
        });
    }

    showProcessingIndicator(shouldShow) {
        this.setState({ processingMessage: shouldShow ? `Please wait while we process your request.` : ''});
    }

    render() {
        if (this.state.step === "EmailStep") {
            return this.renderEmailAddress();
        } else if (this.state.step === "ThankYou") {
            return this.renderThankYou();
        } else {
            return <h1>OOPS!</h1>
        }
    }

    renderEmailAddress() {
        return (
            <div  className='registration'>

                <h1 className="App-page-label">Coventry Provider Portal Registration</h1>
               
                <LoadingMsgBar loadMsg={this.state.processingMessage}/>

                <div >
                    <GlobalMessage message={this.state.globalErrorMessage} />

                    <p>If you have a Provider Portal Account Administrator for your group, ask them to create an account for you. Otherwise, please enter your email address.</p>

                    <div className='App-error-font App-error-color App-note-Field'>Note: Fields marked with an asterisk(*) are required.</div>

                    <Grid container direction="column">
                        <Grid item xs={12} md={6}>
                            <TextField id="emailAddress" name="emailAddress" label="Email Address"
                                error={isNotEmpty(this.state.emailAddressError)} helperText={this.state.emailAddressError}
                                value={this.state.emailAddress}
                                onChange={this.handleInputChange}
                                inputProps={{ maxLength: 120 }}
                                fullWidth
                                autoFocus
                                required
                            />
                        </Grid>
                    </Grid>

                    <div className="centerBox-vertical">
                        <Checkbox className="centerItem-vertical"
                            id="termsAndConditions" 
                            checked={this.state.termsAccepted}
                            onChange={this.handleIAgreeCheckboxOnChange}
                            
                        />

                        <label htmlFor='termsAndConditions' className="centerItem-vertical">
                            By using the Website I agree to these&nbsp;
                        <a href='https://www.enlyte.com/terms-use' className='App-link' 
                                target="_blank" rel="noopener noreferrer">terms and conditions.</a>
                        </label>
                    </div>

                </div>

                <div className="buttonBar">
                    <Button disabled={this.state.isSubmitButtonDisabled} onClick={this.handleSubmitButtonOnClick} aria-label='Coventry Provider Portal Registration: SUBMIT Button'>Submit</Button>
                    <Button variant="outlined" onClick={this.handleCancelButtonOnClick} aria-label='Coventry Provider Portal Registration: CANCEL Button'>Cancel</Button>
                </div>
            </div>
        );
    }

    renderThankYou() {
        return (
            <div className="registration">

                <h1 className="App-page-label">Coventry Provider Portal Registration</h1>

                <div className="step-main">
                    <p>Thank you for registering.
                       You will receive two separate emails from us shortly, one with your authentication code
                       and the other one with activation link to complete registration.
                       If you have not received your emails within one business day please contact
                       Coventry Provider Portal support at 1-800-937-6824.</p>

                    <div className="buttonBar">
                        <Button onClick={this.handleDoneButtonOnClick}>Done</Button>
                    </div>
                </div>
            </div>
        );
    }
}

export default Registration;
