import React, {Component} from 'react';
import PropTypes from 'prop-types';

import { makeStyles, withStyles, useTheme} from '@material-ui/core/styles';
import { formatDate } from '../utils/Format';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import LastPageIcon from '@material-ui/icons/LastPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";

import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';


function desc(a, b, orderBy) {
    const c = a[orderBy] !== null? a[orderBy].toUpperCase() : a[orderBy];
    const d = b[orderBy] !== null? b[orderBy].toUpperCase() : b[orderBy];
    if (d < c) {
        return -1;
    }
    if (d > c) {
        return 1;
    }
    return 0;
}

function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

const headCells = [
    { id: 'selectAll', numeric: false, disablePadding: true, label: 'Remove', width: '20rem' },
    { id: 'ftin', fid: 'ftin', numeric: false, disablePadding: true, label: 'Tax ID Number (TIN)', width: '40rem', filterLable:'Enter TIN'},
    { id: 'displayDate', fid: 'displayDate', numeric: false, disablePadding: true, label: 'Date Added', width: '40rem', filterLable:'Enter Date', }

];


function EnhancedTableHead(props) {
    const { classes,  order, orderBy,  onRequestSort, onRequestFilter } = props;
    const createSortHandler = property => event => {
        onRequestSort(event, property)
    };
    const onFilter = property => event => {
        onRequestFilter(event.target.value, property);
    };
   
    return (
        <TableHead>
            <TableRow>
               
                {headCells.map(headCell => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={'default'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <Grid container direction="column" style={{verticalAlign:'top'}}>
                            <Grid item>
                                <TableSortLabel
                                    active={orderBy === headCell.id}
                                    direction={order}
                                    onClick={createSortHandler(headCell.id)}
                                >
                                    <div className='Manage-users-tbl-hdr'>
                                        <div>
                                            {headCell.label}

                                            {orderBy === headCell.ftin ? (
                                                <span className={classes.visuallyHidden}>
                                   {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </span>) : null}</div>

                                    </div>
                                </TableSortLabel>
                            </Grid>
                          
                        </Grid>
                    </TableCell>
                ))}
            </TableRow>
            <TableRow className='filter-row' style={{borderTop:'none'}}>
                {headCells.map(headCell => (

                    <TableCell
                        key={headCell.id+1}
                        padding={headCell.disablePadding ? 'none' : 'default'}
                        style={{ maxWidth: headCell.width, paddingBottom:0,borderTop:'none', paddingTop: 0}}
                        autoFocus={headCell.id === 'ftin'}
                    >
                      
                        <Grid container direction="column" style={{verticalAlign:'top'}}>
                        <Grid  item style={{paddingLeft:'.2em',paddingRight:'.3em'}}>
                            {headCell.id === 'selectAll'? 
                           ''
                            :
                                <TextField
                                    id="filterId"
                                    style={{ width: '10rem', maxWidth: headCell.width}}
                                    autoFocus={headCell.id === 'ftin'}
                                    placeholder={headCell.filterLable}
                                    margin="dense"
                                    variant="outlined"
                                    onChange={onFilter(headCell.fid)}/>
                            }
                            </Grid></Grid>
                        

                    </TableCell>

                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
    onRequestFilter: PropTypes.func.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = () => {

    return ({}/*
        <Toolbar
            className={clsx(classes.root, {
                [classes.highlight]: numSelected > 0,
            })}
        >
            {numSelected > 0 ? (
                <Typography className={classes.title} color="inherit" variant="subtitle1">
                    {numSelected} selected
                </Typography>
            ) : (
                <Typography className={classes.title} variant="h6" id="tableTitle">
                    Nutrition
                </Typography>
            )}

            {numSelected > 0 ? (
                <Tooltip title="Delete">
                    <IconButton aria-label="delete">
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>
            ) : (
                <Tooltip title="Filter list">
                    <IconButton aria-label="filter list">
                        <FilterListIcon />
                    </IconButton>
                </Tooltip>
            )}
        </Toolbar>*/
    );
};

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
};

const useStyles1 = makeStyles(theme => ({
    root: {
      flexShrink: 0,
      marginLeft: theme.spacing(2.5),     
    },   
  }));

function TablePaginationActions(props) {
    const classes = useStyles1();
    const theme = useTheme();

    const { count, page, rowsPerPage, onChangePage } = props;

    const handleFirstPageButtonClick = event => {
        onChangePage(event, 0);
    };

    const handleBackButtonClick = event => {
        onChangePage(event, page - 1);
    };

    const handleNextButtonClick = event => {
        onChangePage(event, page + 1);
    };

    const handleLastPageButtonClick = event => {
        onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <div className={classes.root}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}               
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </div>
      );
}


const useStyles = theme => ({
    root: {
        width: '35%',
        marginTop: theme.spacing(3),
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 250,
    },
    tableWrapper: {
        overflowX: 'auto',
    },
    visuallyHidden: {

        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
});

class ManageTinsTable extends Component{
    constructor(props) {
        super(props);
        this.state = {
            order: 'desc' ,orderBy:'createdDate', page: 0, rowsPerPage: 10
        }

    }

    handleRequestSort = (event, property) => {
        const isDesc = this.state.orderBy === property && this.state.order === 'desc';
        this.setState({order: isDesc ? 'asc' : 'desc', orderBy: property});
    }

    handleSelectAllClick = event => {
        if (event.target.checked) {
            const newSelecteds = this.props.rows.map(n => n.ftin);
            this.props.setSelected(newSelecteds);
            return;
        }
        this.props.setSelected([]);
    }

    handleClick = (event, ftin) => {
        const selectedIndex = this.props.selected.indexOf(ftin);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(this.props.selected, ftin);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(this.props.selected.slice(1));
        } else if (selectedIndex === this.props.selected.length - 1) {
            newSelected = newSelected.concat(this.props.selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                this.props.selected.slice(0, selectedIndex),
                this.props.selected.slice(selectedIndex + 1),
            );
        }

        this.props.setSelected(newSelected);
    }

    handleChangePage = (event, newPage) => {
        this.setState({page: newPage})
    }
    handleRequestFilter = (value, property) => {
        if( value.length < 1){
            this.props.setFilter(this.props.rows);
            return true;
        }else{
            this.props.setFilter(this.props.rows.filter(tin => {
              
                let cValue = value.toUpperCase();
                let pValue = tin[property];
                if("displayDate" === property){
                    pValue = formatDate(pValue);
                }
                if(pValue && pValue.toUpperCase().search(cValue) !== -1) {
                    console.log("record number");
                    this.setState({ page: 0 });
                    return tin[property];
                    
                }
                return false;
            }))
        }
       
    }

  
    

    handleChangeRowsPerPage = event => {
        this.setState({page: 0, rowsPerPage: event.target.value})
    }

    isSelected = name => this.props.selected.indexOf(name) !== -1;

    render() {
        const {classes} = this.props;

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                {/*  <EnhancedTableToolbar numSelected={selected.length} />*/}
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25, 50, 100, 200]}
                    component="div"
                    count={this.props.filtered.length}
                    rowsPerPage={this.state.rowsPerPage}
                    page={this.state.page}
                    ActionsComponent={TablePaginationActions}
                    onChangePage={this.handleChangePage}
                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                />
                <div className={classes.tableWrapper}>

                    <Table
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        size='small'
                        aria-label="Manage Tins table"
                    >
                        <EnhancedTableHead
                            classes={classes}
                            numSelected={this.props.selected.length}
                            order={this.state.order}
                            orderBy={this.state.orderBy}
                            onSelectAllClick={this.handleSelectAllClick}
                            onRequestSort={this.handleRequestSort}
                            onRequestFilter={this.handleRequestFilter}
                            rowCount={this.props.filtered.length}
                        />
                        <TableBody>
                            {stableSort(this.props.filtered, getSorting(this.state.order, this.state.orderBy))
                                .slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)
                                .map((row, index) => {
                                    const isItemSelected = this.isSelected(row.ftin);
                                    const labelId = `enhanced-table-checkbox-${index}`;

                                    return (
                                        <TableRow

                                            hover
                                            onClick={event => {
                                                if(!this.props.isMirror) {
                                                    this.handleClick(event, row.ftin)
                                                }
                                            }}
                                            role="checkbox"
                                            aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            key={row.ftin}
                                            selected={isItemSelected}
                                        >
                                            <TableCell padding="checkbox">
                                                <Checkbox disabled={this.props.isMirror}
                                                    checked={isItemSelected}
                                                    inputProps={{'aria-labelledby': labelId}}
                                                />
                                            </TableCell>
                                            <TableCell component="th" id={labelId} scope="row" >
                                                {row.ftin}
                                            </TableCell>
                                            <TableCell component="th" id={labelId} scope="row" >
                                                {(row.displayDate)}
                                            </TableCell>


                                        </TableRow>
                                    );
                                })}

                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 25, 50, 100, 200]}                                    
                                    count={this.props.filtered.length}
                                    rowsPerPage={this.state.rowsPerPage}
                                    page={this.state.page}                                   
                                    onChangePage={this.handleChangePage}
                                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                    ActionsComponent={TablePaginationActions}
                                />
                            </TableRow>
                         </TableFooter>
                    </Table>
                </div>               
            </Paper>

        </div>
    );
  }
}
export default withStyles(useStyles)(ManageTinsTable);
