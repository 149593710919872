import React, { Fragment, Component } from 'react'
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types'
import './ProviderHome.css'
import axios from 'axios'



import {isNotEmpty} from "../../utils/Validator";
import {config} from "../../Services/ConfigService";

const styles = () => ({

});
window.analytics = { pageName: 'Homepage' };
class ProviderHome extends Component {
    // _isMounted = false;
    constructor(props) {
        super(props);

        this.setState = { showImage: true };
    }

    initializeState() {
		const initialState = {
			showImage: false,
			aemURL: config.AEM_HOMEPAGE,
		};
		return initialState;
        /*if (window.location.href.startsWith("http://localhost") ||
            window.location.href.startsWith("http://192.168.1.7")) {
            const initialState = {
                showImage: false,
                // aemURL:'http://localhost:4503/content/wcpp/wcpp_homepage.html',                 
                aemURL: 'https://aem.coventryprovider.com/content/wcpp/wcpp_homepage.html',
            };
            return initialState;
        } else if (window.location.href.startsWith("https://provider-dev")
            || window.location.href.startsWith("https://coventryproviders-uat.cvty.com")) {
            const initialState = {
                showImage: false,
                aemURL: 'https://aemcvtywcsqa.cvty.com/content/wcpp/wcpp_homepage.html',
            };
            return initialState;
        } else if (window.location.href.startsWith("https://www.coventryprovider.com")) {
            const initialState = {
                showImage: false,
                aemURL: 'https://coventrywccontent.cvty.com/content/wcpp/wcpp_homepage.html',
            };
            return initialState;
        } else {
			const initialState = {
                showImage: false,
                aemURL: 'https://aemcvtywcsqa.cvty.com/content/wcpp/wcpp_homepage.html',
            };
            return initialState;
		}*/
    }

    checkAEM = () => {
        axios.get(this.state.aemURL).then(response => {
            this.setState({ showImage: false });
        }).catch(error => {
            this.setState({ showImage: true });
        })
    }

    // Gets called when iFrame is first loaded and after the main ProviderHome window is resized
    // Upon resize ProviderHome sends a message to get the latest size the iFrame should be
    wcppIFrameEvent = (event) => {
        //let clientRect = null;
        let height = null;
        let wcppFrame = document.getElementById("wcppFrame");

        console.log("message received in wcppIFrameEvent: " + event.data);

        if (wcppFrame == null) return;

        //console.log( "event.data: " + event.data );
        if (event.data) {
            try {
                if(isNotEmpty(event.data.height) ){
                    console.log( "event.data.height : " + event.data.height );
                    height = `${event.data.height + 17}px`;
                }
                wcppFrame.style.minHeight = height;
                wcppFrame.style.height = height;

                // clientRect = JSON.parse(event.data);
                // // add to rect height to account for horizontal scrollbar height
                // if ((clientRect !== null) && clientRect.hasOwnProperty("height")) {
                //     height = `${clientRect.height + 17}px`;
                //     wcppFrame.style.minHeight = height;
                // }
            } catch (e) {
                console.log("Error parsing clientRect: " + event.data, e);
                console.log(event.data);
            }
        }
    }


    componentDidMount() {
        // this.checkAEM();
        // if(!this.state.showImage){
        window.addEventListener('message', this.wcppIFrameEvent);
        window.addEventListener('resize', this.debouncedSendMessage);
        // }
    }

    componentWillUnmount() {
        //this._isMounted = false;
        //f(!this.state.showImage){
        window.removeEventListener('message', this.wcppIFrameEvent);
        window.removeEventListener('resize', this.debouncedSendMessage);
        // }
    }


    render() {
        const isAEMDown = false;//this.state.showImage;
        let homePage;
        if (isAEMDown) {
            homePage =
                <div className='App-content'><br />
                    <h1 className='App-page-label'> Welcome to the Coventry Provider Portal</h1>
                    <div className="home-grid-container">
                        <div className="home-grid-item1">
                            <a target="_blank" rel="noopener noreferrer" href='https://aem-qa.coventry.enlyte.com/content/dam/pdf_assets/thought_leadership/Forbes-Opioid-Overdose-Prevention-and-Treatment%2020190513.pdf' >
                                <img src={require("../../images/Marquee.jpg")} alt='homemarquee' aria-label='Open Opioid Overdose Prevention and Treatment PDF in a new window' style={{ width: '100%', height: '100%' }}></img></a></div>
                        <div className="home-grid-item2">

                            <p>
                                Coventry Workers' Comp Services, a division of Coventry Health Care Workers Compensation, Inc., is the leading provider of cost and care management solutions for property and casualty insurance carriers, third-party administrators and self-insured employers. We have created this web-based portal in order to increase ease of communication with the medical provider community servicing claimants whose claims are administered by our clients. Medical providers are invited to use this portal to access bill information, as well as resource links and documents.
                            </p>
                        </div>

                    </div>

                </div>;

        } else {
            homePage = <div className='App-content'><br />
                <div className="home-grid-container">
                    <div className="home-grid-item1">
                        <div className="iframe-container">
                            <iframe title="wcppFrame" name="wcppFrame" id="wcppFrame" className="wcppIFrame"
                                src={config.AEM_HOMEPAGE}>
                            </iframe>
                        </div>
                    </div>
                </div>
            </div>;
        }
        return (
            <Fragment>

                {homePage}
            </Fragment>
        );
    }
}
ProviderHome.propTypes = {
    classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(ProviderHome);
