
import React from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useState} from "react";
import {isNotEmpty} from "../../../../utils/Validator";
import Button from "@material-ui/core/Button";
import LoadingMsgBar from "../../../LoadingMsgBar";
import GlobalMessage from "../../../GlobalMessage";
import {Grid} from "@material-ui/core";
import "./Facility.css";
import FacilityLocationTbl from './FacilityLocationTbl';
import ProviderService from "../../../../Services/ProviderService";
import FacilityLicenseTbl from "./FacilityLicenseTbl";

const Facility = (props) => {

    const[facility, setFacility] = useState(null);
    const[locations, setLocations] = useState([]);
    const[licenses, setLicenses] = useState([]);
    const[filterLocations, setfilterLocations] = useState([]);
    const[filterLicenses, setfilterLicenses] = useState([]);
    const[errormsg, setErrormsg] = useState('');
    const[loadMsg, setLoadMsg] = useState('Loading facility...');


   
  
    const getProviderFacility = () => {

        ProviderService.getProviderFacility(props.selectedPrvId)
            .then((response) => {

                if (isNotEmpty(response.data)) {
                    setFacility(response.data);
                    setLoadMsg('');
                    getProviderFacilityLocations();
                } else {
                    setErrormsg('Error loading Facility data.');
                    setLoadMsg('');
                }

            }).catch((error) => {
            console.log('Error GETTING THE PROVIDER Facility' + error);

        })

    }

    
    const getProviderFacilityLocations = () => {

        if( isNotEmpty(locations))
            return;
        ProviderService.getProviderFacilityLocations(props.selectedPrvId)
            .then((response) => {
                //let isNoProv = true;
                //dispatch({type:"BUSY_MSG",isBusyMsg: ''});
                setLoadMsg('');
                getProviderFacilityLicenses();
                if (isNotEmpty(response.data)) {
                    console.log(`locationsss--->${response.data}`)
                    setLocations(response.data);
                    setfilterLocations(response.data);

                }

            }).catch((error) => {
            console.log('Error GETTING THE PROVIDER Facility' + error);
            setErrormsg(`Error GETTING THE PROVIDER Facility location ${error}`);
        })
    }

    const getProviderFacilityLicenses = () => {

        if(isNotEmpty(licenses))
            return;
        ProviderService.getFacilityLicenses(props.selectedPrvId)
            .then((response) => {
                //let isNoProv = true;
                //dispatch({type:"BUSY_MSG",isBusyMsg: ''});
                setLoadMsg('');
                if (isNotEmpty(response.data)) {
                    setLicenses(response.data);
                    setfilterLicenses(response.data);

                }

            }).catch((error) => {
            console.log('Error GETTING THE PROVIDER Physician licenses' + error);
            setErrormsg('Error loading Physician licenses data.');
        })
    }


    const getGeneralSection = () => {

        return(
            <Accordion defaultExpanded>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"

                >
                    <h5>General Information</h5>
                </AccordionSummary>

                <Grid
                    container

                    justifyContent="space-between"
                    style={{paddingLeft:"1em"}}
                >

                    <Grid item style={{width: "30%"}} justifyContent="left">
                        <h6>Facility Name:</h6>

                    </Grid>
                    
                    <Grid item style={{width: "30%"}} justifyContent="left">
                        <h6>TIN:</h6>

                    </Grid>
                    <Grid item style={{width: "30%"}} justifyContent="left">
                        <h6>NPI:</h6>
                    </Grid>
                   
                   
                </Grid>
               
               
              
               
                <Grid
                    container

                    justifyContent="space-between"
                    style={{paddingLeft:"1em",width: "100%",paddingTop:'.15em',marginTop:'-.7em'}}
                >
                    <Grid item style={{width: "30%"}} justifyContent="left">
                    <span>{`${facility.faclFacilityName}`}</span>
                        </Grid>
                    <Grid item style={{width: "30%"}} justifyContent="left">
                        <span>{`${facility.faclTaxId}`}</span>
                    </Grid>
                    <Grid item style={{width: "30%"}} justifyContent="right">
                        <span>{`${(facility.prvNpi !== null) ? facility.prvNpi : ''}`}</span>
                    </Grid>
                </Grid>

                <div style={{lineHeight:'2em'}}/>
                <Grid
                    container

                    justifyContent="space-between"
                    style={{paddingLeft:"1em",width: "100%",paddingTop:'.5em'}}
                >
                    <Grid item style={{width: "60%"}} justifyContent="left">
                        <h6>LOB:</h6>
                    </Grid>
                </Grid>
                <Grid
                    container

                    justifyContent="space-between"
                    style={{paddingLeft:"1em",width: "100%",paddingTop:'.15em',marginTop:'-.7em',paddingBottom:'2em'}}
                >
                    <Grid item style={{width: "60%"}} justifyContent="left">
                        <span>{`${facility.fLob}`}</span>
                    </Grid>

                </Grid>

            </Accordion>
        );
    }


    return (
            <div className='App-content '>
                <LoadingMsgBar loadMsg={loadMsg}/>

                <GlobalMessage message={errormsg}/>
                {!facility?getProviderFacility():<div>
                <Grid
                    container

                    justifyContent="space-between"
                    style={{width: "100%"}}
                >

                    <Grid item style={{width: "50%"}} justifyContent="left">
                        <h1 className='App-page-label'>{facility?`Facility - ${facility.faclFacilityName}`:''}</h1>
                    </Grid>
                    <Grid item style={{width: "50%"}} justifyContent="right">
                        <Button variant="outlined" onClick={props.back} style={{float:'right'}} aria-label='PROVIDER DEMOGRAPHICS: BACK TO DEMOGRAPHICS SEARCH Button'>Back to Demographic Search</Button>
                    </Grid>
                </Grid>

                    {getGeneralSection()}
                    <Accordion >
            <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel3a-header"
            >
            <h5>Locations</h5>
            </AccordionSummary>
                {locations.length >0 ? <FacilityLocationTbl locations={filterLocations} setFilter={setfilterLocations} filtered={locations}/>:<span/>}
            </Accordion>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                >
                 <h5>Licenses</h5>
                </AccordionSummary>
                 {licenses.length >0 ? <FacilityLicenseTbl licenses={filterLicenses} filtered={licenses} setFilter={setfilterLicenses}/>:<span/>}</Accordion> 

                 <Grid
                        container
                        justifyContent="space-between"
                        style={{width: "100%", paddingTop: "10px"}}
                    >

                        <Grid item style={{width: "50%"}} justifyContent="left">

                        </Grid>
                        <Grid item style={{width: "50%"}} justifyContent="right">
                            <Button variant="outlined" onClick={props.back}  style={{float:'right'}} aria-label='PROVIDER DEMOGRAPHICS: BACK TO DEMOGRAPHICS SEARCH Button'>Back to Demographic Search</Button>
                        </Grid>
                    </Grid>
                </div>}

            </div>

    );

};
export default Facility;