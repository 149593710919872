import { parseDate } from './Utils.js';

export function isEmpty(value) {
    if ((value === undefined) ||
        (value === null) ||
        (value.length === 0)) {
        return true;
    }

    return false;
}

export function isAuthenticationCode(value) {
    // NIYYZ-47W07-VN6B9-STUQ9
    let pattern = /^[A-Z0-9]{5}-[A-Z0-9]{5}-[A-Z0-9]{5}-[A-Z0-9]{5}$/;

    return value.match(pattern);
}

export function isNotEmpty(value) {
    return !isEmpty(value);
}

// that's a big string ~ 9 quadrillion
export function hasLength(value, minLength = 0, maxLength = Number.MAX_SAFE_INTEGER) {
    let valueLength = -1;
    let isMinLengthValid = false;
    let isMaxLengthValid = false;

    if ((value !== undefined) && (value !== null)) {
        valueLength = value.length;

        isMinLengthValid = valueLength >= minLength;
        isMaxLengthValid = valueLength <= maxLength;
    }

    return isMinLengthValid && isMaxLengthValid;
}

export function isAlpha(value) {
    let pattern = /^[a-zA-Z]+$/;

    return value.match(pattern);
}

export function isName(value) {
    let pattern = /^[a-zA-Z' -]+$/;

    return value.match(pattern);
}

export function isAlphanumeric(value) {
    let pattern = /^[a-zA-Z0-9]+$/;

    return value.match(pattern);
}

export function isAlphanumericWithSpace(value) {
    let pattern = /^[a-zA-Z0-9 ]+$/;

    return value.match(pattern);
}

export function isEqual(valueA, valueB) {
    return valueA === valueB;
}

export function isNotEqual(valueA, valueB) {
    return valueA !== valueB;
}

export function isEmailAddress(value) {
    let pattern = /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i;

    return value.match(pattern);
}

export function isNumeric(value) {
    let pattern = /^[0-9]+$/;

    return value.match(pattern);
}

// 9 digit number
export function isTaxId(value) {
    let pattern = /^\d{9}$/;

    return value.match(pattern);
}

// 14 digit number
export function isBillControlNumber(value) {
    let pattern = /^\d{1,14}$/;

    return value.match(pattern);
}

// 9-32 digit number
export function isDocumentId(value) {
    let pattern = /^[a-zA-Z0-9-@_]{1,35}$/;

    return value.match(pattern);
}

// 1-8 digit number
export function isProviderId(value) {
    let pattern = /^\d{1,8}$/;

    return value.match(pattern);
}

export function isUserName(value) {
    let isValid = false;

    isValid = isAlphanumeric(value) && hasLength(value, 6, 14);

    return isValid;
}

export function isPhoneNumber(value) {
    let digits = value.replace(/[^\d]/g, "");

    return hasLength(digits, 10, 10) && isNumeric(digits);
}

export function getNumericCharacterCount(value) {
    let numCount = 0;

    if (isNotEmpty(value)) {
        numCount = value.replace(/[^0-9]/g, "").length;
    }

    return numCount;
}

export function getCharacterCount(value) {
    let numCount = 0;

    if (isNotEmpty(value)) {
        numCount = value.replace(/[^A-Za-z]/g, "").length;
    }

    return numCount;
}

export function getUpperCaseCharacterCount(value) {
    let numCount = 0;

    if (isNotEmpty(value)) {
        numCount = value.replace(/[^A-Z]/g, "").length;
    }

    return numCount;
}

export function getLowerCaseCharacterCount(value) {
    let numCount = 0;

    if (isNotEmpty(value)) {
        numCount = value.replace(/[^a-z]/g, "").length;
    }

    return numCount;
}

export function containsSpecialCharacter(value) {
    let containsSpclCharacter = false;
    //const pattern=/[`~!@#$%^&*()_+-=[\]{}|,.?:;]/;
    let pattern = "`~!@#$%^&*()_+-=[]{}|,.?:;<>'/";
    let patternArr = pattern.split("");
    for (let i = 0; i < patternArr.length; ++i) {
        if (value.includes(patternArr[i])) {
            containsSpclCharacter = true;
            break;
        }
    }
    return containsSpclCharacter;
}

export function containsInvalidCharacters(value){
    let pattern= /["\\]/;
    return pattern.test(value);
}

export function getSpecialCharacterCount(value) {
    let numCount = 0;

    if (isNotEmpty(value)) {
        numCount = value.replace(/[^_&\-#@`~!%*()_+={[}|<,>.?:;^$\]]/g, "").length;
    }

    return numCount;
}



export function passwordContainsUserName(userName, password){
    console.log(userName);
    let doesNotContainUserName = isEmpty(userName) || !((password.toUpperCase()).includes(userName.toUpperCase()));
    return doesNotContainUserName;
}

export function passwordContainsReverseUserName(userName, password){
    let doesNotContainReverseUserName = false;
    if(!isEmpty(userName)){
        console.log(userName);
        let usrName = userName.split("");
        let reverseNameArr = usrName.reverse();
        let reverseName = reverseNameArr.join(""); 
        console.log(reverseName);
        doesNotContainReverseUserName = !((password.toUpperCase()).includes(reverseName.toUpperCase()));
    }
    return doesNotContainReverseUserName;
}

export function hasAllowablePasswordCharacters(value) {
    let numCount = 0;

    numCount = value.replace(/[^a-zA-Z0-9_&\-#@]/g, "").length;

    return numCount === value.length;
}

export function hasNumericalSequence(value, length) {
    const NUMBER_SEQUENCE = "0123456789";
    let hasSequence = false;
  
    hasSequence = hasSequentialCharacters(value, NUMBER_SEQUENCE, length);
  
    return hasSequence;
  }

export function hasSequentialCharacters(value, sequence, length) {
    let hasChars = false;
    let valueSubstr = null;
    let lcValue = value.toLowerCase();

    for (let i = 0; i < lcValue.length; ++i) {
                valueSubstr = lcValue.substr(i, length);

                if (valueSubstr.length < length) {
                  break;
                }

                if (sequence.indexOf(valueSubstr) > -1) {
                  hasChars = true;
                  //   console.log(  "  failed because of: " + valueSubstr);
                  break;
                }
    }

    return hasChars;
  }


export function hasKeyboardCharacterSequence(value, length) {
    let hasSequence = false;
    const KEYBOARD_SEQUENCE = ["1234567890", "qwertyuiop", "asdfghjkl", "zxcvbnm"];

    for (let i = 0; i < KEYBOARD_SEQUENCE.length; ++i) {
                if (hasSequentialCharacters(value, KEYBOARD_SEQUENCE[i], length)) {
                  hasSequence = true;
                  break;
                }
    }
    return hasSequence;
  }

export function hasAlphabeticalCharacterSequence(value, length) {
    const ALPHA_SEQUENCE = "abcdefghijklmnopqrstuvwxyz";
    let hasSequence = false;

    hasSequence = hasSequentialCharacters(value, ALPHA_SEQUENCE, length);

    return hasSequence;
  }

export function getMaxRepeatingCharLength(value) {
    let lcValue = value.toLowerCase();
    let currentChar = null;
    let nextChar = null;
    let maxCount = 0;
    let charCount = 0;

    if (value.length > 0) {
                maxCount = 1;
    }

    for (let i = 0; i < lcValue.length - 1; ++i) {
                currentChar = lcValue[i];
                nextChar = lcValue[i + 1];

                if (currentChar === nextChar) {
                  ++charCount;

                  if (charCount > maxCount) {
                               maxCount = charCount;
                  }
                } else {
                  charCount = 1;
                }
    }

    return maxCount;
  }

export function hasCommonlyUsedWords(value) {
    const COMMON_WORDS = ["admin",
    "aetna",
    "coventry",
    "cvty",
    "cvs",
    "dragon",
    "flower",
    "hello",
    "letmein",
    "monkey",
    "pass",
    "provider",
    "secret",
    "summer",
    "super",
    "test",
    "welcome"];
    let hasCommonWords = false;

    hasCommonWords = COMMON_WORDS.includes(value.toLowerCase());

    return hasCommonWords;
  }
  

/*
Password Requirements:

• Must be 8 to 14 characters
• Must contain at least one upper case character
• Must include one numeric character
• Must contain one lower case character
• Must contain one special character: _&-#@
• Must not contain the username*/
export function isPassword(password, userName) {
    let isValid = false;
    let has8To14Chars = false;
    let hasAtLeastOneNumber = false;
    let hasAtLeastOneUpperCaseLetter = false;
    let hasAtLeastOneLowerCaseLetter = false;
    let doesNotContainUserName = false;
    let hasAtLeastOneSpecialCharacter = false;
    let hasAllowableCharacters = false;

    has8To14Chars = hasLength(password, 8, 14);
    hasAtLeastOneNumber = getNumericCharacterCount(password) > 0;
    hasAtLeastOneUpperCaseLetter = getUpperCaseCharacterCount(password) > 0;
    hasAtLeastOneLowerCaseLetter = getLowerCaseCharacterCount(password) > 0;
    doesNotContainUserName = isEmpty(userName) || !((password.toUpperCase()).includes(userName.toUpperCase()));
    hasAtLeastOneSpecialCharacter = getSpecialCharacterCount(password) > 0;
    hasAllowableCharacters = hasAllowablePasswordCharacters(password);

    isValid = has8To14Chars &&
        hasAtLeastOneNumber &&
        hasAtLeastOneUpperCaseLetter;

    isValid = isValid &&
        hasAtLeastOneLowerCaseLetter &&
        doesNotContainUserName;

    isValid = isValid &&
        hasAtLeastOneSpecialCharacter &&
        hasAllowableCharacters;

    return isValid;
}

export function validatePhone(phone) {
    //normalize string and remove all unnecessary characters
    phone = phone.replace(/[^\d]/g, "");

    //check if number length equals to 10
    if (phone.length === 10) {
        //reformat and return phone number
        return phone.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
    }

    return '';
}

// https://stackoverflow.com/questions/15196451/regular-expression-to-validate-datetime-format-mm-dd-yyyy
//expected input mm/dd/yyyy or mm.dd.yyyy or mm-dd-yyyy or mm dd yyyy
// Just checks if it looks reasonable
export function isDateFormat(value) {
    let pattern = /^(0?[1-9]|1[0-2])[-\\/. ](0?[1-9]|1\d|2\d|3[01])[-\\/. ][0-9]{4}$/;

    if (typeof value === "string") {
        return isEmpty(value) ? false : value.match(pattern);
    } else {
        return false;
    }
}

// value can be a string in mm/dd/yyyy form
// or a date object
export function isFutureDate(value) {
    let isFuture = false;
    let incomingDate = null;
    let today = null;

    if (value instanceof Date) {
        incomingDate = value;
    } else {
        incomingDate = parseDate(value);
    }

    if (incomingDate !== null) {
        today = new Date();

        if (incomingDate > today) {
            isFuture = true;
        }
    }

    return isFuture;
}

//expected input mm/dd/yyyy or mm.dd.yyyy or mm-dd-yyyy or mm dd yyyy
// or any combination of those including single digt month and day such as m/d/yyyy
export function isDate(value) {
    let isValid = false;

    if (value instanceof Date) {
        isValid = value.toString() !== 'Invalid Date';
    } else {
        isValid = parseDate(value) !== null;
    }

    return isValid;
}

export function isInternalUser(emailAdrdr) {
    let isValid = false;
    const internalEmailDomain = ["cvty.us.com",
    "mitchell.com",
    "enlyte.com",
    "apricusinc.com",
    "genexservices.com"];
    if(isEmpty(emailAdrdr)){
        return isValid;
    }
    let emailId = emailAdrdr.toLowerCase();
    for (let i = 0; i < internalEmailDomain.length; ++i) {
        isValid = emailId.includes(internalEmailDomain[i]);
        if(isValid === true )
        { 
            break;
        }
        
    }

    return isValid;
}
