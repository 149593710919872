import React from 'react';
import './PasswordRequirements.css'


export default () =>
    <div className='Password-req'>
    <div className='Password-req-pnl'>

            <p>Password Requirements</p>
            <p>• Must be 8 to 20 characters</p>
            <p>• Must contain at least one upper case character</p>
            <p>• Must contain at least one lower case character</p>
            <p>• Must contain at least one numeric character</p>
            <p>• Must contain at least one special character from the following list: {`\`~!@#$%^&*()-_=+[{]}|;:',<.>/?`}</p>
            <p>• Must not contain Username or First Name or Last Name spelled forward or backward</p>

        </div>
    </div>