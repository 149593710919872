import React, {Component, Fragment} from 'react'
import AuthenticationService from "../../Services/AuthenticationService";
import './ManageTinsForUser.css'
import Button from '@material-ui/core/Button';
import ManageTinsForUserTable from '../ManageTinsForUserTable'
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import {isNotEmpty} from "../../utils/Validator";



window.analytics ={pageName:'Manage Users: Manage TINs'};
class ManageTinsForUser extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ftins: [], ftinsFiltered: [], selected: [], errorMsg: '', showDlgMsg: false
        }
    }
    componentDidMount() {
        this.loadList();
    }

    loadList = () => {
        console.log( `Mange users get tins for user................`)
        AuthenticationService.getFtinsForManageUser( this.props.location.state.user.userNm)
            .then((response) => {
                if (response.status === 200) {
                    this.setState({ftins: response.data, ftinsFiltered: response.data})                    
                    this.setChecked();
                } else {
                    this.setState({errorMsg: `Error: There was an error retrieving data. Please try again or contact Customer Service.`})
                }
            }).catch(function (error) {
            console.log('Manage Ftins for User Error:' + error);
        })
    }

    setChecked = () => {
        const selected = [];
        this.state.ftins.forEach( tin => {
            if(tin.hasIt === true) {
                selected.push(tin.ftin)
            }
        })
        if(selected.length > 0) {
            this.handleSetSelected(selected);
        }
    }

    setfilter = (filtered) => {
        console.log( `in fitler set state`)
        if(filtered && filtered.length > 0)
            this.setState({ftinsFiltered:filtered})

    }

    handleSetSelected = (selected) => {
        this.setState({selected: selected})
    }

    closeMsgDlg = () => {
        this.setState({showDlgMsg:false})
    }

    handleSave = () => {
        const ftins = this.state.ftins;
        ftins.forEach(tin => tin.hasIt = false);
        if(this.state.selected.length === 0){
            this.setState({showDlgMsg: true})
            return;
        }

        this.state.selected.forEach(tin => {
            const checkTin = ftins.find(ftin => ftin.ftin === tin);
            checkTin.hasIt = true;
        })

        AuthenticationService.postFtinsForManageUser(this.props.location.state.user.userNm, ftins)
            .then((response) => {
                if (response.status === 200) {
                    console.log(`Post ftin for Manga DONE`)
                    this.handleCancel()
                } else {
                    this.setState({errorMsg: `Error: An error has occurred.  Please try again or contact Customer Service.`})
                }
            }).catch(function (error) {
            console.log('Post ftin for Mangage Users Error:' + error);
        })

    }

    handleCancel = () => {
        this.props.history.push('/wcpp/ManageUsers')

    }


    render() {


        return(
            <Fragment>

                <div className='App-content ManageTinsForUser'>
                    <h1 className='App-page-label'>
                        {`Manage Users: Manage TINs for ${this.props.location.state.user.userFirstName} ${this.props.location.state.user.userLastName}`}</h1>
                    <div className='App-error App-font' hidden={this.state.errorMsg === ''}>{this.state.errorMsg}</div>
                    <ManageTinsForUserTable tinsFiltered={this.state.ftinsFiltered} setFilter={this.setfilter}
                                            tins={this.state.ftins} setSelected={this.handleSetSelected}
                                            selected={this.state.selected} admin={this.props.location.state.user.typeCd  === 'PRAD'}/>
                    <Grid>
                        <Button  color="primary" disabled={this.props.location.state.user.typeCd  === 'PRAD' || isNotEmpty(AuthenticationService.getMirrorUser())} onClick={this.handleSave}>
                            SAVE
                        </Button>
                        <Button variant="outlined"  onClick={this.handleCancel}>
                            CANCEL
                        </Button>
                    </Grid>

                    <Dialog
                        open={this.state.showDlgMsg}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">{"You cannot remove all TINs."}
                        </DialogTitle>

                            <div style={{textAlign:'center'}}>
                            <Button variant="text"   onClick={this.closeMsgDlg} >
                                OK</Button>
                            </div>

                    </Dialog>
                </div>
            </Fragment>

        );

    }
}
export default ManageTinsForUser;