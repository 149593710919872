import {useEffect,useState} from "react";

import AuthenticationService from "../Services/AuthenticationService";

const useGetTaxIds = () => {
    const [options, setOptions] = useState([]);
    useEffect(() => {
        loadTxIds()
    },[]);

    const loadTxIds = () => {

        AuthenticationService.getUserFtins(AuthenticationService.getLoggedInUserName())
            .then((response) => {
                let txIdOptions = [];
                if(null != response.data){

                    response.data.forEach((record) => {
                       txIdOptions.push(record.ftin);
                    });
                    setOptions(txIdOptions);

                }
            }).catch(function (error) {
            console.log('Demographics Search Error receiving user ftins:' + error);
        })
    };
    return options;
};
export default  useGetTaxIds;