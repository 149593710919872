import axios from 'axios';

class ConfigService {
	instance = this.loadConfig();
    
	async loadConfig() {
		let url = "/js/config.json";
		
		try {
		  console.log("loading Config properties from: " + url);

		  const response = await axios.get(url);
		  Object.assign(this, response.data);
		  console.log("===============================================");
		  console.log(JSON.stringify(this.props));
		} catch (error) {
		  console.error("Failed to load config: " + url, error);
		}
	  }
	  
	getProperty(propName) {
		return this[propName];
	}
  
}

export const config = new ConfigService();