
import React from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useState} from "react";
import ProviderService from "../../../../Services/ProviderService";
import {isNotEmpty} from "../../../../utils/Validator";
import Button from "@material-ui/core/Button";
import LoadingMsgBar from "../../../LoadingMsgBar";
import GlobalMessage from "../../../GlobalMessage";
import {Grid} from "@material-ui/core";

import "./Physician.css"
import PhysicianLicenseTbl from "./PhysicianLicenseTbl";
import PhysicianLocationTbl from "./PhysicianLocationTbl";

import PhysicianSpecialtyTblTbl from "./PhysicianSpecialtyTbl";



const Physician = (props) => {

    const[physician, setPhysician] = useState(null);
    const[locations, setLocations] = useState([]);
    const[licenses, setLicenses] = useState([]);
    const[specialties, setSpecialties] = useState([]);
    const[filterLocations, setfilterLocations] = useState([]);
    const[filterLicenses, setfilterLicenses] = useState([]);
    const[filterSpecialties, setfilterSpecialties] = useState([]);
    const[errormsg, setErrormsg] = useState('');
    const[loadMsg, setLoadMsg] = useState('Loading Physician...');


    const getProviderPhysician = () => {

        ProviderService.getProviderPhysician(props.selectedPrvId)
            .then((response) => {


                if (isNotEmpty(response.data)) {

                    setPhysician(response.data);
                    setLoadMsg('');
                    getProviderPhysicianSpecialties();
                } else {
                    setErrormsg('Error loading Physician data.');
                    setLoadMsg('');
                }

            }).catch((error) => {
            console.log('Error GETTING THE PROVIDER Physician' + error);

        })

    }
    const getProviderPhysicianSpecialties = () => {

        if( isNotEmpty(specialties))
            return;
        ProviderService.getProviderPhysicianSpecialties(props.selectedPrvId)
            .then((response) => {
                //let isNoProv = true;
                //dispatch({type:"BUSY_MSG",isBusyMsg: ''});
                console.log(`GET PROVIDER phy response----->${response.data}`)
                getProviderPhysicianLocations();
                setLoadMsg('');
                if (isNotEmpty(response.data)) {
                    setSpecialties(response.data);
                    setfilterSpecialties(response.data);
                    setLoadMsg('');

                }

            }).catch((error) => {
            console.log('Error GETTING THE PROVIDER Physician Specialties' + error);

        })
    }
    const getProviderPhysicianLocations = () => {

        if( isNotEmpty(locations))
            return;
        ProviderService.getProviderPhysicianLocations(props.selectedPrvId)
            .then((response) => {
                //let isNoProv = true;
                //dispatch({type:"BUSY_MSG",isBusyMsg: ''});
                setLoadMsg('');
                getProviderPhysicianLicenses();
                if (isNotEmpty(response.data)) {
                    console.log(`locationsss--->${response.data}`)
                    setLocations(response.data);
                    setfilterLocations(response.data);

                }

            }).catch((error) => {
            console.log('Error GETTING THE PROVIDER Physician' + error);
            setErrormsg(`Error GETTING THE PROVIDER Physician location ${error}`);
        })
    }

    const getProviderPhysicianLicenses = () => {

        if(isNotEmpty(licenses))
            return;
        ProviderService.getProviderLicenses(props.selectedPrvId)
            .then((response) => {
                //let isNoProv = true;
                //dispatch({type:"BUSY_MSG",isBusyMsg: ''});
                setLoadMsg('');
                if (isNotEmpty(response.data)) {
                    setLicenses(response.data);
                    setfilterLicenses(response.data);

                }

            }).catch((error) => {
            console.log('Error GETTING THE PROVIDER Physician licenses' + error);
            setErrormsg('Error loading Physician licenses data.');
        })
    }

    const getGeneralSection = () => {

        return(
            <Accordion defaultExpanded>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"

                >
                    <h5>General Information</h5>
                </AccordionSummary>

                <Grid
                    container

                    justifyContent="space-between"
                    style={{paddingLeft:"1em"}}
                >

                    <Grid item style={{width: "30%"}} justifyContent="left">
                        <h6>First Name:</h6>

                    </Grid>
                    <Grid item style={{width: "30%"}} justifyContent="left">
                        <h6>Middle Name:</h6>
                    </Grid>
                    <Grid item style={{width: "30%",marginBottom:'.1em'}} justifyContent="right">
                        <h6>Last Name:</h6>
                    </Grid>
                </Grid>
                <Grid
                    container

                    justifyContent="space-between"
                    style={{paddingLeft:"1em",width: "100%",paddingTop:'.15em',marginTop:'-.7em'}}
                >

                    <Grid item style={{width: "30%"}} justifyContent="left">
                        <span>{`${physician.phyFirstName}`}</span>

                    </Grid>
                    <Grid item style={{width: "30%"}} justifyContent="left">
                        <span>{`${physician.phyMiddleName}`}</span>
                    </Grid>
                    <Grid item style={{width: "30%"}} justifyContent="right">
                        <span>{`${physician.phyLastName}`}</span>
                    </Grid>
                </Grid>
                <Grid
                    container

                    justifyContent="space-between"
                    style={{paddingLeft:"1em",width: "100%",paddingTop:'.5em'}}
                >

                    <Grid item style={{width: "30%"}} justifyContent="left">
                        <h6>Degree(s):</h6>

                    </Grid>
                    <Grid item style={{width: "30%"}} justifyContent="left">
                        <h6>Date of Birth:</h6>
                    </Grid>
                    <Grid item style={{width: "30%"}} justifyContent="right">
                        <h6>Gender:</h6>
                    </Grid>
                </Grid>
                <div style={{lineHeight:'2em'}}/>
                <Grid
                    container

                    justifyContent="space-between"
                    style={{paddingLeft:"1em",width: "100%",paddingTop:'.15em',marginTop:'-.7em'}}
                >

                    <Grid item style={{width: "30%"}} justifyContent="left">
                        <span>{`${physician.degreeStr}`}</span>

                    </Grid>
                    <Grid item style={{width: "30%"}} justifyContent="left">
                        <span>{`${physician.phyDateOfBirth}`}</span>
                    </Grid>
                    <Grid item style={{width: "30%"}} justifyContent="right">
                        <span>{`${physician.phyGender}`}</span>
                    </Grid>
                </Grid>
                <Grid
                    container

                    justifyContent="space-between"
                    style={{paddingLeft:"1em",width: "100%",paddingTop:'.5em'}}
                >

                    <Grid item style={{width: "30%"}} justifyContent="left">
                       <h6>Language(s):</h6>

                    </Grid>
                    <Grid item style={{width: "30%"}} justifyContent="left">
                        <h6>TIN:</h6>
                    </Grid>
                    <Grid item style={{width: "30%"}} justifyContent="right">
                       <h6>NPI:</h6>
                    </Grid>
                </Grid>
                <div style={{lineHeight:'2em'}}/>
                <Grid
                    container

                    justifyContent="space-between"
                    style={{paddingLeft:"1em",width: "100%",paddingTop:'.15em',marginTop:'-.7em'}}
                >

                    <Grid item style={{width: "30%", overflowX:"auto"}} justifyContent="left">
                        <span>{`${physician.languageStr}`}</span>

                    </Grid>
                    <Grid item style={{width: "30%"}} justifyContent="left">
                        <span>{`${props.selectedTin}`}</span>
                    </Grid>
                    <Grid item style={{width: "30%"}} justifyContent="right">
                        <span>{`${physician.prvNpi}`}</span>
                    </Grid>
                </Grid>
                <div style={{lineHeight:'2em'}}/>
                <Grid
                    container

                    justifyContent="space-between"
                    style={{paddingLeft:"1em",width: "100%",paddingTop:'.5em'}}
                >


                    <Grid item style={{width: "60%"}} justifyContent="left">
                        <h6>LOB:</h6>
                    </Grid>
                    <Grid item style={{width: "30%"}} justifyContent="right">

                    </Grid>
                </Grid>
                <Grid
                    container

                    justifyContent="space-between"
                    style={{paddingLeft:"1em",width: "100%",paddingTop:'.15em',marginTop:'-.7em',paddingBottom:'2em'}}
                >

                    <Grid item style={{width: "60%"}} justifyContent="left">
                        <span>{`${physician.lobString}`}</span>
                    </Grid>
                    <Grid item style={{width: "30%"}} justifyContent="right">

                    </Grid>
                </Grid>

            </Accordion>
        );
    }


    return (



            <div className='App-content '>
                <LoadingMsgBar loadMsg={loadMsg}/>

                <GlobalMessage message={errormsg}/>
                {!physician?getProviderPhysician():<div>
                <Grid
                    container

                    justifyContent="space-between"
                    style={{width: "100%"}}
                >

                    <Grid item style={{width: "50%"}} justifyContent="left">
                        <h1 className='App-page-label'>{physician?`Physician - ${physician.phyLastName}, ${physician.phyFirstName} ${physician.phyMiddleInitial}`:''}</h1>
                    </Grid>
                    <Grid item style={{width: "50%"}} justifyContent="right">
                        <Button variant="outlined" onClick={props.back} style={{float:'right'}} aria-label='PROVIDER DEMOGRAPHICS: BACK TO DEMOGRAPHICS SEARCH Button'>Back to Demographic Search</Button>
                    </Grid>
                </Grid>




                    {getGeneralSection()}

            <Accordion>
            <AccordionSummary
            expandIcon={<ExpandMoreIcon />}


            aria-controls="panel2a-content"
            id="panel2a-header"
            >
            <h5>Specialties</h5>
            </AccordionSummary>
            <AccordionDetails>
                {specialties.length > 0? <PhysicianSpecialtyTblTbl specialties={filterSpecialties} setFilter={setfilterSpecialties} filtered={specialties}/> : <span/>}
            </AccordionDetails>
            </Accordion>
            <Accordion >
            <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3a-content"
            id="panel3a-header"
            >
            <h5>Locations</h5>
            </AccordionSummary>
                {locations.length >0 ? <PhysicianLocationTbl locations={filterLocations} setFilter={setfilterLocations} filtered={locations}/>:<span/>}
            </Accordion>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                >
                    <h5>Licenses</h5>
                </AccordionSummary>

                        {licenses.length >0 ? <PhysicianLicenseTbl licenses={filterLicenses} filtered={licenses} setFilter={setfilterLicenses}/>:<span/>}

            </Accordion>
                    <Grid
                        container

                        justifyContent="space-between"
                        style={{width: "100%"}}
                    >

                        <Grid item style={{width: "50%"}} justifyContent="left">

                        </Grid>
                        <Grid item style={{width: "50%"}} justifyContent="right">
                            <Button variant="outlined" onClick={props.back}  style={{float:'right'}} aria-label='PROVIDER DEMOGRAPHICS: BACK TO DEMOGRAPHICS SEARCH Button'>Back to Demographic Search</Button>
                        </Grid>
                    </Grid>
                </div>}

            </div>

    );

};
export default Physician;