import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles, useTheme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import LastPageIcon from '@material-ui/icons/LastPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import Paper from '@material-ui/core/Paper';
import TextField from "@material-ui/core/TextField";
import {isNotEmpty} from '../utils/Validator';
import { parseDate } from '../utils/Utils';
import "../table.css";

import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";

const headCells = [
    { id: 'userFirstName',fid:'userFirstName', numeric: false, disablePadding: true, label: 'First Name', filterLable: ' Name', width: '8rem',sortable: true },
    { id: 'userLastName', fid:'userLastName', numeric: false, disablePadding: true, label: 'Last Name', filterLable: ' Name', width: '8rem',sortable: true },
    { id: 'userNm', fid:'userNm', numeric: false, disablePadding: true, label: 'Username', filterLable: ' Username', width: '9rem',sortable: true },
    { id: 'emailAddr', fid:'emailAddr', numeric: false, disablePadding: true, label: 'Email Address', filterLable: ' Email', width: '16em',sortable: true },
    { id: 'statusDesc', fid:'statusDesc', numeric: false, disablePadding: true, label: 'Account Status', filterLable: ' Status', width: '8rem',sortable: true },
    { id: 'typeDesc',fid:'typeDesc', numeric: false, disablePadding: true, label: 'Account Type', filterLable: ' Type', width: '11rem',sortable: true },
    { id: 'grpInfoId',fid:'grpInfoId', numeric: false, disablePadding: true, label: 'Group ID', filterLable: ' Group ID', width: '11rem',sortable: true }



];

function desc(a, b, orderBy) {
    let c = a[orderBy];
    let d = b[orderBy];

    if (isNotEmpty(c)) {
        if (typeof c === "string") {
            c = c.toUpperCase();
        } else if (c instanceof Date) {
            c = parseDate(c);
        }
    } else {
        c = "";
    }

    if (isNotEmpty(d)) {
        if (typeof d === "string") {
            d = d.toUpperCase();
        } else if (d instanceof Date) {
            d = parseDate(d);
        }
    } else {
        d = "";
    }

    if (d < c) {
        return -1;
    }

    if (d > c) {
        return 1;
    }

    return 0;
}


function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });

    return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}



function EnhancedTableHead(props) {
    const {  classes, order, orderBy, onRequestSort, onRequestFilter, onclkAdd, onSelectAllClick, isSelectAll} = props;

    const createSortHandler = property => event => {
        onRequestSort(event, property);
    };
    const onFilter = property => event => {
        console.log(`********IN HEAD************((((((((ON Filter`);
        onRequestFilter(event.target.value, property);

    };
    const onAdd = (event) => {
        console.log(`********IN HEAD************((((((((ON ADD`);
        onclkAdd(event.target.value);

    };
    const onSelectAll = (event) => {
        console.log(`********IN HEAD************((((((((SELECT ALL toggle->${isSelectAll}`);
        onSelectAllClick(event.target.value);

    };

    return (

        <TableHead>
            <TableRow>

                {headCells.map(headCell => (
                    <TableCell
                        key={headCell.id}
                        padding={headCell.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === headCell.id ? order : false}
                        style={{ width: headCell.width, paddingBottom:0  }}
                        autoFocus={headCell.id === 'userFirstName'}
                    >
                        <div>
                            {headCell.sortable &&
                                <TableSortLabel
                                    active={orderBy === headCell.id}
                                    direction={order}
                                    onClick={createSortHandler(headCell.id)}
                                >
                                    <div>
                                        {headCell.label}

                                        {orderBy === headCell.id ? (
                                            <span className={classes.visuallyHidden}>
                                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                            </span>) : null}
                                    </div>
                                </TableSortLabel>
                            }
                            {!headCell.sortable &&
                                <div>
                                    {headCell.label}
                                </div>
                            }
                        </div>
                    </TableCell>
                ))}
                {
                    <TableCell style={{ width: '7rem', textAlign: 'left' }}>
                        <Button size="small" style={{fontSize:'.75em', backgroundColor:'white'}} onClick={onAdd}>
                            {props.add === true? 'ADD': 'REMOVE'} SELECTED
                        </Button>
                    </TableCell>
                }
            </TableRow>
            <TableRow className='filter-row'>
                {headCells.map(headCell => (
                    <TableCell
                        key={headCell.id+1}
                        padding={headCell.disablePadding ? 'none' : 'default'}
                        style={{ maxWidth: headCell.width, paddingBottom:0}}
                        autoFocus={headCell.id === 'userFirstName'}
                    >
                        <div  style={{paddingRight:'1rem',verticalAlign:'bottom'}}>
                            <TextField
                                id="filterId"
                                fullWidth
                                autoFocus={headCell.id === 'userFirstName'}
                                style={{height:'.7'}}
                                placeholder={`Enter ${headCell.filterLable}`}
                                margin="dense"
                                variant="outlined"
                                onChange={onFilter(headCell.fid)}
                            />
                        </div>

                    </TableCell>

                ))}
                <TableCell

                    padding={ 'default'}

                >
                    <Button size="small"  style={{fontSize:'.75em'}} onClick={onSelectAll}>
                    {!isSelectAll? 'DE':''}SELECT ALL
                    </Button>

                </TableCell>
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onRequestFilter: PropTypes.func.isRequired,
    onclkAdd: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    add: PropTypes.string.isRequired,
    isSelectAll:PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = () => {

    return ({}
    );
};

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
};

const useStyles1 = makeStyles(theme => ({
    root: {
        flexShrink: 0,
        marginLeft: theme.spacing(2.5),
    },
}));

function TablePaginationActions(props) {
    const classes = useStyles1();
    const theme = useTheme();

    const { count, page, rowsPerPage, onChangePage } = props;

    const handleFirstPageButtonClick = event => {
        onChangePage(event, 0);
    };

    const handleBackButtonClick = event => {
        onChangePage(event, page - 1);
    };

    const handleNextButtonClick = event => {
        onChangePage(event, page + 1);
    };


    const handleLastPageButtonClick = event => {
        onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (


        <div className={classes.root}>

            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </div>
    );
}

const useStyles = theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(3),
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 450,
    },
    tableWrapper: {
        overflowX: 'auto',
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },

});

class ManageRecipientsSearchTbl extends Component {
    constructor(props) {
        super(props);
        this.state = {
            order: 'asc', orderBy: 'userFirstName', page: 0, rowsPerPage: 10,
            isSelectAll: true, add: this.props.add
        }

    }

    handleRequestSort = (event, property) => {
        const isDesc = this.state.orderBy === property && this.state.order === 'desc';
        this.setState({ order: isDesc ? 'asc' : 'desc', orderBy: property });
    }

    handleRequestFilter = (value, property) => {
        if( value.length < 1){
            this.props.setFilter(this.props.unfilteredUsers)
            return true;
        }else{

            this.props.setFilter(this.props.unfilteredUsers.filter(user => {
                    if( user[property] == null) {
                        return false;
                    }
                    let comp = isNaN(user[property]) ? user[property].toUpperCase():  user[property] + " ";
                    if(  comp.search(value.toUpperCase()) !== -1) {
                        this.setState({ page: 0 });
                        return value.toUpperCase();
                    }

                    return false;
                }
            ))

        }
    }

    onclkAdd = () => {
      this.props.actionOnSelected(this.props.selected, this.props.users);
      this.props.setSelected([]);
      this.setState({ isSelectAll:true});
    }

    handleChangePage = (event, newPage) => {
        this.setState({ page: newPage })
    }

    handleChangeRowsPerPage = event => {
        this.setState({ page: 0, rowsPerPage: event.target.value })
    }
    handleSelectAllClick = event => {

        console.log(`*****select all`);
        if(this.state.isSelectAll) {
            this.props.setSelected(this.props.users.map(n => n.id), this.state.add);
            this.setState({ isSelectAll:false});
        } else {
            this.props.setSelected([], this.state.add);
            this.setState({ isSelectAll:true});
        }

    }

    getUsrSelected = (id) => {
        return this.props.users.find(usr => usr.id === id);
    }

    handleClick = (event, id) => {

        const selectedIndex = this.props.selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(this.props.selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(this.props.selected.slice(1));
        } else if (selectedIndex === this.props.selected.length - 1) {
            newSelected = newSelected.concat(this.props.selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                this.props.selected.slice(0, selectedIndex),
                this.props.selected.slice(selectedIndex + 1),
            );
        }

        this.props.setSelected(newSelected);
        console.log(`*****select Index->${selectedIndex}******yahooo selected length->${this.props.selected.length} selected->${JSON.stringify(this.props.selected)}`)
    }
    isMirror = (user) => {

      //  return this.props.isUserSearch === true && (user.typeCd === 'PRUS' || user.typeCd === 'PRAD') && user.statusCd === 'ACTIV';
    }

    isSelected = id => this.props.selected.indexOf(id) !== -1;

    render() {
        const { classes } = this.props;

        return (
            <div className={classes.root}>
                <Paper className={classes.paper}>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25, 50, 100, 200]}
                        component="div"
                        count={this.props.users.length}
                        rowsPerPage={this.state.rowsPerPage}
                        page={this.state.page}
                        onChangePage={this.handleChangePage}
                        onChangeRowsPerPage={this.handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                    />
                    {/*  <EnhancedTableToolbar numSelected={selected.length} />*/}
                    <div className={classes.tableWrapper}>
                        <Table
                            className={`${classes.table} App-font`}
                            aria-labelledby="tableTitle"
                            size='small'
                            striped="true"
                            aria-label="Manage Tins table"
                        >
                            <EnhancedTableHead
                                classes={classes}
                                numSelected={this.props.selected.length}
                                order={this.state.order}
                                orderBy={this.state.orderBy}
                                onSelectAllClick={this.handleSelectAllClick}
                                onclkAdd={this.onclkAdd}
                                onRequestSort={this.handleRequestSort}
                                onRequestFilter={this.handleRequestFilter}
                                rowCount={this.props.users.length}
                                add={this.state.add}
                                isSelectAll={this.state.isSelectAll}
                            />
                            <TableBody>
                                {stableSort(this.props.users, getSorting(this.state.order, this.state.orderBy))
                                    .slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)
                                    .map((row, index) => {
                                        const isItemSelected = this.isSelected(row.id);
                                        const labelId = `enhanced-table-Select-${index}`;

                                        return (
                                            <TableRow
                                                tabIndex={-1}
                                                style={{
                                                    padding: '5px 20px',
                                                    height: 25,
                                                    backgroundColor: index % 2 ? '#fafafa' : 'white'
                                                }}

                                                role="checkbox"
                                                aria-checked={isItemSelected}
                                                key={row.id}
                                                selected={isItemSelected}
                                            >

                                                <TableCell component="th" id={labelId} scope="row"
                                                           size='small'>
                                                    {row.userFirstName}
                                                </TableCell>
                                                <TableCell component="th" id={labelId} scope="row"
                                                           size='small'>
                                                    {row.userLastName}
                                                </TableCell>
                                                <TableCell component="th" id={labelId} scope="row"
                                                           size='small'>
                                                    {row.userNm}
                                                </TableCell>
                                                <TableCell component="th" id={labelId} scope="row"
                                                           size='small'>
                                                    {row.emailAddr}
                                                </TableCell>
                                                <TableCell component="th" id={labelId} scope="row"
                                                           size='small'>
                                                    {row.statusDesc}
                                                </TableCell>

                                                <TableCell component="th" id={labelId} scope="row"
                                                           size='small'>
                                                    {row.typeDesc}
                                                </TableCell>
                                                <TableCell component="th" id={labelId} scope="row"
                                                           size='small'>
                                                    {row.grpInfoId}
                                                </TableCell>
                                                <TableCell padding="checkbox">
                                                    <Checkbox
                                                              checked={isItemSelected}
                                                              inputProps={{'aria-labelledby': labelId}}
                                                              onClick={event => {
                                                                  this.handleClick(index, row.id)
                                                              }}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}

                            </TableBody>
                            <TableFooter>
                                <TableRow>
                                    <TablePagination
                                        rowsPerPageOptions={[5, 10, 25, 50, 100, 200]}
                                        //component="div"
                                        count={this.props.users.length}
                                        rowsPerPage={this.state.rowsPerPage}
                                        page={this.state.page}
                                        onChangePage={this.handleChangePage}
                                        onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                        ActionsComponent={TablePaginationActions}
                                    />
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </div>
                </Paper>

            </div>
        );
    }
}
export default withStyles(useStyles)(ManageRecipientsSearchTbl);