import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles, useTheme} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import LastPageIcon from '@material-ui/icons/LastPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import Paper from '@material-ui/core/Paper';
import TextField from "@material-ui/core/TextField";
import { isNotEmpty } from '../../../../utils/Validator';
import { parseDate } from '../../../../utils/Utils';

import Link from "@material-ui/core/Link";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import FacilityLocationServicesDtlsTbl from "./FacilityLocationServicesDtlsTbl";
import FacilityLocationSpclDtlsTbl from "./FacilityLocationSpclDtlsTbl";
import FacilityLocationFocusDtlsTbl from "./FacilityLocationFocusDtlsTbl";
import FacilityLocationLangDtlsTbl from "./FacilityLocationLangDtlsTbl";
import Button from "@material-ui/core/Button";
import {Grid} from "@material-ui/core";
import {Avatar} from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";

function desc(a, b, orderBy) {
    let c = a[orderBy];
    let d = b[orderBy];

    if (isNotEmpty(c)) {
        if (typeof c === "string") {
            c = c.toUpperCase();
        } else if (c instanceof Date) {
            c = parseDate(c);
        }
    } else {
        c = "";
    }

    if (isNotEmpty(d)) {
        if (typeof d === "string") {
            d = d.toUpperCase();
        } else if (d instanceof Date) {
            d = parseDate(d);
        }
    } else {
        d = "";
    }

    if (d < c) {
        return -1;
    }

    if (d > c) {
        return 1;
    }

    return 0;
}

function
stableSort(array, cmp) {

    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

const headCells = [
    {id: 'faclocDirectoryLocName', numeric: false, disablePadding: true, label: 'Name', filterLable:' Name',width:'17rem'},
    {id: 'addressString', numeric: false, disablePadding: true, label: 'Address', filterLable:' Address',width:'18rem'},
    {id: 'faclocDirectoryPhoneNbr', numeric: false, disablePadding: true, label: 'Phone', filterLable:'Phone',width:'5rem'},
    {id: 'faclocFaxNbr', numeric: false, disablePadding: true, label: 'Fax ', filterLable:'Fax ',width:'5em'},
    {id: 'faclocEmailAddress', numeric: false, disablePadding: true, label: 'Email Address', filterLable:'Email Address',width:'11rem'},
    {id: 'flfAcceptWcPatients', numeric: false, disablePadding: true, label: 'Accepting New WC?', filterLable:'Accepting New WC?',width:'4rem'},
    {id: 'Additional Information', numeric: false, disablePadding: true, label: 'Additional Information', filterLable:'Additional Infor',width:'5rem'}
];

function EnhancedTableHead(props) {
    const {classes, order, orderBy, onRequestSort, onRequestFilter} = props;
    const createSortHandler = property => event => {
        onRequestSort(event, property);
    };
    const onFilter = property => event => {
        onRequestFilter(event.target.value, property);

    };

    return (
        <TableHead>
            <TableRow>

                {headCells.map(headCell => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}

                        sortDirection={orderBy === headCell.id ? order : false}
                        style={{width:headCell.width}}
                        autoFocus={headCell.id === 'userFirstName'}
                    >

                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={order}
                            onClick={createSortHandler(headCell.id)}
                        >
                            <div >
                                <div>
                                    {headCell.label}

                                    {orderBy === headCell.id ? (
                                        <span className={classes.visuallyHidden}>
                                   {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </span>) : null}</div>

                            </div>
                        </TableSortLabel>
                    </TableCell>
                ))}

            </TableRow>
            <TableRow className='filter-row'>
                {headCells.map(headCell => (
                    <TableCell
                        key={headCell.id+1}
                        padding={headCell.disablePadding ? 'none' : 'default'}
                        style={{ maxWidth: headCell.width, paddingBottom:0}}
                        autoFocus={headCell.id === 'userFirstName'}
                    >
                        <div  style={{paddingRight:'1rem',verticalAlign:'bottom'}}>
                            <TextField
                                id="filterId"
                                fullWidth
                                autoFocus={headCell.id === 'userFirstName'}
                                style={{height:'.7'}}
                                placeholder={`Enter ${headCell.filterLable}`}
                                margin="dense"
                                variant="outlined"
                                onChange={onFilter(headCell.id)}
                            />
                        </div>
                    </TableCell>
                ))}

            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onRequestFilter: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = () => {

    return ({}
    );
};

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
};

const useStyles1 = makeStyles(theme => ({
    root: {
        flexShrink: 0,
        marginLeft: theme.spacing(2.5),
    },
}));

function TablePaginationActions(props) {
    const classes = useStyles1();
    const theme = useTheme();

    const { count, page, rowsPerPage, onChangePage } = props;

    const handleFirstPageButtonClick = event => {
        onChangePage(event, 0);
    };

    const handleBackButtonClick = event => {
        onChangePage(event, page - 1);
    };

    const handleNextButtonClick = event => {
        onChangePage(event, page + 1);
    };

    const handleLastPageButtonClick = event => {
        onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <div className={classes.root}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </div>
    );
}
const useStyles = theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(3),
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 450,
    },
    tableWrapper: {
        overflowX: 'auto',
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    dialog: {
		'& .MuiDialog-paperWidthSm': {
			minWidth: '800px;'
		}
  	},
      label: {
		fontWeight: 'bold',
		whiteSpace: 'noWrap',			
	}	  

});

class FacilityLocationTbl extends Component {
    constructor(props) {
        super(props);
        this.state = {
             page: 0, rowsPerPage: 5, services:[], specialties:[], focuses:[], languagues:[],
            filteredServices:[], filteredSpecialties:[], filteredFocuses:[], filteredLanguagues:[],
            showDlg:false, officeName:'', filtered: []
        }

    }

    setFilter = (services) => {
        this.setState({filteredServices:services});
 
     }

    setFilterServices = (services) => {
       this.setState({filteredServices:services});

    }
    setFilterSpecialties = (specialties) => {
        this.setState({filteredSpecialties:specialties});
 
     }
     setFilterFocuses = (focuses) => {
        this.setState({filteredFocuses:focuses});
 
     }
     setFilterLanguagues = (languagues) => {
        this.setState({filteredLanguagues:languagues});
 
     }

    closeMsgDlg = () => {
        this.setState({services:[], specialties:[], focuses:[], languagues:[], showDlg:false} );
    }

    handleRequestSort = (event, property) => {
        const isDesc = this.state.orderBy === property && this.state.order === 'desc';
        this.setState({order: isDesc ? 'asc' : 'desc', orderBy: property});
    }
    handleRequestFilter = (value, property) => {
        console.log(`YES HITTING TGHE FILERER`)
        if( value.length < 1){
            this.props.setFilter(this.props.filtered)
            return true;
        }else{
            this.props.setFilter(this.props.filtered.filter(user => {
                const testUser = user[property] !== null ? user[property].toUpperCase() : user[property];
                if( testUser != null) {
                    this.setState({ page: 0 });
                    return testUser.search( value.toUpperCase()) !== -1;
                }
                return false;
            }))
        }
    }


    handleChangePage = (event, newPage) => {
        this.setState({page: newPage})
    }

    handleChangeRowsPerPage = event => {
        this.setState({page: 0, rowsPerPage: event.target.value})
    }

    handleViewDetails( services, specialties, focuses, languagues, officeName) {

        //console.log(`event click val==>${JSON.stringify(details)} `)

      this.setState({services:services, specialties:specialties, focuses:focuses, languagues:languagues, showDlg:true,  
        filteredServices:services, filteredSpecialties: specialties, filteredFocuses:focuses, filteredLanguagues:languagues, officeName:officeName} );


    }

    render() {
        const {classes} = this.props;

        return (

            <div >
                <Dialog
                    open={this.state.showDlg}
                    onClose={this.closeMsgDlg}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    className={classes.dialog}
                >
                    <DialogTitle variant="h2">Location:{this.state.officeName}</DialogTitle>
                   
                    <Grid
                        container
                        justifyContent="space-between"
                        style={{paddingLeft:"1em"}}
                    >
                                       
                        {(this.state.services.length > 0 ) ? <FacilityLocationServicesDtlsTbl dtlss={this.state.filteredServices} setFilter={this.setFilterServices} filtered={this.state.services}/> : null}
                        {(this.state.specialties.length > 0 ) ? <FacilityLocationSpclDtlsTbl dtlss={this.state.filteredSpecialties} setFilter={this.setFilterSpecialties} filtered={this.state.specialties}/> : null}
                        {(this.state.focuses.length > 0 ) ? <FacilityLocationFocusDtlsTbl dtlss={this.state.filteredFocuses} setFilter={this.setFilterFocuses} filtered={this.state.focuses}/> : null}
                        {(this.state.languagues.length > 0 ) ?<FacilityLocationLangDtlsTbl dtlss={this.state.filteredLanguagues} setFilter={this.setFilterLanguagues} filtered={this.state.languagues}/> : null}

                </Grid>
                           
                    <div style={{textAlign:'center'}}>
                        <Button variant="outlined"   onClick={this.closeMsgDlg} aria-label='PROVIDER DEMOGRAPHICS: Facility Locations: Additional Information VIEW DETAILS CLOSE Button '>
                            CLOSE</Button>
                    </div>

                </Dialog>
                <div className={classes.root}>
                    <Paper className={classes.paper}>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25, 50, 100, 200]}
                            component="div"
                            count={this.props.locations.length}
                            rowsPerPage={this.state.rowsPerPage}
                            page={this.state.page}
                            ActionsComponent={TablePaginationActions}
                            onChangePage={this.handleChangePage}
                            onChangeRowsPerPage={this.handleChangeRowsPerPage}
                        />
                        {/*  <EnhancedTableToolbar numSelected={selected.length} />*/}
                        <div className={classes.tableWrapper}>
                            <Table
                                className={`${classes.table} App-font`}
                                aria-labelledby="tableTitle"
                                size='small'
                                striped
                                aria-label="Client List table"
                            >
                                <EnhancedTableHead
                                    classes={classes}

                                    order={this.state.order}
                                    orderBy={this.state.orderBy}
                                    onSelectAllClick={this.handleSelectAllClick}
                                    onRequestSort={this.handleRequestSort}
                                    onRequestFilter={this.handleRequestFilter}
                                    rowCount={this.props.locations.length}

                                />
                                <TableBody className="Demographic">
                                    {stableSort(this.props.locations, getSorting(this.state.order, this.state.orderBy))
                                        .slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)
                                        .map((row, index) => {

                                            const labelId = `enhanced-table-Select-${index}`;

                                            return (
                                                <TableRow
                                                    tabIndex={-1}
                                                    key={row.poflOfcId}
                                                    style={{
                                                        padding: '5px 20px',
                                                        height: 25,
                                                        backgroundColor: index % 2 ? '#fafafa' : 'white'
                                                    }}
                                                >

                                                    <TableCell component="th" id={labelId} scope="row"
                                                               size='small'>
                                                                {(row.flfPrimaryLocationFlag ==="Y") ?
                                                            <Tooltip arrow title="Primary" aria-label="Primary Service">
                                                               <div className="Primary-indicator">
                                                                    <Avatar sx={{ fontSize:'.5rem' }}>P</Avatar>
                                                            </div>

                                                            </Tooltip> : null }
                                                        {row.faclocDirectoryLocName}
                                                    </TableCell>
                                                    <TableCell component="th" id={labelId} scope="row"
                                                               size='small'>
                                                        {row.addressString
                                                        }
                                                    </TableCell>
                                                    <TableCell component="th" id={labelId} scope="row"
                                                               size='small'>
                                                        {row.faclocDirectoryPhoneNbr}
                                                    </TableCell>
                                                    <TableCell component="th" id={labelId} scope="row"
                                                               size='small'>
                                                        {row.faclocFaxNbr}
                                                    </TableCell>
                                                    <TableCell component="th" id={labelId} scope="row"
                                                               size='small'>
                                                        {row.faclocEmailAddress}
                                                    </TableCell>
                                                    <TableCell component="th" id={labelId} scope="row"
                                                               size='small'>
                                                        {row.flfAcceptWcPatients}
                                                    </TableCell>
                                                    <TableCell component="th" id={labelId} scope="row"
                                                               size='small'>
                                                                 {((row.services.length > 0 ) || (row.specialties.length > 0) || (row.focuses.length > 0) ||  (row.languagues.length > 0)) ?
                                                                 <Link onClick={() => this.handleViewDetails( row.services, row.specialties, row.focuses, row.languagues, row.faclocDirectoryLocName)} 
                                                                 aria-label='PROVIDER DEMOGRAPHICS: Facility Locations: Additional Information VIEW DETAILS Hyperlink'>View Details</Link>
                                                                 : <span/>
                                                                }
                                                    </TableCell>

                                                </TableRow>
                                            );
                                        })}
                                </TableBody>
                                <TableFooter>
                                    <TableRow>
                                        <TablePagination
                                            rowsPerPageOptions={[5, 10, 25, 50, 100, 200]}
                                            count={this.props.locations.length}
                                            rowsPerPage={this.state.rowsPerPage}
                                            page={this.state.page}
                                            ActionsComponent={TablePaginationActions}
                                            onChangePage={this.handleChangePage}
                                            onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                        />
                                    </TableRow>
                                </TableFooter>
                            </Table>
                        </div>
                    </Paper>

                </div>

            </div>
        );
    }
}
export default withStyles(useStyles)(FacilityLocationTbl);