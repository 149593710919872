import React from 'react';
import { isNotEmpty } from '../utils/Validator.js';

import '../App.css';

function GlobalMessage(props) {
    let styleClass = "globalSuccessMessage";

    if (props.type === "error") {
        styleClass = "globalErrorMessage"
    }

    if (isNotEmpty(props.message)) {
        return (<p className={styleClass}>{props.message}</p>);
    } else {
        return (null);
    }
}

GlobalMessage.defaultProps = {
    type: 'error'
};

export default GlobalMessage;
