import React, {Component, Fragment} from 'react'
import './ChangePassword.css'
import AuthenticationService from "../../Services/AuthenticationService"
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import GlobalMessage from "../GlobalMessage";
import Button from "@material-ui/core/Button";
import {
    isEmpty, hasLength, getNumericCharacterCount, getUpperCaseCharacterCount, getLowerCaseCharacterCount, containsSpecialCharacter, containsInvalidCharacters
} from '../../utils/Validator.js';
import { getValue } from '../../utils/Utils.js';
import PasswordRequirements from './PasswordRequirements'
import PasswordRequirementsError from "./PasswordRequirementsError";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import InputAdornment from "@material-ui/core/InputAdornment";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

const INCORECT_PASSWORD = 'incorrect_password';
const INVALID_PW = 'INVALID_PW';
const FAILED = 'failed'
window.analytics ={pageName:'Change Password'};
class ChangePassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            valid: true, password: '', newPassword: '', confPassword: '',curPwdErrorMsg: '',
            errorMsg: '',successMsg: props.isChangedPwd ? `Your password has been changed successfully.` : ``,
            newPwdErrorMsg: '', confPwdErrorMsg: '', isSubmitEnabled: true,
            showCurrentPassword: false,
            showPassword: false,
            showConfirmPassword: false,
            showPwdRequirementsError: false,
            pwdLengthError: false,
            pwdContainsUpperError: false,
            pwdContainsLowerError: false,
            pwdContainsNumberError: false,
            pwdContainsSpclCharacterError: false,
            pwdContainsUserName: false,
            showUserNameCheck: false
        }
    }


    changePwd = () => {
        console.log(`try change password`);
        AuthenticationService.changeUsrPwd(AuthenticationService.getLoggedInUserName(), this.state.password, this.state.newPassword)
            .then(response => {
                const result = response.data
                this.setState({isSubmitEnabled: true})
                if(result.includes(INCORECT_PASSWORD)) {
                    this.setState({errorMsg: `An error has occurred. Please address all fields marked with “Error". `, curPwdErrorMsg: `Error: Current Password is incorrect`})
                }else if(result.includes(FAILED)) {
                    this.setState({errorMsg: `Error: The password change was not successful.`})
                }else if(result.includes(INVALID_PW)) {
                    this.validatePwdForUserName();
                    //this.setState({newPwdErrorMsg: `Error: Password cannot contain Username or First Name or Last Name spelled forward or backward.`});
                    //this.setState({errorMsg:`An error has occurred. Please address all fields marked with “Error".`})
                } else {
                    this.props.setChangedPwd(true)
                    this.setState({successMsg: `Your password has been changed successfully.`})
                }
            }).catch((error) => {
                
                let message = "";

                if (error.response !== undefined) {
                    message = getValue(error.response.data.message, '');
                }
                if(message.includes(INCORECT_PASSWORD)) {
                    this.setState({errorMsg: `An error has occurred. Please address all fields marked with “Error". `, curPwdErrorMsg: `Error: Current Password is incorrect`})
                }else if(message.includes(FAILED)) {
                    this.setState({errorMsg: `Error: The password change was not successful.`})
                }else if(message.includes(INVALID_PW)) {
                    this.setState({newPwdErrorMsg: `Error: Password does not meet the Password Requirements.`});
                    this.validatePwdForUserName();
                    //this.setState({newPwdErrorMsg: `Error: Password cannot contain Username or First Name or Last Name spelled forward or backward.`});
                    //this.setState({errorMsg:`An error has occurred. Please address all fields marked with “Error".`})
                }else{
                this.setState({errorMsg: `Error: The password change was not successful.`});
                }
                 this.setState({isSubmitEnabled: true})
        })

    }

    resetValid = () => {
        this.setState({valid: true, errorMsg: '',
            newPwdErrorMsg: '', curPwdErrorMsg: '', confPwdErrorMsg: '', showPwdRequirementsError: false})
    }

    handleReset = () => {
        this.resetValid()
        this.setState({password: '', newPassword: '', confPassword: ''})
    }

    isValid = () => {
        this.resetValid()
        let isValid = true;


        if(this.state.password.length < 1) {
            this.setState({curPwdErrorMsg: `Error: Enter Current Password`})
            isValid = false
        }

        if (isEmpty(this.state.newPassword)) {
            this.setState({newPwdErrorMsg: `Error: Enter New Password`});
            isValid = false;
        } 

        if(this.state.confPassword.length < 1) {
            this.setState({confPwdErrorMsg: `Error: Enter Confirm Password`})
            isValid = false
        }

        if( this.state.confPwdErrorMsg === '' && this.state.newPassword !== this.state.confPassword) {
            this.setState({newPwdErrorMsg: `Error: Passwords do not match`})
            isValid = false
        }

        return  isValid;
    }
    getPwdValidation() {
        let has8To14Chars = true;
        let hasAtLeastOneUpperCaseLetter = true;
        let hasAtLeastOneLowerCaseLetter = true;
        let hasAtLeastOneNumber = true;
        let hasAtLeastOneSpecialCharacter = true;
        //let passwordDoesContainUserName = false;
        let hasInvalidCharacter = false;
        let validSpclCharacter = true;
       
        this.setState({showPwdRequirementsError: false,
            pwdLengthError: false,
            pwdContainsUpperError: false,
            pwdContainsLowerError: false,
            pwdContainsNumberError: false,
            pwdContainsSpclCharacterError: false,
            pwdContainsUserName: false,
            showUserNameCheck: false
          
        });
        let password = this.state.newPassword;  

        has8To14Chars = hasLength(password, 8, 20);
        hasAtLeastOneNumber = getNumericCharacterCount(password) > 0;
        hasAtLeastOneUpperCaseLetter = getUpperCaseCharacterCount(password) > 0;
        hasAtLeastOneLowerCaseLetter = getLowerCaseCharacterCount(password) > 0;
        //hasAtLeastOneSpecialCharacter = getSpecialCharacterCount(password) > 0;
        hasAtLeastOneSpecialCharacter = containsSpecialCharacter(password);
        hasInvalidCharacter = containsInvalidCharacters(password);
        validSpclCharacter = (hasAtLeastOneSpecialCharacter && !hasInvalidCharacter);
      
       
        return { has8To14Chars: has8To14Chars, hasAtLeastOneUpperCaseLetter: hasAtLeastOneUpperCaseLetter,  hasAtLeastOneLowerCaseLetter: hasAtLeastOneLowerCaseLetter, 
            hasAtLeastOneNumber: hasAtLeastOneNumber, 
            hasAtLeastOneSpecialCharacter: validSpclCharacter,
           // passwordDoesContainUserName : passwordDoesContainUserName   
        };
    }

    checkPwdErrors(pwdResults){
        let isValid = true;
        if(!pwdResults.has8To14Chars){
            this.setState({pwdLengthError: true});
            isValid = false;
        }
        if(!pwdResults.hasAtLeastOneUpperCaseLetter){
            this.setState({pwdContainsUpperError: true});
            isValid = false;
        }
        if(!pwdResults.hasAtLeastOneLowerCaseLetter){
            this.setState({pwdContainsLowerError: true});
            isValid = false;
        }
        if(!pwdResults.hasAtLeastOneNumber){
            this.setState({pwdContainsNumberError: true});
            isValid = false;
        }
        if(!pwdResults.hasAtLeastOneSpecialCharacter){
            this.setState({pwdContainsSpclCharacterError: true});
            isValid = false;
        }
       /*  if(pwdResults.passwordDoesContainUserName){
            this.setState({pwdContainsUserName: true});
            isValid = false;
        } */
        return isValid ;
    }

    validatePwdForUserName = () => {
        this.setState({pwdLengthError: false});
        this.setState({pwdContainsUpperError: false});
        this.setState({pwdContainsLowerError: false});
        this.setState({pwdContainsNumberError: false});
        this.setState({pwdContainsSpclCharacterError: false});
        this.setState({pwdContainsUserName:true});
        this.setState({errorMsg: `New Password does not meet requirements. Please try again. `});
        this.setState({showUserNameCheck: true, showPwdRequirementsError:true, isSubmitEnabled: true }); 
    }


    handleSubmit = () => {

        
        this.setState({valid: true, errorMsg:''});
        const syntaxResults = this.isValid();
        let pwdResults  = this.getPwdValidation();
        const validPwd = this.checkPwdErrors(pwdResults);
        if(syntaxResults && !validPwd){
            this.setState({showPwdRequirementsError:true, isSubmitEnabled: true });
            this.setState({errorMsg: `New Password does not meet requirements. Please try again. `});
            this.setState({newPwdErrorMsg: `Error: Password does not meet the Password Requirements.`});
            this.setState({valid: false});
        }
        
        if(!syntaxResults){
            console.log(`set valid to false`);
            this.setState({valid: false, errorMsg:`An error has occurred. Please address all fields marked with “Error". `});
            this.setState({isSubmitEnabled: true});
        }
        
        if( (syntaxResults === true) && validPwd) {
        //if( (syntaxResults === true) ) {
            console.log(`handle submit password`);
            this.setState({isSubmitEnabled: false});
            this.changePwd();
        } 

    }

    handleClickShowCurrentPassword= (event) => {
        console.log('password');
        if(this.state.showCurrentPassword){
            this.setState({showCurrentPassword : false})
        }
        if(!this.state.showCurrentPassword){
            this.setState({showCurrentPassword : true})
        }
      };

    handleClickShowPassword= (event) => {
        console.log('password');
        if(this.state.showPassword){
            this.setState({showPassword : false})
        }
        if(!this.state.showPassword){
            this.setState({showPassword : true})
        }
      };

      handleClickShowConfirmPassword= (event) => {
        console.log('password');
        if(this.state.showConfirmPassword){
            this.setState({showConfirmPassword : false})
        }
        if(!this.state.showConfirmPassword){
            this.setState({showConfirmPassword : true})
        }
      };
      
      handleMouseDownPassword = (event) => {
        event.preventDefault();
      };
    render() {


        return (
            <Fragment>

                <div className='App-content'>
                    <h1 className='App-page-label' style={{margin:0}}>Change Password</h1>
                    <GlobalMessage type='error'  message={this.state.errorMsg} />

                    <GlobalMessage type='success' message={this.state.successMsg} />


                    <div hidden={this.props.isChangedPwd === true}>

                        <div className='App-error-font App-error-color App-note-Field' > Note: Fields marked with
                            an asterisk(*) are required.
                        </div>


                        <div className=' App-font'>
                            <Grid container>
                                <Grid item>
                                    <div style={{fontWeight: 'bold'}}>
                                        <label  htmlFor='userNm'>Username:&nbsp;&nbsp;</label>
                                    </div>
                                </Grid>
                                <Grid item>
                                    <label id='userNm'>{AuthenticationService.getLoggedInUserName()}</label>
                                </Grid>
                            </Grid>
                        </div>

                        <Grid container style={{width:"100%"}}>
                            <Grid item style={{width:"20%"}}>
                                <div>
                                    <TextField
                                        id="curpwdId"
                                        error={this.state.curPwdErrorMsg !== ''}
                                        helperText={this.state.curPwdErrorMsg}
                                        required
                                        autoFocus
                                        type={this.state.showCurrentPassword ? "text" : "password"}
                                        defaultValue=" "
                                        fullWidth
                                        label="Current Password"
                                        margin="normal"
                                        variant="outlined"
                                        onChange={event => this.setState({password: event.target.value})}
                                        value={this.state.password}
                                        InputProps={{
                                            endAdornment: (
                                              <InputAdornment position="end">
                                                <IconButton disableRipple={true} size="small"
                                                onClick={this.handleClickShowCurrentPassword}
                                               >
                                                  {this.state.showCurrentPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                              </InputAdornment>
                                            )}}
                                        
                                    />
                                </div>
                                <div>
                                    <TextField
                                        id="newpwdId"
                                        error={this.state.newPwdErrorMsg !== ''}
                                        helperText={this.state.newPwdErrorMsg}
                                        required
                                        type={this.state.showPassword ? "text" : "password"}
                                        fullWidth
                                        defaultValue=" "
                                        label="New Password"
                                        margin="normal"
                                        variant="outlined"
                                        onChange={event => this.setState({newPassword: event.target.value})}
                                        value={this.state.newPassword}
                                        InputProps={{
                                            endAdornment: (
                                              <InputAdornment position="end">
                                                <IconButton disableRipple={true} size="small"
                                                onClick={this.handleClickShowPassword}
                                               >
                                                  {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                              </InputAdornment>
                                            )}}
                                    />
                                </div>
                                <div>
                                    <TextField
                                        id="curpwdId"
                                        error={this.state.confPwdErrorMsg !== ''}
                                        helperText={this.state.confPwdErrorMsg}
                                        required
                                        fullWidth
                                        type={this.state.showConfirmPassword ? "text" : "password"}
                                        defaultValue=" "
                                        label="Confirm Password"
                                        margin="normal"
                                        variant="outlined"
                                        onChange={event => this.setState({confPassword: event.target.value})}
                                        value={this.state.confPassword}
                                        InputProps={{
                                            endAdornment: (
                                              <InputAdornment position="end">
                                                <IconButton disableRipple={true} size="small"
                                                onClick={this.handleClickShowConfirmPassword}
                                               >
                                                  {this.state.showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                              </InputAdornment>
                                            )}}
                                    />
                                </div>


                            </Grid>
                           
                            <Grid item style={{width:"40%"}}>
                            {(!this.state.showPwdRequirementsError) ? 
                             <PasswordRequirements/> 
                             :
                             <PasswordRequirementsError pwdLengthError={this.state.pwdLengthError} pwdContainsUpperError={this.state.pwdContainsUpperError}
                                pwdContainsLowerError={this.state.pwdContainsLowerError} pwdContainsNumberError={this.state.pwdContainsNumberError}
                                pwdContainsSpclCharacterError = {this.state.pwdContainsSpclCharacterError} showUserNameCheck = {this.state.showUserNameCheck} 
                                pwdContainsUserName={this.state.pwdContainsUserName}
                                
                                />
                            } 
                            </Grid>
                            </Grid>
                            
                        <div className='Change-Password-btn-row'>

                            <Button  disabled={!this.state.isSubmitEnabled}   onClick={this.handleSubmit } aria-label='Change Password: SUBMIT Button'>SUBMIT
                            </Button>

                            <Button  variant="outlined" onClick={this.handleReset} aria-label='Change Password: RESET Button'>RESET
                            </Button>

                        </div>
                    </div>


                </div>
            </Fragment>
        );
    }
}
export default ChangePassword;