import { createTheme } from '@material-ui/core/styles';

// https://material-ui.com/customization/palette/
export const theme = createTheme({
    palette: {
        primary: {
            main: '#0f197d',
        },

        secondary: {
            main: '#0f197d',
        },

        error: {
            main: '#B0170C',
        }
    },

    // https://material-ui.com/api/button/
    // Go to the component API
    // Can set default properties a component should have
    // Can override property by setting it on component HTML
    // Inspect component in Chrome, generally find [NAME]-root
    props: {
        // Name of the component
        MuiButton: {
            variant: 'contained',
            color: 'primary'
        },

        MuiTextField: {
            variant: 'outlined',
            margin: 'normal',
            InputLabelProps: {
                shrink: true,
            }
        }
    },


    // https://material-ui.com/customization/globals/
    // When the configuration variables aren't powerful enough, you can take advantage of the 
    // overrides key of the theme to potentially change every single style injected by Material-UI
    // into the DOM. That's a really powerful feature.
    overrides: {
        // <Button className={classes.button} variant="contained" color="primary" onClick={this.handleDone}>...
        // To style the .MuiButton-root property applied to the button do the following

        MuiButton: {
            root: {
                margin: '.5rem',
            },

            containedPrimary: {
                '&:hover': {
                    // backgroundColor: '#E9F6FC',
                    // color: '#026c9a',
                    // outline: '2px solid #026c9a'
                },
            },
        },

        MuiIconButton: {
            label: {

            }
        },

        // the small label on the top of the text fields
        // .MuiFormLabel-root is the CSS controlling the color in the <label> with this style 
        MuiFormLabel: {
            root: {
                color: '#222',
            },
        },


        // the label surrounding a component defined in it
        // <FormControlLabel control={<Checkbox .../>} label="Label text goes here." />
        MuiFormControlLabel: {
            root: {
                color: '#222',
            }
        },

        PrivateNotchedOutline: {
            root: {
                borderRadius: 0,
            }
        },

    },
});

