import React, {Component} from "react";
import Button from "@material-ui/core/Button";

import LoadingMsgBar from "../LoadingMsgBar";
import GlobalMessage from "../GlobalMessage";
import Tooltip from "@material-ui/core/Tooltip";
import {isNotEmpty, isNumeric} from "../../utils/Validator";
import {isEmpty} from "../../utils/Utils";
import TextField from "@material-ui/core/TextField";
import AuthenticationService from "../../Services/AuthenticationService";
import { Grid} from "@material-ui/core";
import ManageRecipientsSearchTbl from "../ManageRecipientsSearchTbl";


class SearchRecipients  extends Component {
    constructor(props) {
        super(props);
        this.state = {
            recipients: [], filteredRecipients: [], selected:[],sv_recipients: [], sv_filteredRecipients: [], sv_selected:[],
            filtered: false, userId: '', fieldError: '',fieldGidError:'', userName: '', email:'', tin:'',successmessage: '',groupId:'',
            progMsg: '', searchInfo: null,errorMsg:'', typeCD:'', userIDs:'', usrCsv: this.props.usrCsv, status: '',
            searchRecipients: false

        }
    }

    componentDidMount() {

        if(isNotEmpty(this.props.usrCsv && this.state.init)) {
            this.search(null, null, true);

        }

    }
    setSearchInfo = (searchInfo) => {
        if(!searchInfo)
            searchInfo = {userName: this.state.userName, email: this.state.email,
                tin: this.state.tin, typeCD: this.props.typeCD, groupId:this.state.groupId, userIDs: this.state.userIDs, status:this.state.status};
        else this.setState({userName: searchInfo.userName, email: searchInfo.email, tin: searchInfo.tin,
            groupId: searchInfo.groupId, userIDs: searchInfo.userIDs, status: searchInfo.status})
        this.setState({progMsg:`Please wait while we retrieve data.`,fieldError: '',fieldGidError:'',
            errorMsg: '',users: [],filteredUsers: [],successmessage: '', searchInfo: searchInfo,filtered:false } )
        return searchInfo;
    }
    search = (resetErrorMsg, searchInfo) => {

        searchInfo = this.setSearchInfo(searchInfo);
        this.setSelected([]);
        AuthenticationService.searchUser(searchInfo.userName, searchInfo.email, searchInfo.tin, searchInfo.typeCD,
            searchInfo.groupId,searchInfo.userIDs, searchInfo.status, 'true')
            .then(response => {

                if(response.status === 200 ) {
                   this.setState({recipients: response.data,filteredRecipients: response.data , progMsg:''});
                   if(resetErrorMsg){
                       this.setState({errorMsg: response.data.length === 0 ?
                       `There are no users available for the criteria entered. Please revise your search criteria.`:''});
                    }

                 }
            }).catch((error) => {
            this.setState({errorMsg:`Error: There was an error retrieving data. Please try again or contact Customer Service.`, progMsg:''});
            console.log(`Search For User Error: ${error}`);
        })
    }


    handleSearch = () => {
        if( this.state.email === '' && this.state.status === '' && this.state.tin === '' && this.state.groupId === '' && this.state.userIDs === '') {
            this.setState({fieldError:'Error: Enter Username or Email Address or Tax ID Number (TIN) or Group ID',
                errorMsg: `An error has occurred. Please address all fields marked with “Error".`});
        } else if(this.state.groupId !== '' && !isNumeric(this.state.groupId)){
            this.setState({fieldGidError:'Error: Enter numeric only for Group ID field',
                errorMsg: `An error has occurred. Please address all fields marked with “Error".`})
        } else if(this.state.userIDs !== '' && this.state.userIDs.length < 3) {
            this.setState({
                fieldError: 'Error: Enter a minimum of 3 characters in usernames field',
                errorMsg: `An error has occurred. Please address all fields marked with “Error".`
            })
        } /*else if(isEmpty(this.state.status )) {
            this.setState({
                fieldError: 'Error:Please enter status',
                errorMsg: `An error has occurred. Please address all fields marked with “Error".`
            })
        } */else {
            this.search(true);
        }

    }
    handleReset = () => {
        this.setState({fieldError: '', fieldGidError: '', UserIDs: '',userName: '', email:'', tin:'', groupId:'',
            progMsg: '', errorMsg: '',recipients: [],filteredRecipients: [],successmessage:'',filtered:false, status:'' })
    }


    setSelected = ( selected) => {

        this.setState({selected: selected});

    }
    onChange = event => {
        const { target: { name, value } } = event;

        this.setState({[name] :value });
    }

    setFilter = (filtered) => {

        if(filtered && filtered.length > 0)
            this.setState({filteredRecipients:filtered, filtered:true})
        else this.setState({filtered:false});
    }

    close = () => {
      this.props.close();
    }

    render() {
        return(
            <Grid container spacing={2} justifyContent="center" alignItems="center">

                <div className='App-content Manage-users'>
                    <h1 className='App-page-label'>Manage Notifications Recipients</h1>

                    <LoadingMsgBar loadMsg={this.state.progMsg}/>

                    <GlobalMessage message={this.state.errorMsg}/>

                    <GlobalMessage type='success' message={this.state.successmessage}/>

                    <div
                        style={{
                            paddingTop: '.5rem',
                            paddingBottom: '1rem'
                        }}>{`Search recipients by entering User status or Email Address or Tax ID Number (TIN) or Group ID or or Status or Username, or Usernames comma seperated.`}</div>
                    <Tooltip hidden={isNotEmpty(this.state.email)} className='App-tool-tip' title={isEmpty(this.state.email) ? 'Enter a leading * for a partial email search':''} disableHoverListener placement='bottom-start'
                             aria-label="Enter at least three characters for a partial search">
                    <TextField
                        id="email"
                        name='email'
                        autoFocus
                        disabled={this.state.userName !== '' || this.state.tin !== '' || this.state.groupId !== ''  || this.state.userIDs !== ''}
                        style={{maxWidth: '25%'}}
                        inputProps={{maxLength: 120}}
                        defaultValue=" "
                        label="Email Address"
                        margin="normal"
                        variant="outlined"
                        onChange={event => this.setState({email: event.target.value})}
                        value={this.state.email}

                    />
                    </Tooltip>
                    <div style={{
                        display: 'inline',
                        paddingRight: '2%',
                        paddingLeft: '2%',
                        verticalAlign: '-3em',
                        fontWeight: 'bold'
                    }}>OR
                    </div>
                    <TextField
                        id="tin"
                        name='tin'
                        disabled={this.state.email !== '' || this.state.userName !== '' || this.state.groupId !== '' || this.state.userIDs !== ''}
                        style={{maxWidth: '25%'}}
                        inputProps={{maxLength: 9}}
                        defaultValue=" "
                        label="Tax ID Number (TIN)"
                        margin="normal"
                        variant="outlined"
                        onChange={event => this.setState({tin: event.target.value})}
                        value={this.state.tin}
                    />
                    <div style={{
                        display: 'inline',
                        paddingRight: '2%',
                        paddingLeft: '2%',
                        verticalAlign: '-3em',
                        fontWeight: 'bold'
                    }}>OR
                    </div>
                    <TextField
                        id="groupId"
                        name='groupId'
                        error={this.state.fieldGidError !== ''}
                        helperText={this.state.fieldGidError}
                        disabled={this.state.email !== '' || this.state.userName !== '' || this.state.tin !== '' || this.state.userIDs !== ''}
                        style={{maxWidth: '25%'}}
                        inputProps={{maxLength: 9}}
                        defaultValue=" "
                        label="Group ID"
                        margin="normal"
                        variant="outlined"
                        onChange={event => this.setState({groupId: event.target.value})}
                        value={this.state.groupId}
                    />
                    <div style={{
                        display: 'inline',
                        paddingRight: '2%',
                        paddingLeft: '2%',
                        verticalAlign: '-3em',
                        fontWeight: 'bold'
                    }}>OR
                    </div>
                    <TextField
                        id="status"
                        name='status'
                        disabled={this.state.email !== '' || this.state.tin !== '' ||
                            this.state.groupId !== ''  || this.state.userIDs !== ''}
                        style={{maxWidth: '25%'}}
                        error={this.state.fieldError !== ''}
                        helperText={this.state.fieldError}
                        inputProps={{maxLength: 14}}
                        defaultValue=" "
                        label="Status"
                        margin="normal"
                        variant="outlined"
                        onChange={event => this.setState({status: event.target.value})}
                        value={this.state.status}
                    />

                    <Grid
                        container

                        style={{height: "100%", width: "95%"}}
                    >
                        <Grid item style={{width: "2.4em"}}>
                        <div style={{
                           display:"inline",

                            verticalAlign: '-3em',
                            fontWeight: 'bold'
                        }}>OR
                        </div>
                        </Grid>
                        <Grid item style={{width: "41%"}}>



                            <TextField
                                id="userIDs"
                                name="userIDs"
                                label="Usernames comma seperated"
                                disabled={this.state.email !== '' || this.state.userName !== '' || this.state.tin !== '' || this.state.groupId !== ''}
                                placeholder="Placeholder"
                                multiline
                                fullWidth
                                onChange={event => this.setState({userIDs: event.target.value})}
                                variant="outlined"
                                value={this.state.userIDs}
                            />
                        </Grid>
                    </Grid>
                    <div className='Manage-tins-btn-ln'>
                        <Button onClick={this.handleSearch}>
                            SEARCH RECIPIENTS
                        </Button>

                        <Button variant="outlined" onClick={this.handleReset}>
                            RESET
                        </Button>

                        <Button variant="outlined" onClick={this.close}>
                            CLOSE
                        </Button>
                    </div>
                    <div/>
                </div>

                {this.state.recipients.length === 0 ? '' :
                    <div>

                        <Grid
                            container

                            justifyContent="space-between"
                            style={{ width: "95%"}}
                        >

                            <Grid item style={{width: "100%"}}>

                                <ManageRecipientsSearchTbl users={this.state.filteredRecipients} setFilter={this.setFilter}
                                                           unfilteredUsers={this.state.recipients}
                                                           handleSelectAction={this.props.saveSelected} add={true}
                                                           setSelected={this.setSelected} actionOnSelected={this.props.saveSelected}
                                                           selected={this.state.selected} />

                            </Grid>
                        </Grid>
                    </div>
                }
            </Grid>

        )
    }
}
export default (SearchRecipients);