import axios from 'axios';
import {config} from "./ConfigService";

class ClaimSearchService {
   
    findClaimsByClaimant(searchParams) {
        let url = `${config.API_URL}/globalclaim/searchByClaimant`;
        return axios.post(url, searchParams);
    }

   
}

export default new ClaimSearchService();
