import { isDateFormat, isNotEmpty } from './Validator.js';

const MILLIS_IN_DAY = 1000 * 60 * 60 * 24;

export function toLowerCase(str) {
    if ((str === undefined) || (str === null)) {
        return str;
    } else {
        return str.toLowerCase();
    }
 
}

export function getValue(value, defaultValue) {
    if ((value === undefined) || (value === null)) {
        return defaultValue;
    }

    return value;
}

export function isEmpty(value) {
    if ((value === undefined) ||
        (value === null) ||
        (value.length === 0)) {
        return true;
    }

    return false;
}

export function getDtMnMxFmt(date) {
    return `${date.getFullYear()}-${ date.getMonth() > 8 ? '': '0' }${ (date.getMonth() +1)}-${date.getDate() > 9 ? '' : '0'}${date.getDate()}`;
}
export function getEndDtMaxPreset(begDate, numDays) {
    let date = new Date(begDate);
    date.setDate(date.getDate() + numDays);
    return getDtMnMxFmt(date);
}

export function getEndDtMinPreset(begDate) {
    if(!begDate)
        return;
    let date = new Date(begDate);
    date.setDate(date.getDate() + 1);
    return getDtMnMxFmt(date);
}

export function setFocus(compSelector) {
    let comp = document.querySelector(compSelector);

    if (comp !== null) {
        comp.focus();
    }
}

// https://gomakethings.com/how-to-get-the-first-and-last-focusable-elements-in-the-dom/
// selector should target the div the component is in
// then the first focusable component in that div is given the focus
export function setInitialFocus(selector) {
    setTimeout(function () {
        var theDiv = document.querySelector(selector);
        let comp = null;

        if (theDiv !== null) {
            comp = theDiv.querySelector('button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])');

            if (comp !== null) {
                comp.focus();
            }
        }
    }, 0);
}

// Sets focus on first input component that has an error
// If there is no error but we have a Global Error, the screen is scrolled to the top
// Finds the first error div then a focusable component in it and sets the focus
// If no component has the error but there is the global error then the global error
// is scrolled into view and focus is left on the component the user had the focus on
// If a selector is provided that one is tried first
export function setFocusOnFirstError(selector) {
    let div = null;
    let comp = null;

    console.log("setFocusOnFirstError: " + selector);

    if (isNotEmpty(selector)) {
        comp = document.querySelector(selector);

        if (comp !== null) {
            comp.focus();
        }
    }

    // If component you wanted was not found then we'll look for other components
    if (comp === null) {
        div = document.querySelector("div.Mui-error");

        if (div !== null) {
            comp = div.querySelector('button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])');

            if (comp !== null) {
                comp.focus();
            }
        } else {
            comp = document.querySelector(".globalErrorMessage");

            if (comp !== null) {
                comp.scrollIntoView({ behavior: "smooth" });
            }
        }
    }
}

// 2019-03-25T23:28:56.782Z
export function fromIso8601Date(value) {
    let pattern = /^[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}:[0-9]{2}.[0-9]{3}Z$/;
    let date = null;

    if (isEmpty(value)) {
        return value;
    }

    if (value.match(pattern)) {
        date = new Date(value);
    }

    return date;
}

export function fromYYYYMMDD(value) {
    let pattern = /^[0-9]{4}-[0-9]{2}-[0-9]{2}$/;
    let date = null;
    let datePart = null;

    if (isEmpty(value)) {
        return value;
    }

    if (value.match(pattern)) {
        // date = new Date(value);

        datePart = value.split("-");

        date = new Date(datePart[0], datePart[1] - 1, datePart[2], 0, 0, 0, 0);
    }

    return date;
}

//expected input mm/dd/yyyy or mm.dd.yyyy or mm-dd-yyyy or mm dd yyyy mm\dd\yyyy
// or ISO 8601, standard JSON format, 2019-03-25T23:28:56.782Z
// or any combination of those including single digt month and day such as m/d/yyyy
// if it is not a valid date a null value is returned
// otherwise a mm dd yyyy date is returned with the hours, minutes, millis set to 0
export function parseDate(value) {
    let isValid = false;
    let datePart = null;
    let date = null;

    if (value instanceof Date) {
        return value;
    }

    if (isDateFormat(value)) {
        datePart = value.split(/[-\\\\/. ]/);// - . \ / space
        date = new Date(datePart[2], datePart[0] - 1, datePart[1], 0, 0, 0, 0);

        isValid = (date.getFullYear() === parseInt(datePart[2])) &&
            ((date.getMonth() + 1) === parseInt(datePart[0])) &&
            (date.getDate() === parseInt(datePart[1]));

        if (isValid === false) {
            date = null;
        }
    }

    if (date === null) {
        date = fromYYYYMMDD(value);
    }

    if (date === null) {
        date = fromIso8601Date(value);
    }

    return date;
}

// Returns the number of days between dates
// If endDate is before start date a negative value is returned
// indicating the number of days in the past
// expecting date objects
export function daysBetweenDates(startDate, endDate) {
    let millisDifference = 0;
    let daysBetween = 0;

    if ((startDate instanceof Date) && (endDate instanceof Date)) {
        millisDifference = endDate.getTime() - startDate.getTime();
        daysBetween = millisDifference / MILLIS_IN_DAY;
    }

    return daysBetween;
}

// if a comes before b return -1
// if a comes after b return 1
// empty values come first
export function compareIgnoreCase(a, b) {
    if (isNotEmpty(a) && isNotEmpty(b)) {
        return a.toUpperCase().localeCompare(b.toUpperCase());
    } else if (isEmpty(a) && isNotEmpty(b)) {
        return -1;
    } else if (isNotEmpty(a) && isEmpty(b)) {
        return 1;
    } else {
        return 0;
    }
}

export function debounce(callback, wait) {
    let timeoutId = undefined;

    return (...args) => {
        window.clearTimeout(timeoutId);

        timeoutId = window.setTimeout(() => {
            callback.apply(null, args);
        }, wait);
    };
}
