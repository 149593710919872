import React, {Component, Fragment} from 'react'

import NotificationsTable from "../NotificationsTable";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { isNotEmpty} from "../../utils/Validator";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import {Grid,  TextField} from "@material-ui/core";
import ManageRecipients from "./ManageRecipients";
import NotificationService from "../../Services/NotificationService";
import LoadingMsgBar from "../LoadingMsgBar";
import GlobalMessage from "../GlobalMessage";
import {isEmpty} from "../../utils/Utils";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";




window.analytics = {pageName: 'Notifications'};

class Notifications extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filteredNotifications: [], notifications: [],createEnabled: true,
            notificationLink: '', recipients: false,fieldError: '',errorMsg: '',progMsg:'',successMsg:'',
            name: '',sendDt:'',status:'',sendEmail:false, campaign:'', wcp_notification_id:'',
            requestedBy:'', createDt:null,lastUpdate:null, lastUpdateBy:'',
            userNotList:'',sendDateError:'', isEdit:false, isEditEnabled: true,dlgOpen:false,
            selectNotification:null, unPublish:false
        }
    }
    setFilter = (filtered) => {

        if(filtered && filtered.length > 0)
            this.setState({filteredNotifications:filtered, filtered:true})
        else this.setState({filtered:false});
    }
    resetMsgs = () => {
        this.setState({fieldError: '',errorMsg: '',progMsg:'', successMsg:''});
    }

    componentDidMount() {
        this.loadTable();

    }

    validateNotification = () => {
        this.resetMsgs();
        let notification = this.getNotification();
        if(isEmpty(this.state.name) ) {
            this.setState({fieldError:'Error: Enter Notification name',
                errorMsg: `An error has occurred. Please address all fields marked with “Error".`});
            return null;
        } else {
            return notification;
        }

    }

    getStatus = (notification) => {
        let isValid = isNotEmpty(notification.campaign) &&
        isNotEmpty(notification.userNotList) &&
        isNotEmpty(notification.link) && isNotEmpty(notification.name) ;
        let partialStatus = isValid? 'READY' : 'DRAFT';
        if( partialStatus === 'DRAFT') {
            return partialStatus;
        }
        return partialStatus === 'READY' && isNotEmpty(this.state.sendDt)? 'SCHEDULED' :'READY';
    }

    getNotification = () => {

        const name = isNotEmpty(this.state.name)? this.state.name : 'Provider Portal Notification';
            let notification = {
            wcp_notification_id:this.state.wcp_notification_id,
            name: name,
            sendDt:this.state.sendDt,
            status:this.state.status,
            sendEmail:!this.state.sendEmail?'NO':'YES',
            campaign:this.state.campaign,
            requestedBy:this.state.requestedBy,
            createDt:null,
            lastUpdate:null,
            lastUpdateBy:this.props.userNm,
            userNotList:this.state.userNotList,
            link: this.state.notificationLink
        }
        notification.status = this.getStatus(notification);
        return notification;
    }

    setNotificationEdit = (notification) => {
        if(!notification) {
            return;
        }
        this.setState({name: notification.name, sendDt: notification.sendDt,
            status:notification.status, sendEmail: notification.sendEmail?'YES':'NO',
            campaign: notification.campaign, requestedBy: notification.requestedBy,
            notificationLink: notification.link, isEdit: true, userNotList: notification.userNotList,
            wcp_notification_id:notification.wcp_notification_id, lastUpdateBy:notification.lastUpdateBy,
            unPublish: false,  isEditEnabled:notification.status === 'SENT'? false: true
        });

    }

    deleteNotification = () => {
        this.setState({dlgOpen:false});
        NotificationService.deleteNotification(this.state.selectNotification.wcp_notification_id).then(response => {
            if (response.status === 200) {
                this.loadTable();
            }
        }).catch((error) => {
            console.log(`Delete notification error ${error}`);
        })
    }

    unPublishNotification = () => {
        this.setState({dlgOpen:false, unPublish: false });
        let notification = this.state.selectNotification;
        notification.status = 'READY';
        notification.sendDt = null;
        this.updateNotification(notification);
        NotificationService.deleteUserNotification(notification.wcp_notification_id).then(response => {
                if (response.status === 200) {
                    this.loadTable();
                }
        }).catch((error) => {
            console.log(`Notification Un-publish error ${error}`);
        })

    }

    handleReset = () => {
        this.setState({notificationLink: '', recipients: false,
            name: '',sendDt:null,status:'',sendEmail:false, campaign:'', wcp_notification_id:'',
            requestedBy:'', createDt:null,lastUpdate:null, lastUpdateBy:'',
            userNotList:'',sendDateError:'', isEdit:false});
        this.resetMsgs();
    }

    handleSelectedAction = (notificationId, action) => {
        const selectedNotification =  this.state.notifications.find((not) => not.wcp_notification_id === notificationId);
        if(!selectedNotification) {
            return;
        }

        if (action === 'EDIT') {
           this.setNotificationEdit(selectedNotification);
        } else if (action === 'DELETE'){
            this.setState({dlgOpen:true, unPublish: false, selectNotification: selectedNotification});

        } else if (action === 'UN-PUBLISH') {
            this.setState({dlgOpen:true, unPublish: true, selectNotification: selectedNotification});
           // this.unPublishNotification(selectedNotification);
        }
    }

    handleNotificationAction = () => {
        let notification = this.validateNotification();

        if(notification && this.state.isEdit) {
            this.updateNotification(notification);
        } else if(notification) {
            this.createNotification(notification)
        }
    }

    createNotification = (notification) => {

        NotificationService.create(notification)

            .then(response => {
                if(response.status === 200  ) {
                    this.handleReset();
                    this.loadTable()
                    this.setState({
                        successMsg:`Notification Created.`, errorMsg: ''})

                }
            }).catch((error) => {
            this.setErrorMsg()
            console.log(`Notification Create error ${error}`);
        })

    }

    updateNotification = (notification) => {

        this.handleReset();

        NotificationService.update(notification)

            .then(response => {
                if(response.status === 200  ) {

                    this.loadTable();
                    this.setState({
                        successMsg:`Notification updated.`, errorMsg: ''})

                }
            }).catch((error) => {
            this.setErrorMsg()
            console.log(`Notification Update error ${error}`);
        })
    }

    setRowDisplayData = (rows) => {
        rows.forEach((not) => {
            if('SENT' === not.status) {
                not.actions = ['EDIT','UN-PUBLISH'];
            } else {
                not.actions = ['EDIT','DELETE'];
            }
        })
      return rows;
    }
    loadTable = () => {

        NotificationService.getNotifications()
            .then(response => {
                if(response.status === 200  ) {
                    const rows = this.setRowDisplayData(response.data)
                    this.setState({filteredNotifications: rows, notifications: response.data, selectNotification: null, unPublish:false});

                }
            }).catch((error) => {
            this.setErrorMsg()
            console.log(`Error retrieving Notification List: ${error}`);
        })
    }

    setRecipients = (recipients) => {
        console.log(`recipients->${recipients}`)
        this.setState({userNotList:recipients});
    }

    handleClose = () => {
        this.setState({dlgOpen:false, unPublish: false});
    }

    closeRecipients = () => {
        this.setState({recipients: false});
    }
    renderRecipients = () => {
        this.setState({recipients: true});

    }
    renderManageRecipients = () => {
        return (<ManageRecipients close={this.closeRecipients} setRecipients={this.setRecipients} usrCsv={this.state.userNotList}/>);
    }
    render() {
        return this.state.recipients ? this.renderManageRecipients(): this.renderNotifications();

    }
    renderNotifications = () => {


        return(
<Fragment>


                <div className='App-content Manage-tins App-font'>
                    <h1 className='App-page-label'>Notifications</h1>
                    <LoadingMsgBar loadMsg={this.state.progMsg}/>

                    <GlobalMessage message={this.state.errorMsg}/>

                    <GlobalMessage type='success' message={this.state.successmessage}/>

                    <div>

                        <div>
                            <Grid
                                container

                                justifyContent="space-between"
                                style={{height: "100%", width: "95%"}}
                            >
                                <Grid item style={{width: "50%", height: "50%", overflowY: "auto"}}>
                                    <TextField id="name" name="name"
                                               label="Notification Name"
                                               fullWidth
                                               required
                                               disabled={!this.state.isEditEnabled}
                                               error={this.state.fieldError !== ''}
                                               helperText={this.state.fieldError}
                                               value={this.state.name}
                                               style={{verticalAlign: +4, width: "90%"}}
                                               inputProps={{maxLength: 64}}
                                               onChange={event => this.setState({name: event.target.value})}
                                    />
                                </Grid>
                                <Grid item style={{width: "50%", height: "50%", overflowY: "auto"}}>
                                    <TextField id="campaign" name="campaign"
                                               label="Campaign"
                                               fullWidth
                                               disabled={!this.state.isEditEnabled}
                                               required
                                               value={this.state.campaign}
                                               style={{verticalAlign: +4, width: "90%"}}
                                               inputProps={{maxLength: 64}}
                                               onChange={event => this.setState({campaign: event.target.value})}
                                    />
                                </Grid>



                            </Grid>


                        </div>


                    </div>
                    <Grid
                        container

                        justifyContent="space-between"
                        style={{height: "100%", width: "95%"}}
                    >
                        <Grid item style={{width: "50%", height: "50%", overflowY: "auto"}}>
                            <TextField id="requestedBy" name="billControlNumber"
                                       label="Requested by"
                                       fullWidth
                                       disabled={!this.state.isEditEnabled}
                                       value={this.state.requestedBy}
                                       style={{verticalAlign: +4, width: "90%"}}
                                       inputProps={{maxLength: 22}}
                                       onChange={event => this.setState({requestedBy: event.target.value})}
                            />
                        </Grid>
                        <Grid item style={{width: "50%"}}>
                            <TextField id="notification" name="notification"
                                       label="Notification Link"
                                       fullWidth
                                       disabled={!this.state.isEditEnabled}
                                       required
                                       value={this.state.notificationLink}
                                       style={{width: "90%"}}
                                       onChange={event => this.setState({notificationLink: event.target.value})}
                            />


                        </Grid>

                    </Grid>


                    <div>
                        <Grid
                            container

                            justifyContent="space-between"
                            style={{height: "100%", width: "95%"}}
                        >
                            <Grid item style={{width: "50%", height: "50%", overflowY: "auto"}}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>

                                    <KeyboardDatePicker
                                        error={isNotEmpty(this.state.sendDateError)}
                                        helperText={this.state.sendDateError}
                                        value={this.state.sendDt}

                                        onChange={date => {
                                            if (date instanceof Date) {
                                                date.setHours(8, 0, 0, 0);
                                                this.setState({sendDt: date});
                                            } else {
                                                this.setState({sendDt: null});
                                            }
                                        }}
                                        style={{width: "90%"}}
                                        disabled={!this.state.isEditEnabled}
                                        format="MM/dd/yyyy"
                                        margin="normal"
                                        id="sendDate"
                                        label="Notification Send Date"
                                        fullWidth
                                        disablePast
                                        placeholder='MM/DD/YYYY'
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                        required
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item style={{width: "25%", height: "50%", overflowY: "auto"}}>
                                <Checkbox disabled={true} style={{fontWeight: 300,verticalAlign:'-2em'}}
                                          onChange={event => this.setState({sendEmail: this.state.sendEmail?false:true})}
                                          inputProps={{'aria-labelledby': 'Email?'}}

                                /><span style={{fontWeight: 300,verticalAlign:'-3em'}}>Send Email?</span>
                            </Grid>
                            <Grid item style={{width: "25%"}}>
                                <Button style={{margin: "1.7em"}}
                                        disabled={!this.state.isEditEnabled}
                                        onClick={this.renderRecipients}  variant="contained" color="primary" >MANAGE RECIPIENTS</Button>
                            </Grid>

                        </Grid>


                    </div>
                    <Grid
                        container

                        justifyContent="space-between"
                        style={{height: "100%", width: "95%"}}
                    >
                        <Grid item style={{width: "50%", height: "50%", overflowY: "auto"}}>

                        <Button style={{verticalAlign:'-3em'}} disabled={!this.state.isEditEnabled} onClick={this.handleNotificationAction}>
                            {this.state.isEdit? 'UPDATE' : 'CREATE'} NOTIFICATION
                        </Button>

                        <Button style={{verticalAlign:'-3em'}} variant="outlined" onClick={this.handleReset}>
                            {this.state.isEdit? 'CANCEL EDIT' : 'RESET'}
                        </Button>



                        </Grid>

                    </Grid>
                </div>
                <div className='Manage-users-tbl-div'>
                    <NotificationsTable users={this.state.filteredNotifications} setFilter={this.setFilter}
                                        unfilteredUsers={this.state.notifications}
                                        userName={this.props.userNm}
                                        handleSelectAction={this.handleSelectedAction}/>
                </div>
    <Dialog
        open={this.state.dlgOpen === true}
        onClose={this.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >

        <DialogContent >
            <DialogContentText id="user-list-agreementId" style={{overflow:'hidden', color:'#000'}} elevation={0}>
                {this.state.unPublish ?<div>
                        <p> UN-PUBLISH will recall the notification from recipients. </p>
                        <p> Click CANCEL to stop this action or CONTINUE UN-PUBLISH.</p>
                    </div>
                    : <div>
                        <p> DELETE will remove the notification. </p>
                        <p> Click CANCEL to stop this action or CONTINUE DELETE.</p>
                    </div> }
            </DialogContentText>

            <div style={{textAlign:'right'}}>
                <Button variant="outlined" autoFocus  onClick={this.state.unPublish?this.unPublishNotification: this.deleteNotification} >
                    CONTINUE >></Button>
                <Button variant='text' onClick={this.handleClose} >
                    CANCEL >></Button>

            </div>
        </DialogContent>




        <div style={{height:'10px'}}></div>

    </Dialog>

</Fragment>);

    }


}

export default (Notifications);