import React, {Component, Fragment} from 'react'




class News extends Component {
    constructor(props) {
        super(props);
        this.state = {
            taxId: '', provider: false
        }
    }



    render() {


        return(
            <Fragment>
                <div className='App-content'>
                <h1>News</h1>
                </div>
            </Fragment>

        );

    }
}
export default News;