import React from 'react';
import ReactDOM from 'react-dom';
import "core-js/stable";
import "regenerator-runtime/runtime";
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import dotenv from  'dotenv'
import { Provider } from "react-redux";
import store from "./store/index";
import {config} from "./Services/ConfigService";
//import 'react-datepicker';
import 'date-input-polyfill';

dotenv.config();

config.loadConfig().then(() => {
	load();
}).catch( (error) =>{
	load();
})

function load() {
ReactDOM.render(<Provider store ={store}> 
    <App />
    </Provider>, 
document.getElementById('root'));
ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
}