import React, {Fragment} from 'react';
import ContactUsDlg from './../ContactUsDlg'
import {NavLink} from "react-router-dom";


export default () => {


        return (
            <Fragment>



                <div className='Header' >
                    <div style={{width:'100%',paddingTop:'.75rem', paddingBottom: '.75rem',paddingRight:'1rem', textAlign:'right', fontSize:'13px', color:'#0f197d'}} className='App-ltgrey-bkgrnd'>

                        <ContactUsDlg />

                    </div>
                    <div></div>
                    <div style={{color:'black', width:'100%'}}>
                        <NavLink style={{width:'60px', height: '20px'}} to='/wcpp/login' className='Header-icon-link' aria-label='Opens Login Page in the current window'>
                            <img src={require('../../images/Coventry-Provider-logon-279x58.png')} alt="Coventry Provider"
                                 style={{width: '1', height: '1', padding: '.7em'}}/>
                        </NavLink>

                            <img src={require('../../images/Coventry-logo-279x58.png')} alt="Coventry An Enlyte Company"
                                 style={{float:'right',width: '1', height: '1', padding: '.7em'}}/>

                    </div>
                    <div style={{width:'100%', height:'2.2rem', backgroundColor:'#0F197D'}}>

                    </div>
                </div>

            </Fragment>
        );

}
