import axios from 'axios';
import {config} from "./ConfigService";

class RegistrationService {
    findSecurityQuestions() {
        return axios.get(`${config.API_URL}/public/secQuestions`);
    }

    submitRegistrationRequest(emailAddress, campaign) {
        return axios.post(`${config.API_URL}/public/registration/start`, {
            emailAddress:emailAddress,
            campaign: campaign
        } );
    }

    submitAuthenticationForm(authenticationForm) {
        return axios.post(`${config.API_URL}/public/registration/complete`, authenticationForm);
    }

    completeInternalUserRegistration(authenticationForm) {
        return axios.post(`${config.API_URL}/public/completeInternalUserRegistration`, authenticationForm);
    }
}

export default new RegistrationService();
