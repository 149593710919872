import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Button from '@material-ui/core/Button';
import {withStyles} from '@material-ui/core/styles';
import Cookies from 'universal-cookie';
import PropTypes from 'prop-types';
import './AcceptanceAgreeDlg.css'

const styles = {
    menuButton: {
        color: "#ffffff",
        '&:hover': {
            textDecoration: 'underline'
        }
    }
  };

class AcceptanceAgreeDlg extends React.Component {
    cookies = new Cookies();
    constructor(props) {
        super(props);
        this.state = {
            open: false, show: true
        };
    }

    handleClickOpen = () => {
        if(!this.state.show) {
           this.props.nav();
        } else {
            this.setState({open: true});
        }

    };

    handleClose = () => {
        this.setState({ open: false });

    };

    componentWillMount() {
        console.log('check cookie for agreement');
        if( this.cookies.get('Agreement-Accepted')) {
            this.setState({show: false});
        }
    }

    handleAccept = () => {
        this.cookies.set('Agreement-Accepted');
        this.setState({show:false});
        this.handleClose();
        this.props.nav();
    }




    render() {
        
        return (
            <div style={{display:'inline-block',}}>
                <Button style={styles.menuButton} className="mainMenuButton" variant="text" onClick={this.handleClickOpen}>Client List</Button>

                <Dialog
                    open={this.state.open}
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >

                    <DialogContent >
                        <DialogContentText id="user-list-agreementId" style={{overflow:'hidden', color:'#000'}} elevation={0}>
                            <p> Use this application to determine the Coventry clients that consider you to be part of their network. While you may have an active Coventry contract for one or more network products, clients have the ability to exclude providers or the groups they participate in from the network they offer to their clients. This tool will not only help you verify your participation with an employer or insurer, it can also give you information about where to submit claims. </p>
                            <p> While it is our goal to provide the most up to date information, our clients and their clients change. Always verify that the patient seeking care from you has a payer that considers you participating in their network.</p>
                            <p> Note:  Workers’ Comp and AUTO payers do not provide ID cards to insureds.  Providers must access the Client/Payer list on this site in order to determine whether they are participating in the  WC or Auto Network for a particular member or contact the employer or insurer.</p>
                        </DialogContentText>

                      <div style={{textAlign:'right'}}>
                          <Button variant="outlined" autoFocus  onClick={this.handleAccept} aria-label='Client List: ACCEPT AND CONTINUE Button'>
                              ACCEPT AND CONTINUE >></Button>
                          <Button variant='text' onClick={this.handleClose} aria-label='Client List: DECLINE Button'>
                              DECLINE >></Button>

                      </div>
                    </DialogContent>




                    <div style={{height:'10px'}}></div>

                </Dialog>
            </div>
        );
    }
}
AcceptanceAgreeDlg.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AcceptanceAgreeDlg);

