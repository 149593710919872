
import CircularProgress from "@material-ui/core/CircularProgress";
import React from "react";


export default props => {

    return(
        <div className='App-loading-div ' hidden={props.loadMsg === ''}>
            <CircularProgress color='primary' size='1.25em'/>
            <span>{props.loadMsg}</span>

        </div>
    );
}