// User Stories: 144473

import React, { Component } from 'react';
import { Grid, Button, TextField } from "@material-ui/core";
import { isNotEmpty, isEmpty, isDocumentId, isBillControlNumber } from '../../../utils/Validator.js';

import BillSearchService from "../../../Services/BillSearchService";
import AuthenticationService from "../../../Services/AuthenticationService";
import "./BillSearch.css";

window.analytics ={pageName:'Bill Search: Bill Information'};
class BillInformationInputComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            processingMessage: 'Please wait while we process your request.',
            wasSubmitted: false,
            errCompSelector: null,

            tinList: [],
            billControlNumber: '',
            documentId: '',

        }
        this.billCtrInput = React.createRef();
    }

    // save any changes the user makes into the state variable so you can
    // get back to the state the user was in
    handleInputChange = (event) => {
        let target = event.target;
        let value = target.type === 'checkbox' ? target.checked : target.value;
        let name = target.name;

        this.setState({
            [name]: value
        });
    }

    validate = () => {
        this.props.enableDisableSearch(false);
        let syntaxResults = this.doSyntaxValidation();

        if (!syntaxResults.isValid) {
            this.setGlobalErrorMessage('An error has occurred. Please address all fields marked with “Error”.');
            this.showMessages(syntaxResults.messageList);
            this.props.enableDisableSearch(true);  
        }

        return true;
    }

    showMessages(messageList) {
        messageList.forEach(message => {
            this.addErrorMessage(message.field, message.message);
        });
    }

    addErrorMessage(fieldName, errorMessage) {
        this.setState({
            [fieldName]: errorMessage
        });
    }

    clearErrorMessage(fieldName) {
        this.setState({
            [fieldName]: ''
        });
    }

    componentDidMount() {
        this.props.searchTypeChange('BILL_INFORMATION');
        this.loadTinsForLoggedInUser();
        this.props.enableDisableSearch(true);
        setTimeout(()=>{
            if(this.billCtrInput && this.billCtrInput.current)this.billCtrInput.current.focus();},1);
   }

    loadTinsForLoggedInUser() {
        let userName = null;

        userName = AuthenticationService.getLoggedInUserName();

        this.loadTins(userName);
    }

    getFormData() {
        let searchParams = null;

        searchParams = {
            searchType: "BILL_INFORMATION",
            isTinArray: true,
            tinArray: this.state.tinList,
            searchParams: {
                billControlNumber: (isNotEmpty(this.state.billControlNumber) ? this.state.billControlNumber.trim() : undefined),
                documentId: (isNotEmpty(this.state.documentId) ? this.state.documentId.trim() : undefined),
            }
        };

        return searchParams;
    }

    getFormData_TEST() {
        let searchParams = null;

        searchParams = {
            searchType: "BILL_INFORMATION",
            searchParams: {
                ftin: "262484838",
                // billControlNumber: "2018896",
                documentId: "2019151Z0011272"
            }
        };

        return searchParams;
    }

    clearForm() {
        this.clearFields();
        this.clearErrorMessages();
        this.props.clearSearchResults();
    }

    clearFields() {
        this.setState({
            billControlNumber: '',
            documentId: ''
        });
    }

    handleResetButtonOnClick = () => {
        this.clearForm();
    }

    handleSearchButtonOnClick = () => {
        let searchParams = null;

        this.clearErrorMessages();
        this.props.clearSearchResults();

        // Used in setting focus on first error
        this.setState({ wasSubmitted: true });

        if (this.validate()) {
            searchParams = this.getFormData();
            this.props.handleSearch(searchParams);
        }
    }

    doSyntaxValidation() {
        let messageList = [];
        let isValid = false;

        if (isEmpty(this.state.billControlNumber) && isEmpty(this.state.documentId)) {
            if (messageList.length === 0) {
                this.setState({ errCompSelector: '#billControlNumber' });
            }

            messageList.push({ field: "missingFieldError", message: "Error: Enter Bill Control Number or Document ID Number" });
        } else if (isNotEmpty(this.state.billControlNumber) && (!isBillControlNumber(this.state.billControlNumber))) {
           // messageList.push({ field: "billControlNumberError", message: "Error: Enter a valid Bill Control Number" });
        } else if (isNotEmpty(this.state.documentId) && (!isDocumentId(this.state.documentId))) {
            messageList.push({ field: "documentIdError", message: "Error: Enter a valid Document ID Number" });
        }

        if (messageList.length === 0) {
            isValid = true;
        }

        return { isValid: isValid, messageList: messageList };
    }

    clearErrorMessages() {
        let errorFieldList = [
            'tinError',
            'missingFieldError',
            'billControlNumberError',
            'documentIdError'
        ];

        errorFieldList.forEach(errorFieldName => {
            this.clearErrorMessage(errorFieldName);
        });

        this.clearGlobalErrorMessage();
    }

    loadTins = (userName) => {
        BillSearchService.findTinsForUser(userName).then((response) => {
            let tins =[];
            response.data.forEach(tin =>{
                tins.push(tin.ftin);
            })
            this.setState({ tinList: tins });
           // this.setState({ tinList: ['330106028'] });

        }, (error) => {
            console.log(error);
            this.setGlobalErrorMessage('Error: There was an error retrieving data. Please try again or contact Customer Service.');
        });

    }

    setGlobalErrorMessage(message) {
        this.props.updateGlobalMessage(message, 'error');
    }

    clearGlobalErrorMessage() {
        this.props.updateGlobalMessage('', 'error');
    }

    render() {
        return (
            <div className="providerSearchInputComponent">

                <p className='App-error-font App-note-Field'>Note: Fields marked with an asterisk(*) are required.</p>


                <Grid container direction="row" spacing={1} alignItems="flex-start">
                    <Grid item xs={12} md={5}>
                        <TextField id="billControlNumber" name="billControlNumber"
                                   label="Bill Control Number"
                                   fullWidth
                                   required
                                   inputRef={this.billCtrInput}
                                   error={!isEmpty(this.state.billControlNumberError)} helperText={this.state.billControlNumberError}
                                   disabled={isNotEmpty(this.state.documentId)}
                                   value={this.state.billControlNumber}
                                   onChange={this.handleInputChange}
                                   inputProps={{ maxLength: 22}}
                        />

                    </Grid>

                    <Grid item xs={1} className="align-self-center">
                        <p className='theLabel-or'>OR</p>
                    </Grid>

                    <Grid item xs={12} md={5}>
                        <TextField id="documentId" name="documentId" label="Document ID Number"
                            error={!isEmpty(this.state.documentIdError)} helperText={this.state.documentIdError}
                            disabled={isNotEmpty(this.state.billControlNumber)}
                            value={this.state.documentId}
                            onChange={this.handleInputChange}
                            inputProps={{ maxLength: 50 }}
                            fullWidth
                        />
                    </Grid>
                </Grid>

                <span hidden={isEmpty(this.state.missingFieldError)} className="errorMessage">{this.state.missingFieldError}</span>

                <div className="buttonBar">
                    <Button disabled={!this.props.isSearchEnabled} onClick={this.handleSearchButtonOnClick} aria-label='Bill Search: Bill Information SEARCH Button'>Search</Button>
                    <Button variant="outlined" onClick={this.handleResetButtonOnClick} aria-label='Bill Search: Bill Information RESET Button'>Reset</Button>
                </div>

            </div>

        );
    }
}


export default BillInformationInputComponent;
