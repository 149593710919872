import React, {Component, Fragment} from 'react'
import AuthenticationService from "../../Services/AuthenticationService";
import './ManageTins.css'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button';
import ManageTinsTable from '../ManageTinsTable'
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import GlobalMessage from "../GlobalMessage";
import LoadingMsgBar from "../LoadingMsgBar";
import {isEmpty} from "../../utils/Utils";

window.analytics ={pageName:'Manage TINs: List View'};
class ManageTins extends Component {
    constructor(props) {
        super(props);
        this.state = {
            taxId: '', tins: [], filteredTins: [], billControl:'', provId: '', taxIdErrorMsg: '', progMsg:'',
            billControlErrorMsg: '', provIdErrorMsg:'', successMsg: '', errorMsg: '', grpId: '',
            selected:[], showDlgMsg: false, showDlgOkCancel: false, dlgMsg: '',
            isAddEnabled: true, isMirror: true
        }
    }

    setSelected = (selected) => {
        this.setState({selected: selected})

    }
    componentDidMount() {
        this.getUser()
    }

    getUser = () => {
        let tinUser = AuthenticationService.getMirrorUser();
        if(isEmpty(tinUser)) {
            tinUser = AuthenticationService.getLoggedInUserName();
            this.setState({isMirror: false});
        }
        AuthenticationService.getUsrDtls(tinUser)
            .then((response) => {
                this.setState({grpId: response.data.grpInfoId})               
                this.loadTable()
            }).catch(function (error) {           
        })
    }

    loadTable = () => {
        AuthenticationService.getFtinsForUser(this.state.grpId)
            .then((response) => {
                const tins= []

                response.data.forEach(tin =>{
                  const date = new Date(tin.createdDate)
                    tin.displayDate =  `${date.getMonth()+1}/${date.getDate()}/${date.getFullYear()}`
                    tins.push(tin)
                })
                this.setState({tins: tins, filteredTins: tins});

            }).catch(error => {
            this.setErrorMsg()
            console.log('Error receiving user ftins:' + error);
        })
    }

    validate = () => {
        let isValid = true
        if(this.validateTaxId() === false) {
            isValid = false
        }
        if(this.validateOtherFields() === false){
            isValid = false
        }

        return isValid
    }

    validateTaxId = () => {
        if (this.state.taxId.length === 0) {
            this.setState({taxIdErrorMsg: 'Error: Enter a Tax ID Number (TIN)'})
            return false
        }
        //const exp = new RegExp("^(?=.*[0-9])")
        const exp = new RegExp('^[0-9]+$');             
        if ( exp.test(this.state.taxId) !== true) {
            this.setState({taxIdErrorMsg: `Error: Enter a valid Tax ID Number (TIN)`})
            return false
        }

        if (this.state.taxId.length <9) {
            this.setState({taxIdErrorMsg: 'Error: Enter a 9-digit Tax ID Number (TIN)'})
            return false
        }

        return true
    }

    validateOtherFields = () => {
        if (this.state.billControl.length === 0 && this.state.provId.length === 0) {
            this.setState({billControlErrorMsg: `Error: Enter a Bill Control Number or Provider ID Number`})
            return false
        }

        if (this.state.billControl.length !== 0 ) {
         //   const exp = new RegExp('^[0-9]+$')
          //  if ( exp.test(this.state.billControl) !== true) {
           //     this.setState({billControlErrorMsg: `Error: Enter a valid Bill Control Number`})
           //     return false
          //  }
        } else if(this.state.provId.length !== 0){
            const exp = new RegExp("^[0-9]+$")//only numbers
            if ( exp.test(this.state.provId.trim()) === false) {
                this.setState({provIdErrorMsg: `Error: Enter a valid Provider ID Number`})
                return false
            }
        }
        return true
    }

    setfilter = (filtered) => {
        console.log( `in fitler set state`)
        if(filtered && filtered.length > 0){
            this.setState({filteredTins:filtered});
        }
        else{
            this.setState({filteredTins: []});
        }

    }

    setErrorMsg = () => {
        this.setState({progMsg:'', errorMsg: `Error:  An error has occurred.  Please try again or contact Customer Service.`})
    }

    closeMsgDlg = () => {
        this.setState({showDlgMsg:false})
    }

    postFtin = () => {
        this.setState({progMsg: `Please wait while we add the TIN.`})
        AuthenticationService.postFtin(this.state.grpId, this.state.taxId.trim(), this.state.provId.trim(), this.state.billControl.trim(),
            AuthenticationService.getLoggedInUserName())
               .then(response => {
                this.setState({isAddEnabled: true})
                if(response.status === 200) {
                    this.loadTable()
                    this.setState({taxId: '', billControl:'', provId: '',progMsg:'',errorMsg: '',
                        successMsg:`TIN ${this.state.taxId} has been successfully added.`})
                }

            }).catch((error) => {
            if(null!= error.response){
                if(null!=error.response.data){
                    if(error.response.data.message === 'TIN_EXISTS_INTGRP'){
                        this.setState({progMsg:'', errorMsg: 'Error: TIN already associated to the Provider Group. Please try again or contact Customer Service.'})
                    }else if(error.response.data.message === 'VALID_FTIN_INVALID_BCN') {
                        this.setState({progMsg:'', errorMsg: 'Error: We have verified the Tax ID Number (TIN), but the Tax ID Number (TIN) and Bill Control Number combination you have entered is not on file. Please try a different combination.'})
                    }else if(error.response.data.message === 'INVALID_FTIN_BCN'){
                        this.setState({progMsg:'', errorMsg: 'Error: The Tax ID Number (TIN) and Bill Control Number combination you have entered is not on file. Please try a different combination.'})
                    }else if(error.response.data.message === 'VALID_FTIN_INVALID_CPDID'){
                        this.setState({progMsg:'', errorMsg: 'Error: We have verified the Tax ID Number (TIN), but the Tax ID Number (TIN) and Provider ID Number combination you have entered is not on file. Please try a different combination.'})
                    }else if(error.response.data.message === 'INVALID_FTIN_CPDID'){
                        this.setState({progMsg:'', errorMsg: 'Error: The Tax ID Number (TIN) and Provider ID Number combination you have entered is not on file. Please try a different combination.'})
                    }else if(error.response.data.message === 'FTIN_RESTRICTED_FOR_REGISTRATION'){
                        this.setState({progMsg:'', errorMsg: 'Error: Adding a TIN owned by our organization is not allowed.  Select a different TIN and resubmit..'})
                    }else{
                        this.setErrorMsg()
                        this.setState({progMsg:''})
                    }
                }else{
                    this.setErrorMsg()
                    this.setState({progMsg:''})
                }
            }else{
                this.setErrorMsg()
                this.setState({progMsg:''})
            }
            this.setState({isAddEnabled: true})
        })
    }

    handleDeleteTin(tin, callLoad) {
      //  console.log(`handle delete FTin-> ${tin.ftin}`)
        AuthenticationService.deleteFtin(this.state.grpId, tin.ftin, tin.lastUpdatedUserName)
            .then(response => {
                if(response.status === 200 && callLoad === true ) {
                    
                        this.loadTable()
                        this.setState({
                            successMsg:`The selected TIN(s) have been successfully removed.`, errorMsg: ''})
                    
                }
            }).catch((error) => {
            this.setErrorMsg()
            console.log(`Delete TaxID error ${error}`);
        })
    }

    handleAdd = () => {
        this.setState({ taxIdErrorMsg: '',
            billControlErrorMsg: '', provIdErrorMsg:'', successMsg: '', errorMsg: '',isAddEnabled: false})
        console.log(`in add event taxid->${this.state.taxId}`)

        if(this.validate() === true) {
            this.postFtin()
        } else {
            this.setState({errorMsg:`An error has occurred. Please address all fields marked with “Error".`, successMsg:''})
            this.setState({isAddEnabled: true})
        }

    }

    handleReset = () => {
        this.setState({taxId: '', billControl:'', provId: '', taxIdErrorMsg: '',progMsg:'',
            billControlErrorMsg: '', provIdErrorMsg:'', successMsg: '', errorMsg: ''})
    }

    handleOkContinue  = () => {
        this.setState({showDlgOkCancel: false })
        this.remove()
    }

    handleCancel  = () => {
        this.setState({showDlgOkCancel: false })
    }

    handleRemove = () => {
        this.handleReset();
        if(this.state.selected.length === 0) {
            this.setState({showDlgMsg: true, dlgMsg:'You have not selected a TIN to remove.'})
            return;
        } else if(this.state.selected.length === this.state.tins.length) {
            this.setState({showDlgMsg: true, dlgMsg:'You cannot remove all TINs.'})
            return;
        } else  {
            this.setState({showDlgOkCancel: true})
            return;
        }

    }

    remove = () => {
        let pos = -1;
        const tins = this.state.tins;
        this.state.selected.forEach((select, index) => {
            pos = tins.findIndex(tin => tin.ftin === select)
            console.log(`index ->${index} lentgth->${this.state.selected.length} remove index->${index} from tins -${JSON.stringify(tins[pos])}`)
            this.handleDeleteTin(tins[pos], index + 1 === this.state.selected.length)
        })

        this.setState({selected: []})
    }


    render() {

        return(
            <Fragment>

                <div className='App-content Manage-tins App-font'>
                    <h1 className='App-page-label'>Manage Tax ID Numbers (TINs)</h1>

                    <LoadingMsgBar loadMsg={this.state.progMsg}/>

                    <GlobalMessage  message={this.state.errorMsg} />

                    <GlobalMessage type ='success' message={this.state.successMsg} />
                    <div style={{paddingTop:'.5rem'}}>{`Manage Tax ID Numbers (TINs) by entering the TIN and either the Bill Control Number or Provider ID Number.`}</div>

                    <div className='App-error-font App-note-Field'> Note: Fields marked with
                        an asterisk(*) are required.
                    </div>
                    <div>
                        <TextField
                            id="outlined-basic"
                            error={this.state.taxIdErrorMsg !== ''}
                            helperText={this.state.taxIdErrorMsg}
                            required
                            inputProps={{ maxLength: 9 }}
                            defaultValue=" "
                            label="Tax ID Number (TIN)"
                            margin="normal"
                            variant="outlined"
                            onChange={event => this.setState({taxId: event.target.value})}
                            value={this.state.taxId}
                        />

                    </div>
                    <div style={{fontWeight: 'bold'}}>
                        AND
                    </div>
                    <div>
                        <div className='App-error-font'
                             hidden={true}>{`Error: Enter Tax ID Number`}</div>
                        <TextField
                            id="outlined-uncontrolled"
                            label="Bill Control Number"
                            defaultValue=" "
                            disabled={this.state.provId !== ''}
                            margin="normal"
                            inputProps={{ maxLength: 22 }}
                            variant="outlined"
                            onChange={event => this.setState({billControl: event.target.value})}
                            value={this.state.billControl}
                            error={this.state.billControlErrorMsg !== ''}
                            helperText={this.state.billControlErrorMsg}
                        />
                        <div style={{ display:'inline', paddingRight:'2%', paddingLeft:'2%',verticalAlign: '-3em', fontWeight:'bold'}}>OR</div>
                        <div className='App-error-font'
                             hidden={true}>{``}</div>

                        <TextField
                            id="outlined-uncontrolled"
                            label="Provider ID Number"
                            disabled={this.state.billControl !== ''}
                            defaultValue=" "
                            inputProps={{ maxLength: 8 }}
                            margin="normal"
                            variant="outlined"
                            onChange={event => this.setState({provId: event.target.value})}
                            value={this.state.provId}
                            error={this.state.provIdErrorMsg !== ''}
                            helperText={this.state.provIdErrorMsg}
                        />
                    </div>

                    <div className='Manage-tins-btn-ln'>
                    <Button disabled={!this.state.isAddEnabled || this.state.isMirror}  onClick={this.handleAdd}>
                        ADD
                    </Button>

                    <Button  variant="outlined" onClick={this.handleReset}>
                       RESET
                    </Button>
                    </div>
                    <div className='Manage-tins-divider'/>
                    <div className='Manage-tins-tbl App-font '>
                        <ManageTinsTable rows={this.state.tins} filtered={this.state.filteredTins} setFilter={this.setfilter} setSelected={this.setSelected} isMirror={this.state.isMirror} selected={this.state.selected}/>
                    </div>
                    <div className='Manage-tins-btn-last'>
                        <Button variant="contained" color="primary" onClick={this.handleRemove} disabled={this.state.isMirror} >
                            REMOVE SELECTED TIN(S)
                        </Button>
                    </div>
                </div>
                <Dialog
                    open={this.state.showDlgMsg}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{this.state.dlgMsg}
                    </DialogTitle>

                    <div style={{textAlign:'right'}}>
                        <Button variant="outlined" autoFocus  onClick={this.closeMsgDlg} >
                            OK</Button>
                    </div>

                </Dialog>
                <Dialog
                    open={this.state.showDlgOkCancel}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    style={{ fontSize:'13px', padding:'3%'}}
                >
                    <DialogTitle>{`Are you sure you want to remove the(se) TIN(s)?`}</DialogTitle>
                    <div style={{padding:'2em'}} >
                    {'Clicking “OK” will remove access to the(se) TIN(s) for all users (Manager Users and Staff Users) currently associated with the(se) TIN(s).'}
                    </div>

                    <div style={{textAlign:'right'}}>
                        <Button variant="outlined" autoFocus  onClick={this.handleOkContinue} >
                            OK</Button>
                        <Button variant='text' onClick={this.handleCancel} >
                            Cancel</Button>
                    </div>

                </Dialog>
            </Fragment>

        );

    }
}
export default (ManageTins);
