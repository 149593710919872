// User Stories: 144473

import React, { Component } from 'react';
import { Button, Grid, TextField } from "@material-ui/core";
import { isNotEmpty, hasLength } from '../../../utils/Validator.js';
import BillSearchService from "../../../Services/BillSearchService";
import AuthenticationService from "../../../Services/AuthenticationService";
import "./BillSearch.css";


const SELECT_ONE = "Select One";
window.analytics ={pageName:'Bill Search: Claim Number'};
class ClaimNumberInputComponent extends Component {
    constructor(props) {
        super(props);

        this.state = this.initializeState();
        this.billCtrInput = React.createRef();
    }

    // save any changes the user makes into the state variable so you can
    // get back to the state the user was in
    handleInputChange = (event) => {
        let target = event.target;
        let value = target.type === 'checkbox' ? target.checked : target.value;
        let name = target.name;

        this.setState({
            [name]: value
        });
    }

    validate = () => {
        this.props.enableDisableSearch(false);
        let syntaxResults = this.doSyntaxValidation();

        if (!syntaxResults.isValid) {
            this.setGlobalErrorMessage('An error has occurred. Please address all fields marked with “Error”.');
            this.showMessages(syntaxResults.messageList);
            this.props.enableDisableSearch(true);  
        }

        return syntaxResults.isValid;
    }

    showMessages(messageList) {
        messageList.forEach(message => {
            this.addErrorMessage(message.field, message.message);
        });
    }

    addErrorMessage(fieldName, errorMessage) {
        this.setState({
            [fieldName]: errorMessage
        });
    }

    clearErrorMessage(fieldName) {
        this.setState({
            [fieldName]: ''
        });
    }

    componentDidMount() {
        this.props.searchTypeChange('CLAIM_NUMBER');
        this.loadTinsForLoggedInUser();
        this.props.enableDisableSearch(true);
        setTimeout(()=>{
            if(this.billCtrInput && this.billCtrInput.current)this.billCtrInput.current.focus();},1);
    }

    loadTinsForLoggedInUser() {
        let userName = null;

        userName = AuthenticationService.getLoggedInUserName();

        this.loadTins(userName);
    }


    initializeState() {
        const initialState = {
            processingMessage: '',
            wasSubmitted: false,

            tinList: [],
            tin: '',
            claimNumber: '',
        };

        return initialState;
    }

    getFormData() {
        let searchParams = null;
        let searchClmNum = this.state.claimNumber.trim().toUpperCase();
        searchParams = {
            isTinArray: true,
            tinArray: this.state.tinList,
            searchType: "CLAIM_NUMBER",
            searchParams: {
                ftin: '',
                claimNumber: searchClmNum,
            }
        };

        return searchParams;
    }

    getFormData_TEST() {
        let searchParams = null;

        searchParams = {
            searchType: "CLAIM_NUMBER",
            searchParams: {
                ftin: "020222150",
                claimNumber: "1376102-00002",
            }
        };

        return searchParams;
    }

    clearForm() {
        this.clearFields();
        this.clearErrorMessages();
        this.props.clearSearchResults();
    }

    clearFields() {
        this.setState({
            tin: isNotEmpty(this.state.tinList) ? SELECT_ONE : '',
            claimNumber: '',
        });
    }

    handleResetButtonOnClick = () => {
        this.clearForm();
    }

    handleSearchButtonOnClick = () => {
       // this.props.handleSearch(this.getFormData_TEST());
      //  return;
        let searchParams = null;

        this.clearErrorMessages();
        this.props.clearSearchResults();

        // Used in setting focus on first error
        this.setState({ wasSubmitted: true });

        if (this.validate()) {
            searchParams = this.getFormData();
            this.props.handleSearch(searchParams);
        }
    }

    doSyntaxValidation() {
        let messageList = [];
        let isValid = false;

      //  if (isEmpty(this.state.tin) || (this.state.tin === SELECT_ONE)) {
      //      messageList.push({ field: "tinError", message: "Error: Select Tax ID Number" });
      //  }

        if (!hasLength(this.state.claimNumber, 5, 32)) {
            messageList.push({ field: "claimNumberError", message: "Error: Enter a valid Claim Number" });
        }

        if (messageList.length === 0) {
            isValid = true;
        }

        return { isValid: isValid, messageList: messageList };
    }

    clearErrorMessages() {
        let errorFieldList = [
            'tinError',
            'claimNumberError',
        ];

        errorFieldList.forEach(errorFieldName => {
            this.clearErrorMessage(errorFieldName);
        });

        this.clearGlobalErrorMessage();
    }

    loadTins(userName) {

        //this.props.showProcessingIndicator(true);

        BillSearchService.findTinsForUser(userName).then((response) => {
         //   this.props.showProcessingIndicator(false);
           //  console.log(`tins---->${JSON.stringify(response.data)}`)
            if (isNotEmpty(response.data)) {
                const ftins = [];
                response.data.forEach( tin => {
                    ftins.push(tin.ftin);
                });
                this.setState({ tinList: ftins });
            }
        }, (error) => {
            console.log(error);
            this.props.showProcessingIndicator(false);
            this.setGlobalErrorMessage('Error: There was an error retrieving data. Please try again or contact Customer Service.');
        });
    }

    setGlobalErrorMessage(message) {
        this.props.updateGlobalMessage(message, 'error');
    }

    clearGlobalErrorMessage() {
        this.props.updateGlobalMessage('', 'error');
    }

    render() {
        return (
            <div className="claimNumberInputComponent">

                <p className='App-error-font App-note-Field'>Note: Fields marked with an asterisk(*) are required.</p>

                <Grid container direction="column" spacing={3}>


                    <Grid item xs={12} md={5}>
                        <TextField id="claimNumber" name="claimNumber" label="Claim Number"
                            error={isNotEmpty(this.state.claimNumberError)} helperText={this.state.claimNumberError}
                            value={this.state.claimNumber}
                            onChange={this.handleInputChange}
                            inputProps={{ maxLength: 35 }}
                            fullWidth
                            inputRef={this.billCtrInput}
                            required
                        />
                    </Grid>
                </Grid>

                <div className="buttonBar">
                    <Button disabled={!this.props.isSearchEnabled} onClick={this.handleSearchButtonOnClick} aria-label='Bill Search: Claim Number SEARCH Button'>Search</Button>
                    <Button variant="outlined" onClick={this.handleResetButtonOnClick} aria-label='Bill Search: Claim Number RESET Button'>Reset</Button>
                </div>
            </div>
        );
    }
}

export default ClaimNumberInputComponent;
