import React, { Component } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import { formatDate, currencyFormat } from '../../../utils/Format';
import { isNotEmpty, isEmpty } from '../../../utils/Validator.js';
import "./PaymentInfoDialog.css";

class PaymentInfoDialog extends Component {
    contentArea = (paymentInfo) => {
        if (isNotEmpty(paymentInfo)) {
            if (isEmpty(paymentInfo.paidAmount) &&
                isEmpty(paymentInfo.paidDate) &&
                isEmpty(paymentInfo.checkNumber)) {
                return (
                    <p>Payment Information is not available. Please contact Customer Service.</p>
                );
            } else {
                return (
                    <table>
                        <tbody>
                            <tr>
                                <th>Paid Amount</th>
                                <td>{currencyFormat(paymentInfo.paidAmount)}</td>
                            </tr>
                            <tr>
                                <th>Paid Date</th>
                                <td>{formatDate(paymentInfo.paidDate)}</td>
                            </tr>
                            <tr>
                                <th>Check Number</th>
                                <td>{paymentInfo.checkNumber}</td>
                            </tr>
                        </tbody>
                    </table>
                );
            }
        } else {
            return (
                <p>Payment Information is not available. Please contact Customer Service.</p>
            );
        }
    }

    render() {
        let contentComponent = this.contentArea(this.props.paymentInfo);

        return (
            <div>
                <Dialog
                    open={this.props.isOpen}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <div className="paymentInfoDialog">
                        <DialogTitle>{"Payment Information"}</DialogTitle>
                        <div className="dialogContent">
                            {contentComponent}
                        </div>

                        <DialogActions>
                            <Button variant="outlined" autoFocus onClick={(event) => this.props.handleHidePaymentInfo()} aria-label='Bill Search Results: Payment Information View Payment Info OK Button'>OK</Button>
                        </DialogActions>
                    </div>
                </Dialog>
            </div>
        );
    }
}

export default PaymentInfoDialog;
