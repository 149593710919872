import React, { Component } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import "./ConfirmationDialog.css";

/*
    <ConfirmationDialog title="Title Goes Here" message="Here is the message." 
        open={this.showConfirmation}
        yesLabel="Yes" nolLabel="Cancel"
        handleYes={this.handleYes} handleNo={this.handleNo} />
*/

class ConfirmationDialog extends Component {
    render() {
        return (
                <Dialog                
                    open={this.props.open}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <div className="confirmationDialog">
                        <DialogTitle>{this.props.title}</DialogTitle>
                        <div className="dialogContent">
                            {this.props.children}
                        </div>

                        <DialogActions>
                            <Button variant="outlined" autoFocus onClick={(event) => this.props.handleYes()}>{this.props.yesLabel}</Button>
                            <Button variant="text" onClick={(event) => this.props.handleNo()}>{this.props.noLabel}</Button>
                        </DialogActions>
                    </div>
                </Dialog>
        );
    }
}

export default ConfirmationDialog;
