import React, {Component} from 'react'
import {withStyles} from "@material-ui/core";
import AuthenticationService from "../../../Services/AuthenticationService";
import FeeScheduleService from "../../../Services/FeeScheduleService";
import { isNotEmpty} from '../../../utils/Validator.js';
import { compareIgnoreCase } from '../../../utils/Utils.js';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import {Grid} from "@material-ui/core";
import Autocomplete from "../BillSearch/Autocomplete.js";
import Button from '@material-ui/core/Button';
import GlobalMessage from "../../GlobalMessage";
import LoadingMsgBar from "../../LoadingMsgBar";
import RateCodeResultsTable from './FeeScheduleResultsTable.js';
import "./FeeSchedule.css";
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import { EventSourcePolyfill  } from 'event-source-polyfill';
import Tooltip from "@material-ui/core/Tooltip";
import InfoIcon from '@material-ui/icons/Info';


window.analytics ={pageName:'Fee Schedule'};

const styles = () => ({
    root: {
        width: '100%',
    },

});

class FeeSchedule extends Component {
    validErrorTxt = `An error has occurred. Please address all fields marked with “Error". `;
    constructor(props) {
        super(props);
        this.state = {
            isNoProv: false,validMsg: '', lobSelect: 'DPWC',
            taxIdsOptions: [], selectedTaxId: null, taxIdError:'',
            providersOptions: [], provider: '',providers: [] ,selectedProvider: null,isProviderDisabled: true,
            providerError:'', showTable : false,
            taxId: '', isSearchEnabled: true,
            shouldshowProcessingIndicator: false,processingMessage: '',
            rateCodes: [],searchResultMessage:'', filteredRateCodes: [],
            feeSchedRequests: [], dlgMessage:'', providerType:'',
            email:'CSSupport@cvty.us.com'
        }
        this.radioButtonRef = React.createRef();
    }

    setfilter = (filtered) => {
        this.setState({filteredRateCodes: filtered})
    }

    componentDidMount() {
        AuthenticationService.getUserFtins(AuthenticationService.getLoggedInUserName())
            .then((response) => {
                if(null != response.data){
                    response.data.forEach((record) => {
                        const labeloption={label:`${record.ftin}`, value:`${record.ftin}`}
                        this.state.taxIdsOptions.push(labeloption)
                    });
                }
            }).catch(function (error) {
            console.log('Error receiving user ftins:' + error);
        })
    }

    showProcessingIndicator(shouldShow) {
        this.setState({ shouldshowProcessingIndicator: shouldShow });
    }

    handleLobSelected = event => {
        this.handleResetFields()
        this.setState({lobSelect: event.target.value})
    }

    handleTaxIdSelected = (selectedTaxId) => {
        this.setState({ selectedTaxId });
        this.setState({
            validMsg: '', providers: null, providersOptions: [],
            selectedProvider: null,  showTable: false,
            isNoProv: false, provider: null, taxIdError:'', providerError:'',
            isProviderDisabled: true, processingMessage:'',searchResultMessage:'',taxId:'',
            filteredRateCodes: [], rateCodes: []
        })

        if(selectedTaxId !== null){
            const selected = selectedTaxId.value;
            this.setState({taxId: selected});
            this.setState({ processingMessage: `Please wait while we retrieve data.` , validMsg: ''})
            this.getProviders(selected);
        }

    }

    handleProviderSelected = (selectedProvider) => {
        this.setState({ selectedProvider });
        this.setState({
            showTable: false,provider: null,searchResultMessage:'',
            taxIdError:'', providerError:'', rateCodes: []
        })
        if(selectedProvider !== null){
            const selected = selectedProvider.value;
            this.setState({provider: selected});
        }
    }

    getProviders = (taxId) => {

        AuthenticationService.getProviders(taxId)
            .then((response) => {
                let isNoProv = true;
                this.setState({ providers: response.data !==null && response.data.length > 0 ?  response.data : null})
                if (isNotEmpty(response.data)) {
                    response.data.sort((a, b) => compareIgnoreCase(a.prvName, b.prvName));
                }
                response.data.forEach((provider, index) => {

                    const label = `${provider.prvName + ': ' + provider.prvId}`;
                    const val = `${index}`;
                    const labeloption = { label: label, value: val };
                    this.state.providersOptions.push(labeloption);
                    isNoProv = false;
                });
                this.setState({ processingMessage: '' ,isNoProv: isNoProv, validMsg: isNoProv === true ?  this.validErrorTxt :'', isProviderDisabled: false})
                if(this.state.isNoProv === true){
                    this.addErrorMessage("providerError", "Error: Provider not found");
                }
            }).catch((error) => {
                console.log('Error getting Providers' + error);
                this.setState({ processingMessage: '' ,isNoProv: true , isProviderDisabled: false, validMsg:'Error: An error has occurred. Please contact Customer Service.'});


        })
    }

    handleResetFields = () => {
        this.setState({
            validMsg: '', providers: null, selectedTaxId: null, providersOptions: [],
            selectedProvider: null, lobSelect: 'DPWC', showTable: false,
            isNoProv: false, provider: null, taxIdError:'', providerError:'',
            isProviderDisabled: true, processingMessage:'',searchResultMessage:'',taxId:'',
            filteredRateCodes: [], rateCodes: []
        })
        //this.radioButtonRef.current.focus();
        console.log(this.radioButtonRef.current.children);
        console.log(this.radioButtonRef.current.children[0].autofocus);
        this.radioButtonRef.current.children[0].focus();
    }

    showMessages(messageList) {
        messageList.forEach(message => {
            this.addErrorMessage(message.field, message.message);
        });
    }

    addErrorMessage(fieldName, errorMessage) {
        this.setState({
            [fieldName]: errorMessage
        });
    }

    clearErrorMessage(fieldName) {
        this.setState({
            [fieldName]: ''
        });
    }

    handleSubmit = () => {
        let valid = true
        let messageList = [];
        this.setState({
            validMsg: '',
            showTable: false, processingMessage:'', taxIdError:'', providerError:''
        })

        if (this.state.taxId === null || this.state.taxId === '') {
            messageList.push({ field: "taxIdError", message: "Error: Select Tax ID" });
            valid = false;

        }
        if (this.state.provider === null || this.state.provider === '') {
            messageList.push({ field: "providerError", message: "Error: Select Provider" });
            valid = false;
        }

        if (valid === true) {

            if(this.state.showTable){
                this.setState({isSearchEnabled: true })
            }else{
                this.setState({isSearchEnabled: false })
            }
            this.setState({ showTable: true})

            this.doRateCodesSearch();
        }else{
            this.showMessages(messageList)
            this.setState({isSearchEnabled: true })
        }
        this.setState({ validMsg: valid === true ? '' : this.validErrorTxt })
    }

    doRateCodesSearch = () => {

        this.showProcessingIndicator(true);
        this.setState({  rateCodes: [], showTable: false});
        this.setState({ processingMessage: 'Please wait while we process your request.'});
        FeeScheduleService.findRateCodesByLOB(this.state.lobSelect,
            this.state.providers[this.state.provider].prvId,
            this.state.taxId).then((response) => {
            //response is not a list, coming as 1 object
            this.setState({isSearchEnabled: true});
            var respdata = response.data;
            if(respdata.length ===0){
                this.setState({
                    showTable: false,
                    searchResultMessage: "There are no Rate Codes for the selected criteria, please revise your search criteria and try again.",
                    providerType: this.state.providers[this.state.provider].prvProviderType
                });
                
            }
            else{
                respdata.forEach(rateCode => {
                    rateCode.rateCodeIdStr = rateCode.nationalRateCd.toString();
                    rateCode.location = `${rateCode.provLctnName} ${rateCode.provLctnAddr} ${rateCode.provLctnDetails}`;
                    this.state.rateCodes.push(rateCode);
                });
                this.state.rateCodes.sort((a, b) => compareIgnoreCase(a.location, b.location));
                this.setState({filteredRateCodes: this.state.rateCodes, showTable: true});
                //var claims = this.parseClaims(this.state.claimList);
            }
            this.setState({ processingMessage: ''});
        }, (error) => {
            console.log(error);
            this.showProcessingIndicator(false);
            this.setState({
                processingMessage: '',
                validMsg:'Error: There was an error retrieving data. Please try again or contact Customer Service.'
            });
            this.setState({  rateCodes: [], isSearchEnabled: true});
        });
    }

    searchResults(){
        if(this.state.rateCodes.length > 0) {
            return (
                <div>
                    <div className='result-divider'/>
                    <RateCodeResultsTable rateCodes={this.state.rateCodes}
                                          filteredRateCodes={this.state.filteredRateCodes} setFilter={this.setfilter}
                                          handleClickAction={this.handleSelectClick} showTable={this.state.showTable}/>
                </div>
            );
        }else if (isNotEmpty(this.state.searchResultMessage)) {
            if(this.state.providerType === "H"){
                return(
                    <div>
                        <div className='result-divider'/>
                        <p className="message-no-results-found">
                        Please send your request for a copy of your contracted rates in writing on company letterhead to <a href={`mailto:${this.state.email}`}>{this.state.email}</a>. Include the Tax ID Number, address, provider name, and signature by the contract owner or manager.
                        </p>
                    </div>
                );
            }
            else{
                return(
                    <div>
                        <div className='result-divider'/>
                        <p className="message-no-results-found">{this.state.searchResultMessage}</p>
                    </div>
                );
            }
        }else{
            return;
        }

    }

    handleSelectClick = (nationalRateCd,lctnZipCd,cpdProvId,lctnName) => {
        let userName= AuthenticationService.getLoggedInUserName();
        FeeScheduleService.postuserFeeSchedRequest(userName,nationalRateCd,cpdProvId,lctnName,lctnZipCd)
            .then((response) => {
                const requestId = response.data;
                this.setState({dlgMessage:`Your request is in progress, and you will be notified once it is available.`})
                this.setState({dlgOpen: true});
                let eventSource = undefined;
                let token = this.props.token;
                eventSource = new EventSourcePolyfill(`${AuthenticationService.getApiUrl()}/feeSchedule/submitFeeScheduleAndNotify?`+
                    'userName='+userName+
                    '&feeSchedRqstId='+requestId,{
                    headers: {
                        'Authorization': 'Bearer '+token}
                });
                eventSource.onopen = (event) => {
                    // console.log(`connection is established with event--->${JSON.stringify(event)}`);
                }
                eventSource.onmessage = (event) => {

                    if(event.data && event.data === 'Request in process') {
                        // eventSource.close();
                        return;
                    }

                    let feeSchedData = JSON.parse(event.data);

                    const count = feeSchedData.successCount;
                    let feeSchedResponse = feeSchedData.feeSchedList;
                    this.props.setFeeSchedRqstCount(count);
                    feeSchedResponse.forEach(feeSched => {
                        this.state.feeSchedRequests.push(feeSched);
                    });
                    this.props.setFeeSchedRqstsFiles(feeSchedData.feeSchedList);
                    eventSource.close();
                }
                eventSource.onsseeventtimer = (event)  =>             {
                    console.log("test");
                }
                eventSource.onerror = (event) => {
                    console.error("EventSource failed:", event);
                    if(event.error === undefined) {
                        // eventSource.close();
                    }
                }
            },(error) => {
                if(error.response !== 'undefined'){
                    console.log(error.response.data);
                    this.setState({dlgMessage:`Your request could not be submitted, please retry.`})
                    this.setState({dlgOpen: true});
                }
            });
    }

    handleClose = () => {
        this.setState({dlgOpen: false});
        console.log(this.props.user)
    }

    render() {
        let searchResultComponent = this.searchResults();
        const { classes } = this.props;
        return(
            <div>

                <div><Dialog
                    open={this.state.dlgOpen}
                    onClose={this.state.dlgOpen}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{this.state.dlgMessage}
                    </DialogTitle>
                    <DialogActions>
                        <Button className={classes.button} variant="text" autoFocus onClick={this.handleClose}  >
                            OK</Button>
                    </DialogActions>
                </Dialog>
                </div>
                <div className='feeSchedule'>
                    <h1 style={{display:'inline'}} className="App-page-label">PPO Fee Schedule</h1>
                        <Tooltip arrow title="The PPO fee schedule is based on the contracted rates with the Coventry Network. This does not represent the state specific fee schedule."
                            aria-label="The PPO fee schedule is based on the contracted rates with the Coventry Network. This does not represent the state specific fee schedule.">

                        <InfoIcon tabIndex='0' color='primary' style={{verticalAlign:'-.15em', paddingTop:'0px', paddingLeft:'.2em', fontSize:'1.1em'}}></InfoIcon>
                    </Tooltip>

                    <LoadingMsgBar loadMsg={this.state.processingMessage}/>
                    <GlobalMessage  message={this.state.validMsg} />
                    <div>

                        <p className='App-error-font App-note-Field' > Note: Fields marked with
                            an asterisk(*) are required.
                        </p>
                        <FormControl component="fieldset">
                            <FormLabel required component="legend">Line of Business</FormLabel>
                            <RadioGroup aria-label="position" defaultValue='DPWC' name="position"
                                        onChange={this.handleLobSelected}  ref={this.radioButtonRef} row>
                                <FormControlLabel
                                    value='DPWC'
                                    control={<Radio color="primary" />}
                                    label="Workers' Comp"
                                    labelPlacement="end"
                                    name='DPWC'
                                />
                                <FormControlLabel
                                    name='DPAUT'
                                    value='DPAUT'
                                    control={<Radio color="primary" />}
                                    label="Auto"
                                    labelPlacement="end"

                                />
                            </RadioGroup>
                        </FormControl>
                        <Grid container direction="column" >
                            <Grid item xs={12} md={5}>
                                <Autocomplete  name="taxId" label="Tax ID" id="taxId"
                                               error={isNotEmpty(this.state.taxIdError)} helperText={this.state.taxIdError}
                                               value={this.state.selectedTaxId} options={this.state.taxIdsOptions}
                                               onChange={this.handleTaxIdSelected}
                                               fullWidth
                                               required
                                />
                            </Grid>
                            <Grid item xs={12} md={5}>
                                <Autocomplete  name="providerId" label="Provider" id="providerId"
                                               error={isNotEmpty(this.state.providerError)} helperText={this.state.providerError}
                                               isDisabled={this.state.isProviderDisabled} value={this.state.selectedProvider} options={this.state.providersOptions}
                                               onChange={this.handleProviderSelected} aria-describedby='forProv'
                                               fullWidth
                                               required
                                />
                            </Grid>
                        </Grid>
                        <div className="buttonBar">
                            <Button disabled={!this.state.isSearchEnabled} onClick={this.handleSubmit} >Search</Button>
                            <Button variant="outlined" onClick={this.handleResetFields}>Reset</Button>
                            <div></div>
                        </div>
                    </div>

                    {searchResultComponent}
                </div>
            </div>
        );

    }
}
export default withStyles(styles)(FeeSchedule);
