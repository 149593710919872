import React, {Component} from "react";
import Button from "@material-ui/core/Button";

import LoadingMsgBar from "../LoadingMsgBar";
import GlobalMessage from "../GlobalMessage";
import { Grid} from "@material-ui/core";
import ManageRecipientsSearchTbl from "../ManageRecipientsSearchTbl";
import SearchRecipients from "./SearchRecipients";
import {isNotEmpty} from "../../utils/Validator";
import AuthenticationService from "../../Services/AuthenticationService";



class ManageRecipients  extends Component {
    constructor(props) {
        super(props);
        this.state = {
            recipients: [], filteredRecipients: [], selected:[],
            sv_recipients: [], sv_filteredRecipients: [], sv_selected:[],
            filtered: false, userId: '', fieldError: '',fieldGidError:'', userName: '', email:'', tin:'',successmessage: '',groupId:'',
            progMsg: '', errorMsg:'', typeCD:'', userIDs:'',   status: '',usrCsv: this.props.usrCsv,
            searchRecipients: false

        }
    }

    componentDidMount() {

        if(isNotEmpty(this.props.usrCsv )) {
            this.search(this.state.errorMsg);

        }


    }
    search = (resetErrorMsg) => {
        this.setState({progMsg:'Loading Recipients...'})
        console.log(`((((((((((((())))))))))))searchInfo-->${JSON.stringify(this.state.usrCsv)}`)
        AuthenticationService.searchUser('', '', '', '',
            '', this.state.usrCsv, '', 'false')
            .then(response => {

                if(response.status === 200 ) {
                       this.setState({sv_recipients: response.data,sv_filteredRecipients: response.data ,
                            progMsg:'', sv_selected:[], selected:[]});
                    }

                    if(resetErrorMsg){
                        this.setState({errorMsg: response.data.length === 0 ?
                                `There are no users available for the criteria entered. Please revise your search criteria.`:''});

                    }
            }).catch((error) => {
            this.setState({errorMsg:`Error: There was an error retrieving data. Please try again or contact Customer Service.`, progMsg:''});
            console.log(`Search For User Error: ${error}`);
        })
    }


    handleRemove = () => {
        console.log(`handle re3move--------------------${this.state.sv_selected}----------------`)
        if(this.state.sv_selected && this.state.sv_selected.length > 0 &&
            this.state.sv_recipients.length === this.state.sv_selected.length) {
            this.setState({sv_recipients: [], sv_filteredRecipients: [], sv_selected:[]});
        } else if(this.state.sv_selected && this.state.sv_selected.length > 0) {
            let recipients = this.state.sv_recipients;
            let index;

            this.state.sv_selected.forEach((id) => {
               index = recipients.findIndex((usr) => id === usr.id);

               if(index > -1) {
                   recipients.splice(index, 1);
                }

            });
            this.setState({sv_recipients: recipients, sv_filteredRecipients: recipients, sv_selected:[]});
        }

    }

    saveSelected = (searchSelected, searchRecipients) => {

        let recipients = this.state.sv_recipients;
        recipients = this.addSelected(searchSelected, recipients, searchRecipients);

        this.setState({sv_recipients: recipients,sv_filteredRecipients: this.state.sv_recipients, selected: []});

    }

    addSelected = (selected, recipients , searchRecipients) => {

        let index = -1;
        selected.forEach((id) => {

            if(!this.isInArray(id)) {
                index = searchRecipients.findIndex((usr) => id === usr.id);

                if(index > -1) {
                    recipients.push(searchRecipients[index]);
                } else console.log(`ERROR LOOK NOT FINDING SELECTED IN ARRAY***${id}***`)
            }

        });

        return recipients;
    }


    isInArray = (id) => {
        const index = this.state.sv_recipients.findIndex((usr) => id === usr.id);
        return index > -1? true: false;
    }

    setSelected = ( selected) => {

        this.setState({sv_selected: selected});

    }
    onChange = event => {
        const { target: { name, value } } = event;

        this.setState({[name] :value });
    }

    setFilter = (filtered) => {

        if(filtered && filtered.length > 0)
            this.setState({filteredRecipients:filtered, filtered:true})
        else this.setState({filtered:false});
    }

    buildUrsCsv = () => {

        let usrCsv = '';
        this.state.sv_recipients.forEach( (usr) => {
           usrCsv  =`${usrCsv}${usr.id},`;
        });
        return usrCsv;
    }

    close = () => {
        this.props.setRecipients(this.buildUrsCsv());
        this.props.close();
    }

    closeSearchRecipients = () => {
        this.setState({searchRecipients: false});
    }
    renderRecipients = () => {
        this.setState({searchRecipients: true});

    }
    renderSearchRecipients = () => {
        return (<SearchRecipients close={this.closeSearchRecipients}
                  saveSelected={this.saveSelected}
                />);
    }
    render() {
        return this.state.searchRecipients ? this.renderSearchRecipients() : this.renderManageRecipients();

    }
    renderManageRecipients = () => {


        return(
            <Grid container spacing={2} justifyContent="center" alignItems="left">

                <div className='App-content Manage-users'>
                    <h1 className='App-page-label'>Manage Notification Recipients</h1>

                    <LoadingMsgBar loadMsg={this.state.progMsg}/>

                    <GlobalMessage message={this.state.errorMsg}/>

                    <GlobalMessage type='success' message={this.state.successmessage}/>
                    <div
                        style={{
                            float:'left',
                            paddingTop: '.5rem',
                            paddingBottom: '1rem'
                        }}>{`Click Search recipients to add recipients to the notification.`}</div>
                    <Grid
                        container

                        justifyContent="space-between"
                        style={{height: "100%", width: "95%"}}
                    >
                    <div className='Manage-tins-btn-ln'>
                        <Button onClick={this.renderRecipients}>
                            SEARCH RECIPIENTS
                        </Button>

                        <Button variant="outlined" onClick={this.close}>
                            CLOSE
                        </Button>
                    </div>
                    {this.state.sv_recipients.length === 0 ? '' :


                        <Grid item style={{width: "100%"}}>
                            <h2>Recipients to Receive the Notification</h2>

                        <ManageRecipientsSearchTbl users={this.state.sv_filteredRecipients}
                         setFilter={this.setFilter} unfilteredUsers={this.state.recipients}
                         actionOnSelected={this.handleRemove} add={false}
                         setSelected={this.setSelected} selected={this.state.sv_selected}/>

                        </Grid>

                    }
                    </Grid>

                    <div/>
                </div>


            </Grid>

        )
    }
}
export default (ManageRecipients);