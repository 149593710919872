// User Stories: 144473

import React, { Component } from 'react';
import { Button, Grid} from "@material-ui/core";
import { isNotEmpty, isEmpty, isDate, isFutureDate } from '../../../utils/Validator.js';
import { daysBetweenDates, parseDate, setFocusOnFirstError, compareIgnoreCase  } from '../../../utils/Utils.js';
import BillSearchService from "../../../Services/BillSearchService";
import AuthenticationService from "../../../Services/AuthenticationService";
import "./BillSearch.css";
import {getEndDtMaxPreset, getEndDtMinPreset} from "../../../utils/Utils";
import DateFnsUtils from "@date-io/date-fns";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import {Autocomplete} from "@material-ui/lab";
import TextField from "@material-ui/core/TextField";


window.analytics ={pageName:'Bill Search: Service Dates'};
class ServiceDateSearchInputComponent extends Component {
    constructor(props) {
        super(props);

        this.state = this.initializeState();
    }

    // save any changes the user makes into the state variable so you can
    // get back to the state the user was in
    handleInputChange = (event) => {
        let target = event.target;
        let value = target.type === 'checkbox' ? target.checked : target.value;
        let name = target.name;

        this.setState({
            [name]: value
        });
    }

    validate = () => {
        this.props.enableDisableSearch(false);
        let syntaxResults = this.doSyntaxValidation();

        if (!syntaxResults.isValid) {
            this.setGlobalErrorMessage('An error has occurred. Please address all fields marked with “Error”.');
            this.showMessages(syntaxResults.messageList);   
            this.props.enableDisableSearch(true);                    
        }

        return syntaxResults.isValid;
    }

    showMessages(messageList) {
        messageList.forEach(message => {
            this.addErrorMessage(message.field, message.message);
        });
    }

    addErrorMessage(fieldName, errorMessage) {
        this.setState({
            [fieldName]: errorMessage
        });
    }

    clearErrorMessage(fieldName) {
        this.setState({
            [fieldName]: ''
        });
    }

    componentDidMount() {
        this.props.searchTypeChange('SERVICE_DATE');
        
        this.loadTinsForLoggedInUser();
        this.props.enableDisableSearch(true);
    }

    loadTinsForLoggedInUser() {
        let userName = null;

        userName = AuthenticationService.getLoggedInUserName();

        this.loadTins(userName);
    }

    componentDidUpdate() {
        if (this.state.wasSubmitted) {
            setFocusOnFirstError();
            this.setState({ wasSubmitted: false });
        }
    }

    initializeState() {
        const initialState = {
            processingMessage: 'Please wait while we process your request.',
            wasSubmitted: false,       
            //tinList: [],
            //tins:[],
            ftinsOptions:[],
            ftinSelected:null,
            tin: '',
            beginServiceDate:null,
            endServiceDate: null,
        };

        return initialState;
    }

    getFormData() {
        let searchParams = null;

        let params= {
            ftin: this.state.tin.trim(),
            beginServiceDate: this.state.beginServiceDate,
            endServiceDate: this.state.endServiceDate
        }

        searchParams = {
            searchType: "SERVICE_DATE",
            searchParams: params
        };


        return searchParams;
    }

    clearForm() {
        this.clearFields();
        this.clearErrorMessages();
        this.props.clearSearchResults();
    }

    clearFields() {
        this.setState({
            beginServiceDate: null,
            endServiceDate: null, 
            tin: '',
            ftinSelected:null,
        });
    }

    handleResetButtonOnClick = () => {
        this.clearForm();
    }

    handleSearchButtonOnClick = () => {
        let searchParams = null;

        this.clearErrorMessages();
        this.props.clearSearchResults();       
        // Used in setting focus on first error
        this.setState({ wasSubmitted: true});
       if (this.validate()) {
            searchParams = this.getFormData();
            this.props.handleSearch(searchParams);             
        }
    }



    doSyntaxValidation() {
        let messageList = [];
        let isValid = false;

        if (isEmpty(this.state.ftinSelected)) {
            messageList.push({ field: "tinError", message: "Error: Select Tax ID Number (TIN)" });
        }
        if (isEmpty(this.state.beginServiceDate)) {
            messageList.push({ field: "beginServiceDateError", message: "Error: Enter Begin Service Date" });
        } else if (!isDate(this.state.beginServiceDate)) {
            messageList.push({ field: "beginServiceDateError", message: "Error: Enter a valid Begin Service Date" });
        } else if (isFutureDate(this.state.beginServiceDate)) {
            messageList.push({ field: "beginServiceDateError", message: "Error: Begin Service Date cannot be a future date" });
        }

        if (isEmpty(this.state.endServiceDate)) {
            messageList.push({ field: "endServiceDateError", message: "Error: Enter End Service Date" });
        } else if (!isDate(this.state.endServiceDate)) {
            messageList.push({ field: "endServiceDateError", message: "Error: Enter a valid End Service Date" });
        } else if (isFutureDate(this.state.endServiceDate)) {
            messageList.push({ field: "endServiceDateError", message: "Error: End Service Date cannot be a future date" });
        }

        let beginServiceDate = parseDate(this.state.beginServiceDate);
        let endServiceDate = parseDate(this.state.endServiceDate);

        if ((beginServiceDate !== null) && (endServiceDate !== null)) {
            if (endServiceDate < beginServiceDate) {
                messageList.push({ field: "endServiceDateError", message: "Error: End Service Date cannot be before Begin Service Date" });
            } else if (daysBetweenDates(beginServiceDate, endServiceDate) > 7) {
                messageList.push({ field: "endServiceDateError", message: "Error: Service Date range cannot exceed more than 7 days" });
            }
        }

        if (messageList.length === 0) {
            isValid = true;
        }

        return { isValid: isValid, messageList: messageList };
    }

    clearErrorMessages() {
        let errorFieldList = [
            'tinError',
            'beginServiceDateError',
            'endServiceDateError'
        ];

        errorFieldList.forEach(errorFieldName => {
            this.clearErrorMessage(errorFieldName);
        });

        this.clearGlobalErrorMessage();
    }

    loadTins(userName) {
        let rawTinList = [];
        //let tinList = [];
        let tins = [];
        this.props.showProcessingIndicator(true);
        BillSearchService.findTinsForUser(userName).then((response) => {
            this.props.showProcessingIndicator(false);
            rawTinList = response.data;
            if(isNotEmpty(rawTinList)){ 
                rawTinList.sort((a, b) => compareIgnoreCase(a.ftin, b.ftin));
               
                rawTinList.forEach((record) => {
                    const labeloption={label:`${record.ftin}`, value:`${record.ftin}`}
                    tins.push(labeloption.value);
                    this.state.ftinsOptions.push(labeloption)
                });

                this.setState({ tins:tins}); //tinList: tinList ,
                //this.setState({ tin: VIEW_ALL, ftinSelected :VIEW_ALL  });

            }
            else {
                this.setState({ tin: '' });
                this.setState({ tins:[]});
            }
        }, (error) => {
            console.log('Error receiving user ftins:' + error);
            this.props.showProcessingIndicator(false);
        });
    }
    
    handleTinSelected = (ftinSelected) => {
        this.setState({ ftinSelected });
        this.setState({tin: null});

        if(ftinSelected !== null){
            const selected = ftinSelected.value;
            this.setState({tin: selected});
        }
    }

    setGlobalErrorMessage(message) {
        this.props.updateGlobalMessage(message, 'error');
    }

    clearGlobalErrorMessage() {
        this.props.updateGlobalMessage('', 'error');
    }

   
    render() {
        return (
            <div className="providerSearchInputComponent">

                <p className='App-error-font App-note-Field'>Note: Fields marked with an asterisk(*) are required.</p>

                <Grid container direction="column" spacing={3}>
                    <Grid item xs={12} md={5}>                 
                    <Autocomplete id="tin"
                            value={this.state.ftinSelected} options={this.state.ftinsOptions}
                            onChange={(event, newValue) => {
                                this.handleTinSelected(newValue);
                            }}
                            getOptionLabel={(ftin) => `${ftin.label}`}                            
                            renderInput={(params) => <TextField {...params} label="Select Tax ID" id="taxId" variant="outlined" 
                            helperText={this.state.tinError} FormHelperTextProps={{
                                style: { color: '#B0170C' }
                              }}/>} 
                        />
                    </Grid>
                </Grid>

                <Grid container spacing={3}>
                    <Grid item xs={12} md={5}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>

                            <KeyboardDatePicker
                                error={isNotEmpty(this.state.beginServiceDateError)} helperText={this.state.beginServiceDateError}
                                value={this.state.beginServiceDate}
                                onChange={ date => {
                                    if(date instanceof Date) {
                                        date.setHours(8, 0, 0,0);
                                        this.setState({beginServiceDate: date});
                                    } else {
                                        this.setState({beginServiceDate: null, endServiceDate:null});
                                    }
                                }}
                                format="MM/dd/yyyy"
                                margin="normal"
                                id="beginServiceDate"
                                label="Begin Service Date"
                                fullWidth
                                disableFuture
                                placeholder='MM/DD/YYYY'
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                                required
                            />

                        </MuiPickersUtilsProvider>

                    </Grid>

                    <Grid item xs={12} md={5}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>

                            <KeyboardDatePicker
                                error={isNotEmpty(this.state.endServiceDateError)} helperText={this.state.endServiceDateError}
                                value={this.state.endServiceDate}
                                onChange={ date => {
                                    if(date instanceof Date) {
                                        date.setHours(8, 0, 0,0);
                                        this.setState({endServiceDate: date});
                                    }
                                }}
                                format="MM/dd/yyyy"
                                margin="normal"
                                id="endServiceDate"
                                label="End Service Date"
                                fullWidth
                                minDate={getEndDtMinPreset(this.state.beginServiceDate)}
                                maxDate={getEndDtMaxPreset(this.state.beginServiceDate, 8)}

                                placeholder='MM/DD/YYYY'
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                                disabled={!isNotEmpty(this.state.beginServiceDate)}
                                required
                            />

                        </MuiPickersUtilsProvider>

                    </Grid>
                </Grid>

                <div className="buttonBar">
                    <Button disabled={!this.props.isSearchEnabled} onClick={this.handleSearchButtonOnClick} aria-label='Bill Search: Service Dates SEARCH Button'>Search</Button>
                    <Button variant="outlined" onClick={this.handleResetButtonOnClick} aria-label='Bill Search: Service Dates RESET Button'>Reset</Button>
                </div>
            </div>
        );
    }
}


export default ServiceDateSearchInputComponent;
