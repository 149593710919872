import React from 'react';
import './PasswordRequirements.css';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';

export default (props) =>
    <div className='Password-req'>
    <div className='Password-req-pnl-err'>
                                    <p>Password Requirements</p>
                                    <p> 
                                    {(props.pwdLengthError === true ) ? 
                                    <CloseIcon tabIndex='0'  style={{verticalAlign:'-.15em', paddingTop:'0px', paddingLeft:'.2em', fontSize:'1.1em', fill:'red'}}></CloseIcon>
                                    : <CheckIcon tabIndex='0'  style={{verticalAlign:'-.15em', paddingTop:'0px', paddingLeft:'.2em', fontSize:'1.1em', fill:'green'}}></CheckIcon>
                                    }
                                     &nbsp;Must be 8 to 20 characters
                                    </p>
                                    <p> 
                                    {(props.pwdContainsUpperError === true ) ? 
                                    <CloseIcon tabIndex='0'  style={{verticalAlign:'-.15em', paddingTop:'0px', paddingLeft:'.2em', fontSize:'1.1em', fill:'red'}}></CloseIcon>
                                    : <CheckIcon tabIndex='0'  style={{verticalAlign:'-.15em', paddingTop:'0px', paddingLeft:'.2em', fontSize:'1.1em', fill:'green'}}></CheckIcon>
                                    }
                                     &nbsp;Must contain at least one upper case character
                                    </p>
                                    <p> 
                                    {(props.pwdContainsLowerError === true ) ? 
                                    <CloseIcon tabIndex='0'  style={{verticalAlign:'-.15em', paddingTop:'0px', paddingLeft:'.2em', fontSize:'1.1em', fill:'red'}}></CloseIcon>
                                    : <CheckIcon tabIndex='0'  style={{verticalAlign:'-.15em', paddingTop:'0px', paddingLeft:'.2em', fontSize:'1.1em', fill:'green'}}></CheckIcon>
                                    }
                                     &nbsp;Must contain at least one lower case character
                                    </p>
                                    <p> 
                                    {(props.pwdContainsNumberError === true ) ? 
                                    <CloseIcon tabIndex='0'  style={{verticalAlign:'-.15em', paddingTop:'0px', paddingLeft:'.2em', fontSize:'1.1em', fill:'red'}}></CloseIcon>
                                    : <CheckIcon tabIndex='0'  style={{verticalAlign:'-.15em', paddingTop:'0px', paddingLeft:'.2em', fontSize:'1.1em', fill:'green'}}></CheckIcon>
                                    }
                                     &nbsp;Must contain at least one numeric character
                                    </p>
                                    <p>
                                    {(props.pwdContainsSpclCharacterError === true ) ? 
                                    <CloseIcon tabIndex='0'  style={{verticalAlign:'-.15em', paddingTop:'0px', paddingLeft:'.2em', fontSize:'1.1em', fill:'red'}}></CloseIcon>
                                    : <CheckIcon tabIndex='0'  style={{verticalAlign:'-.15em', paddingTop:'0px', paddingLeft:'.2em', fontSize:'1.1em', fill:'green'}}></CheckIcon>
                                    }
                                     &nbsp;Must contain at least one special character from the following list: {`\`~!@#$%^&*()-_=+[{]}|;:',<.>/?`}
                                    </p>
                                    
                                    {(props.showUserNameCheck === true ) ? 
                                        <p>
                                        {(props.pwdContainsUserName === true ) ? 
                                            <CloseIcon tabIndex='0'  style={{verticalAlign:'-.15em', paddingTop:'0px', paddingLeft:'.2em', fontSize:'1.1em', fill:'red'}}></CloseIcon>
                                            : <CheckIcon tabIndex='0'  style={{verticalAlign:'-.15em', paddingTop:'0px', paddingLeft:'.2em', fontSize:'1.1em', fill:'green'}}></CheckIcon>
                                        }  
                                        &nbsp;Must not contain Username or First Name or Last Name spelled forward or backward
                                         </p>
                                    :
                                        <p>• Must not contain Username or First Name or Last Name spelled forward or backward</p>
                                    }
                                   

        </div>
    </div>
