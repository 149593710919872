import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import {withStyles} from '@material-ui/core/styles';
import { StylesProvider } from '@material-ui/styles';
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";


const styles = () => ({
    button: {
        margin: 0,
        padding:0,
    },


});

window.analytics ={pageName:'Contact Us'};
class ContactUsDlg extends React.Component {
    
    
    constructor(props) {
        super(props);
        this.state = {
            open: false, email:'CoventryProvider@cvty.us.com'
        };
    }

    handleClickOpen = () => {
       this.setState({open: true});
     }



    handleClose = () => {
        this.setState({ open: false });

    };

    componentWillMount() {
        
    }

    render() {
        
        return (
        
            <div style={{display:'inline-block'}} >
               
                <IconButton className='App-link' style={{fontSize:'13px'}}  onClick={this.handleClickOpen}
                   disableFocusRipple      aria-label='Opens Contact Us pop up as a modal window'>Contact Us</IconButton>

                <StylesProvider injectFirst>
                <Dialog 
                    open={this.state.open}
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle >{`For Provider Portal support`}
                    </DialogTitle>
                    <DialogContent style={{boxShadow:'0px 0px 0px 0px'}} elevation={0}>
                        <DialogContentText id="contactusDlgId" elevation={0} style={{overflow:'hidden', color:'#000',width:300, boxShadow:'0px 0px 0px 0px'}}>

                            <p style={{textAlign: 'center'}}> Call 800-937-6824<br/>
                            (Mon-Fri 08:00am to 08:00pm Eastern) </p>
                            <p style={{textAlign: 'center'}}>Or Email <a href={`mailto:${this.state.email}`}>{this.state.email}</a></p>
                        </DialogContentText>
                        <div style={{textAlign:'right'}}>
                            <Button variant="outlined" autoFocus  onClick={this.handleClose} >
                                OK</Button>
                        </div>

                    </DialogContent>




                    <div style={{height:'10px'}}></div>

                </Dialog>
                </StylesProvider>
            </div>
        );
    }
}

export default withStyles(styles)(ContactUsDlg);

