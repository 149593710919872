import axios from 'axios';
import { isNotEmpty } from '../utils/Validator.js';
import {config} from "./ConfigService";
///wcp-portal-services-1 

export const USER_NAME_SESSION_ATTRIBUTE_NAME = 'authenticatedUer'

const MIRROR_USER_NAME_SESSION_ATTRIBUTE_NAME = 'mirrorUser'

class AuthenticationService {
    axiosInterceptor = null;

    executeJwtAuthenticationService(username, password) {
        return axios.post(`${config.API_URL}/authenticate`, {
            username,
            password
        })
    }

    changeUsrPwd(username, password, newPassword) {
		return axios.post(`${config.API_URL}/public/updatePwd`, {
            username,
            password,
            newPassword
        })
    }
    // called when the uer logs in
    registerSuccessfulLoginForJwt(username, token) {        
        sessionStorage.setItem(USER_NAME_SESSION_ATTRIBUTE_NAME, username)
        this.setupAxiosInterceptors(this.createJWTToken(token))
    }
    setMirrorUser(mirrorUsrNm, mirrorNm, loggedNm) {
        sessionStorage.setItem(MIRROR_USER_NAME_SESSION_ATTRIBUTE_NAME, mirrorUsrNm);
       // axios.post(`${config.API_URL}/logger/log/User ${loggedNm} is ${mirrorNm}`, null, null);
    }

    removeMirrorUser(mirrorNm, loggedNm) {
        sessionStorage.removeItem(MIRROR_USER_NAME_SESSION_ATTRIBUTE_NAME);
        if(mirrorNm !== '') {
           // axios.post(`${config.API_URL}/logger/log/User ${loggedNm} has stopped ${mirrorNm}`, null, null);
        }
    }

    getMirrorUser() {
        let user = sessionStorage.getItem(MIRROR_USER_NAME_SESSION_ATTRIBUTE_NAME)
        if (user === null) return ''
        return user
    }

    logMessage(msg) {
        //axios.post(`${config.API_URL}/logger/log/${msg}`, null, null);
    }

    createJWTToken(token) {
        return 'Bearer ' + token;
    }

    getApiUrl() {
        return config.API_URL;
    }

    logout() {
        sessionStorage.removeItem(USER_NAME_SESSION_ATTRIBUTE_NAME);

        if (this.axiosInterceptor !== null) {
            axios.interceptors.request.eject(this.axiosInterceptor);
            this.axiosInterceptor = null;
        }
    }

    isUserLoggedIn() {
        let user = sessionStorage.getItem(USER_NAME_SESSION_ATTRIBUTE_NAME)
        if (user === null) return false
        return true
    }

    getLoggedInUserName() {
        let user = sessionStorage.getItem(USER_NAME_SESSION_ATTRIBUTE_NAME)
        if (user === null) return ''
        return user
    }

    getProvidersState(prvId,prvType) {
        return axios.get(`${config.API_URL}/clients/states/${prvType}/${prvId}/`)
    }

    getProviders(taxId) {
        return axios.get(`${config.API_URL}/clients/list/${taxId}`)
    }

    getProducts(lob, cpdProvId, cpdProvStateCd, cpdProvTin) {
        return axios.get(`${config.API_URL}/clients/getProductsByProv/${lob}/${cpdProvId}/${cpdProvStateCd}/${cpdProvTin}/`)
    }

    getClntDtls(aeId, aeNm) {
        return axios.get(`${config.API_URL}/clients/getClientDetails/${aeId}/${aeNm}/`)
    }

    getClients(lob, provId, provState, prod, provTin) {
        return axios.get(`${config.API_URL}/clients/getClientsByLOB/${lob}/${provId}/${provState}/${prod}/${provTin}/`)
    }

    getUserFtins(username) {
        let ftinUser = username;
        let mUser = this.getMirrorUser();
        if(isNotEmpty(mUser)) {
            ftinUser = mUser;
        }
        return axios.get(`${config.API_URL}/ftin/userFtins/${ftinUser}?activeFlag=true`)
    }

    getUsrDtls(username) {
        return  axios.get(`${config.API_URL}/users/userByName/${username}`)
    }
    updateUsrDtls(userDtlsInfo){
        return axios.put(`${config.API_URL}/users/updateUser`, userDtlsInfo)
     }

    getSecQuestionsForUserProfile() {
        return axios.get(`${config.API_URL}/appProperties/secQuestions`)
    }

    //manage Tin ftin/userFtins/{userName}?activeFlag=true
    getFtinsForUser(grpId) {
        return axios.get(`${config.API_URL}/ftin/groupFtins/${grpId}?activeFlag=true`)
    }

    validateFtinForUser(ftin,  bcn, cpdId) {
        if (isNotEmpty(bcn)) {
            return axios.get(`${config.API_URL}/ftin/validateFtin/${ftin}/?bcn=${bcn}`)
        } else {
            return axios.get(`${config.API_URL}/ftin/validateFtin/${ftin}/?cpdId=${cpdId}`)
        }
    }

    addUserFtin(userName, ftin,  bcn, cpdId) {
    return axios.post(`${config.API_URL}/ftin/validateandSaveFtin/${ftin}/${userName}`,null, {params :{
       cpdId,
        bcn
    }})
}
    deleteFtin(grpId, ftin, lastUpdatedUserName) {
        return axios.delete(`${config.API_URL}/ftin/groupFtins/${grpId}/${ftin}?lastUpdatedUserName=${lastUpdatedUserName}`)
    }

    postFtin(grpId, ftin, cpdId, bcn, userName) {
        console.log(`post ftin ->${ftin}`)

         return axios.post(`${config.API_URL}/ftin/groupFtins/${grpId}/${ftin}`, {
            grpId:grpId,ftin:ftin,cpdId:cpdId,billCntrlNum:bcn,createdDate:null,inactiveDate:null,lastUpdatedUserName:userName
        } )
    }
     //manage user ftins for users
    getFtinsForManageUser( userName) {
        return axios.get(`${config.API_URL}/ftin/managedUserFtins/${userName}`)
    }

    postFtinsForManageUser( userName, ftins) {
        return axios.post(`${config.API_URL}/ftin/managedUserFtins/${userName}?lastUpdatedUserName=${userName}`, ftins )
    }

    //manage users /users/manageUsers/{grpId}/{username}
    getManageUsersUsers(grpId, username) {        
        return axios.get(`${config.API_URL}/users/manageUsers/${grpId}/${username}`)
    }

    convertUserToAdmin(adminUser, userName){
        return axios.post(`${config.API_URL}/users/convertUserToAdmin/${adminUser}/${userName}`)
    }
    
    convertAdminToUser(adminUser, userName){
        return axios.post(`${config.API_URL}/users/convertAdminToUser/${adminUser}/${userName}`)
    }

    resetPassword(userName, emailAddress,adminUserName){
        
        return axios.post(`${config.API_URL}/users/resetPasswordByAdmin/start`, {
            userName:userName,
            emailAddress:emailAddress,
            adminUserName: adminUserName
        } )
    }

    terminate(userDtl) {
        return axios.put(`${config.API_URL}/users/terminate`, userDtl)
    }

    terminateUser(adminUser,userName) {
            return axios.put(`${config.API_URL}/users/terminateUser/${adminUser}/${userName}`)
    } 

    forgotUsername(emailAddress) {
        return axios.post(`${config.API_URL}/public/forgotUsername`,emailAddress)
    }
    checkEmailAddressExists(emailAddress) {
        return axios.post(`${config.API_URL}/users/checkEmailAddressExists/${emailAddress}`)
    }
    getUserAccountTypes() {
        return axios.put(`${config.API_URL}/users/getUserTypes`)
    }
    createNewUser(UserDetailsInfo){
        return axios.put(`${config.API_URL}/users/createUser`, UserDetailsInfo)
    }

    resendRegistration(adminUserNm, emailAddr){
        return axios.post(`${config.API_URL}/users/regenRegistrationByAdmin/${adminUserNm}/${emailAddr}`)
    }

    getProviderResources() {
        return axios.get(`${config.API_URL}/public/providerResources`)
    }

    getIdleTimeout() {
        return axios.get(`${config.API_URL}/appProperties/property/FE_APP_TIMEOUT`)
    }
    getNotifications = () => {
        return axios.get(`${config.API_URL}/notifications/list`);
    }
    searchUser(userName, emailAddress,tin, userTypeCD, groupId, userIDs,status, isUserNames){
        return axios.post(`${config.API_URL}/admin/userList`, {
            userTypeCD:userTypeCD,
            userName:userName,
            emailAddress:emailAddress,
            ftin: tin,
            groupId: groupId,
            userIDs: userIDs,
            status: status,
            isUserNames:isUserNames
        } )
    }

    // this is only called during login
    setupAxiosInterceptors(token) {
        // remove the interceptor which contains an old token if it is present
        if (this.axiosInterceptor !== null) {
            axios.interceptors.request.eject(this.axiosInterceptor);
            this.axiosInterceptor = null;
        }

        this.axiosInterceptor = axios.interceptors.request.use(
            (config) => {
                if ( this.isUserLoggedIn()) {
                    config.headers.authorization = token;
                    //console.log(`set authorization->${token}`)
                } else if(token === null) {
                   // console.log(`null authorization->${token}`)
                }
                return config
            }
        )
    }
}

export default new AuthenticationService()
