// User Stories: 144473

import React, { Component } from 'react';
import {  Button, Grid, TextField } from "@material-ui/core";
import GlobalMessage from '../../GlobalMessage';
import { isNotEmpty, isEmpty, isEmailAddress } from '../../../utils/Validator.js';
import { getValue, setInitialFocus, toLowerCase } from '../../../utils/Utils.js';
import ResetPasswordService from "../../../Services/ResetPasswordService";
import "./ResetPassword.css";
import LoadingMsgBar from "../../LoadingMsgBar";
window.analytics ={pageName:'Reset Password'};
class ResetPassword extends Component {
    processMsg = `Please wait while we process your request.`;
    constructor(props) {
        super(props);

        this.state = {
            globalErrorMessage: '',
            processingMessage: '',
            step: "EmailStep",
            termsAccepted: false,
            emailAddress: '',
            emailAddressError: '',
            userName: '',
            wasSubmitted: false,
            isSubmitEnabled: true
        }
    }

    handleCancelButtonOnClick = () => {
        this.navigateToLoginPage();
    }

    handleDoneButtonOnClick = () => {
        this.navigateToLoginPage();
    }

    handleSubmitButtonOnClick = () => {
        this.completeResetPasswordRequest();
    }

    // save any changes the user makes into the state variable so you can
    // get back to the state the user was in
    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        if (isEmpty(name)) {
            console.error(`${target.id} has an empty name attribute. Entered: ${value}`);
        }

        if (name === "userName") {
            this.setState({
                [name]: toLowerCase(value.trim())
            });
        } 

        else if (name === "emailAddress") {
            this.setState({
                [name]: value.trim()
            });
        } 
        
        else {
            this.setState({
                [name]: value
            });
        }
    }

    completeResetPasswordRequest() {
        this.clearErrorMessages();

        // Used in setting focus on first error
        this.setState({ wasSubmitted: true });

        if (this.validate()) {
            this.showProcessingIndicator(true);

            ResetPasswordService.submitResetPasswordRequest(this.state.userName, this.state.emailAddress).then(() => {
                this.setState({ isSubmitEnabled: true });
                this.showProcessingIndicator(false);
                this.setState({ step: "ThankYou" });
            }, (error) => {
                console.log("ERROR: " + JSON.stringify(error));
                let message = "";

                if (error.response !== undefined) {
                    message = getValue(error.response.data.message, '');
                }

                if (message.startsWith("INVALID_USERNAME_OR_EMAIL")) {
                    this.addErrorMessage("globalErrorMessage", 'Error: The Username and/or Email Address you submitted does not match our records. Please check the Username and/or Email Address and try again.');
                } else if (message.startsWith("USER_ACCT_TERMINATED")) {
                    this.addErrorMessage("globalErrorMessage", 'Error: The account has been terminated. Please contact your account administrator.');
                } else {
                    this.addErrorMessage("globalErrorMessage", "An error has occured. Please try again or contact Customer Service.");
                }

                this.showProcessingIndicator(false);
                this.setState({ isSubmitEnabled: true });
            });
        }
    }

    navigateToLoginPage() {
        this.props.history.push('/wcpp/login');
    }

    validate = () => {
        this.setState({ isSubmitEnabled: false });
        let syntaxResults = this.doSyntaxValidation();

        if (!syntaxResults.isValid) {
            this.addErrorMessage("globalErrorMessage", 'An error has occurred. Please address all fields marked with “Error”.');
            this.showMessages(syntaxResults.messageList);
            this.setState({});
            this.setState({ isSubmitEnabled: true });
        }

        return syntaxResults.isValid;
    }



    componentDidMount() {
        console.log("componentDidMount!");
        console.log(`location----->>>${this.props.location.search}`)
        setInitialFocus(".resetPassword");
    }

    doSyntaxValidation() {
        let messageList = [];

        let isValid = false;

        if (isEmpty(this.state.userName)) {
            messageList.push({ field: "userNameError", message: "Error: Enter a Username" });
        }

        if (!isEmailAddress(this.state.emailAddress)) {
            messageList.push({ field: "emailAddressError", message: "Error: Enter a valid Email Address" });
        }

        if (messageList.length === 0) {
            isValid = true;
        }

        return { isValid: isValid, messageList: messageList };
    }

    // messageList = [{field: "fieldName", message: "Error message"}, {field: "fieldName", message: "Error message"}]
    showMessages(messageList) {
        messageList.forEach(message => {
            this.addErrorMessage(message.field, message.message);
        });
    }

    clearErrorMessages() {
        let errorFieldList = [
            'userNameError',
            'emailAddressError',
            'globalErrorMessage'
        ];

        errorFieldList.forEach(errorFieldName => {
            this.clearErrorMessage(errorFieldName);
        });
    }

    addErrorMessage(fieldName, errorMessage) {
        this.setState({
            [fieldName]: errorMessage
        });
    }

    clearErrorMessage(fieldName) {
        this.setState({
            [fieldName]: ''
        });
    }

    showProcessingIndicator(shouldShow) {
        this.setState({ processingMessage: shouldShow? this.processMsg : '' });
    }

    render() {
        if (this.state.step === "EmailStep") {
            return this.renderEmailAddress();
        } else if (this.state.step === "ThankYou") {
            return this.renderThankYou();
        } else {
            return <p>OOPS!</p>
        }
    }

    renderEmailAddress() {
        return (
            <div>

                <h1 className="App-page-label">Reset Password</h1>

                <LoadingMsgBar loadMsg={this.state.processingMessage}/>

                <GlobalMessage  message={this.state.globalErrorMessage} />

                <div className='App-error-font App-error-color App-note-Field'>Note: Fields marked with an asterisk(*) are required.</div>

                <Grid container direction="column">
                    <Grid item xs={12} md={6}>
                        <TextField id="userName" name="userName" label="Username"
                            error={isNotEmpty(this.state.userNameError)} helperText={this.state.userNameError}
                            value={this.state.userName}
                            onChange={this.handleInputChange}
                            inputProps={{ maxLength: 50 }}
                            fullWidth
                            autoFocus
                            required
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <TextField id="emailAddress" name="emailAddress" label="Email Address"
                            error={isNotEmpty(this.state.emailAddressError)} helperText={this.state.emailAddressError}
                            value={this.state.emailAddress}
                            onChange={this.handleInputChange}
                            inputProps={{ maxLength: 120 }}
                            fullWidth
                            required
                        />
                    </Grid>
                </Grid>

                <div className="buttonBar">
                    <Button disabled={!this.state.isSubmitEnabled} onClick={this.handleSubmitButtonOnClick} aria-label='Reset Password: SUBMIT Button'>Submit</Button>
                    <Button variant="outlined" onClick={this.handleCancelButtonOnClick} aria-label='Reset Password: CANCEL Button'>Cancel</Button>
                </div>
            </div>
        );
    }

    renderThankYou() {
        return (
            <div className="resetPassword">
                <h1 className="pageTitle">Reset Password</h1>

                <p>An email containing your temporary password has been sent to {this.state.emailAddress}.</p>

                <div className="buttonBar">
                    <Button onClick={this.handleDoneButtonOnClick}>Done</Button>
                </div>
            </div>
        );
    }
}

export default ResetPassword;
