// User Stories: 144473

import React, {Component} from 'react';
import {withStyles} from "@material-ui/core/styles";

import GlobalMessage from '../../GlobalMessage';
import ProviderSearchInputComponent from './ProviderSearchInputComponent';
import ServiceDateSearchInputComponent from './ServiceDateSearchInputComponent';
import ClaimNumberInputComponent from './ClaimNumberInputComponent';
import ClaimantInformationInputComponent from './ClaimantInformationInputComponent';
import BillInformationInputComponent from './BillInformationInputComponent';
import BillSearchResultsTable from './BillSearchResultsTable';
import PaymentInfoDialog from './PaymentInfoDialog';
import BillSearchService from "../../../Services/BillSearchService";
import {isNotEmpty, isEmpty} from '../../../utils/Validator.js';
import {compareIgnoreCase, parseDate} from '../../../utils/Utils.js';
import {Switch} from 'react-router-dom'
import AuthenticatedRoute from '../../AuthenticatedRoute'
import "./BillSearch.css";
import LoadingMsgBar from "../../LoadingMsgBar";
import {Document, Page, PDFDownloadLink, StyleSheet, Text, Font} from "@react-pdf/renderer";
import {currencyFormat} from "../../../utils/Format";
import AuthenticationService from "../../../Services/AuthenticationService";


Font.register('https://fonts.googleapis.com/css2?family=Courier+Prime&display=swap', {family: 'Courier'});

const pdfStyles = StyleSheet.create({

    page: {

        backgroundColor: '#E4E4E4',
        width: '100%',
        whiteSpace: 'pre-wrap'
    },

    section: {
        margin: 10,
        whiteSpace: 'pre-wrap'

    },
    reportHeader: {
        width: "90%",
        fontSize: 10,
        fontFamily: 'Courier',
        whiteSpace: 'pre',
        textAlign: 'left',
        marginLeft: '10%'
    }

});

const BillImage = ({image}) => (

    <Document wrap={false}>
        {
            image.map((page, index) => {

                return (<Page object-fit="fill" size='A4' wrap={false} key={`page_${index + 1}`}>
                    {
                        page.map((line, index) => {
                            return (
                                <Text style={[pdfStyles.reportHeader]} key={`line_${index + 1}`}>{line} </Text>

                            )
                        })
                    }

                </Page>)

            })

        }

    </Document>
);


const styles = theme => ({
    root: {
        width: '100%',
    },
    button: {
        cursor: 'pointer',
        color: '#0f197d'
    },
    input: {
        display: 'none',
    },

});

class BillSearch extends Component {
    constructor(props) {
        super(props);
        this.pdfLink = React.createRef();
        this.state = {
            pageTitle: 'Bill Search',
            globalMessage: '',
            globalMessageType: '',
            processingMessage: '',
            searchResultMessage: '',
            billList: [],
            billsFiltered: [],
            isSearchEnabled: true,
            shouldShowPaymentInfo: false,
            paymentInfo: null,
            pdfReport: null,
            showLink: false,
            respCnt: 0
        };
        this.ie = false;

    }

    setfilter = (filtered) => {
        console.log(`in fitler set state`)
        this.setState({billsFiltered: filtered})

    }

    showMessages(messageList) {
        messageList.forEach(message => {
            this.addErrorMessage(message.field, message.message);
        });
    }

    addErrorMessage(fieldName, errorMessage) {
        this.setState({
            [fieldName]: errorMessage
        });
    }

    clearErrorMessage(fieldName) {
        this.setState({
            [fieldName]: ''
        });
    }

    showProcessingIndicator = (shouldShow) => {
        this.setState({processingMessage: shouldShow === true ? `Please wait while we process your request.` : ''});
        if (shouldShow === true) {
            this.updateGlobalMessage('');
        }
    }

    enableDisableSearch = (enabled) => {
        this.setState({
            isSearchEnabled: enabled
        });
    }


    handleHidePaymentInfo = () => {
        this.setState({
            shouldShowPaymentInfo: false,
            paymentInfo: null
        });
    }

    clearForm() {
        this.clearErrorMessages();
    }

    handleBillSearch = (searchParams) => {

        this.clearSearchResults();
        this.showProcessingIndicator(true);
        this.enableDisableSearch(false);
        if (searchParams.isTinArray) {
            this.setState({respCnt: searchParams.tinArray.length + 1});

            searchParams.tinArray.forEach(tin => {

                searchParams.searchParams = {...searchParams.searchParams, ftin: tin};

                this.doBillSearch(searchParams);
            });

            let searchParamsEx = {...searchParams.searchParams};
            searchParamsEx.tins = searchParams.tinArray;
            this.doMitchBillSearch(searchParamsEx);
        } else {
            let searchParamsEx = {...searchParams.searchParams};
            this.setState({respCnt: 2})
            this.doBillSearch(searchParams);

            this.doMitchBillSearch(searchParamsEx);
        }

    }


    getBillPdf = () => {

        if (this.state.showLink) {
            return (
                <div style={{display: 'none', float: 'right'}}>

                    <PDFDownloadLink document={<BillImage image={this.state.pdfReport}/>} fileName={'PricingSheet_' + this.getImageFileName()}>
                        {({blob, url, loading, event}) => {

                            if (window.navigator && window.navigator.msSaveOrOpenBlob && !/Edge/.test(window.navigator.userAgent)) {
                                this.ie = true;

                                window.navigator.msSaveOrOpenBlob(blob, 'PricingSheet_' + this.getImageFileName());
                            } else if(/Edge/.test(window.navigator.userAgent)) {
                                return (loading ? '' : <div ref={this.pdfLink}></div>);
                            } else {
                                return (loading ? '' : <div ref={this.pdfLink}></div>);
                            }


                        }}
                    </PDFDownloadLink>
                </div>
            )
        }
    }

    getImageFileName = () => {
        let date = new Date();
        return `${AuthenticationService.getLoggedInUserName()}_${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}_${date.getHours()}-${date.getMinutes()}-${date.getSeconds()}-${date.getMilliseconds()}.pdf`;
    }

    linkClicked = () => {

        if (this.ie === true) {
            setTimeout(() => this.setState({showLink: false}), 0);

        } else if (this.pdfLink && this.pdfLink.current) {
            this.pdfLink.current.click();
            if (this.state.showLink) {
                this.setState({showLink: false});
            }
        }
    }

    handleSearchBillImage = (dcn) => {
        this.showProcessingIndicator(true);
        BillSearchService.searchBillImage(dcn)

            .then((response) => {

                this.showProcessingIndicator(false);
                this.showProcessingIndicator(false);
                const blob = new Blob([response.data], {type: 'application/pdf'});

                if (response.statusText === 'OK' && window.navigator && window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(blob, this.getImageFileName());
                } else if (response.statusText === 'OK') {
                    const url = window.URL.createObjectURL(blob);
                    window.open(url, 'billImage');
                }

            }, (error) => {
                console.log('File download Link Error:' + error);
                this.updateGlobalMessage('Error: The image selected is not available.');
                this.showProcessingIndicator(false);
            });

    }

    handleSearchBillRef = (event, tin, bcn) => {
        this.showProcessingIndicator(true);
        this.updateGlobalMessage('');

        BillSearchService.searchBillRefNubmer(tin, bcn).then((response) => {
            this.showProcessingIndicator(false);
            let rep = [];
            let lines = [];
            let line = '';

            response.data.forEach((Line, index) => {
                if (Line.line.includes('PAGE ')) {
                    rep.push(lines);
                    lines = ['\r\n', '\r\n', '\r\n'];
                }
                if (Line.line.startsWith(' ')) {
                    Line.line = '\u3000' + Line.line;
                }

                line = Line.line.replace(/ /g, '\u0020');
                lines.push(line.replace(/\n/g, '\u0020'));
            });

            rep.push(lines);

            this.setState({showLink: true, pdfReport: rep});

        }, (error) => {
            console.log(error);
            this.showProcessingIndicator(false);
            this.updateGlobalMessage('Error: There was an error retrieving data. Please try again or contact Customer Service.');
        });

    }

    handleViewPaymentInfo = (event, tin, bcn) => {
        event.preventDefault();

        this.viewPaymentInfo(tin, bcn);
    }
    handleMitchPaymentInfo = (paidAmount, paidDate, checkNumber) =>{

        let paymentInfo = {
            paidAmount:paidAmount,
            paidDate: paidDate,
            checkNumber: checkNumber
        }
        this.setState({
            paymentInfo: paymentInfo,
            shouldShowPaymentInfo: true
        });
    }
    viewPaymentInfo(tin, bcn) {
        let paymentInfo = null;

        this.showProcessingIndicator(true);
        this.updateGlobalMessage('');

        if (isEmpty(bcn)) {
            this.setState({
                paymentInfo: paymentInfo,
                shouldShowPaymentInfo: true
            });

            this.showProcessingIndicator(false);
        } else {
            BillSearchService.findPaymentDetails(tin, bcn).then((response) => {
                this.showProcessingIndicator(false);

                paymentInfo = response.data;

                this.setState({
                    paymentInfo: paymentInfo,
                    shouldShowPaymentInfo: true
                });
            }, (error) => {
                console.log(error);
                this.showProcessingIndicator(false);
                this.updateGlobalMessage('Error: There was an error retrieving data. Please try again or contact Customer Service.');
            });
        }
    }

    // called when a search type is selected
    searchTypeChange = (searchType) => {
        let title = "";

        // change these to use a switch statement
        if (searchType === "PROVIDER") {
            title = "Provider Information";
        } else if (searchType === "SERVICE_DATE") {
            title = "Service Dates";
        } else if (searchType === "CLAIM_NUMBER") {
            title = "Claim Number";
        } else if (searchType === "CLAIMANT") {
            title = "Claimant Information";
        } else if (searchType === "BILL_INFORMATION") {
            title = "Bill Information";
        } else {
            console.error("Unknown searchType: " + searchType);
        }

        this.setState({
            pageTitle: 'Bill Search: ' + title,
            billList: [],
            billsFiltered: [],
            globalMessage: '',
            searchResultMessage: '',
            lastTin: ''
        });
    }

    updateGlobalMessage = (message, messageType = 'error') => {
        this.setState({
            globalMessage: message,
            globalMessageType: messageType
        });
    }


    clearSearchResults = () => {
        this.setState({
            searchResultMessage: '',
            billList: [],
            billsFiltered: []
        });
    }

    doBillSearch = (searchParams) => {

        BillSearchService.findBills(searchParams).then((response) => {
           this.billSearchResponse(response);
        }, (error) => {
            console.log(error);
            if (this.state.respCnt <= 1) {
                this.showProcessingIndicator(false,);
                this.updateGlobalMessage('Error: There was an error retrieving data. Please try again or contact Customer Service.');
            }

            this.setState({
                respCnt: this.state.respCnt - 1, isSearchEnabled: true
            });

        });
    }

    doMitchBillSearch = (searchParams) => {

        BillSearchService.findMitchBills(searchParams).then((response) => {

           this.billSearchResponse(response);
        }, (error) => {
            console.log(error);
            if (this.state.respCnt <= 1) {
                this.showProcessingIndicator(false,);
                this.updateGlobalMessage('Error: There was an error retrieving data. Please try again or contact Customer Service.');
            }

            this.setState({
                respCnt: this.state.respCnt - 1, isSearchEnabled: true
            });

        });
    }

    billSearchResponse = (response) => {
        let respCnt = this.state.respCnt;
        let rawBillList = response.data;

        if (respCnt === 1) {
            this.showProcessingIndicator(false);
            this.updateGlobalMessage('');
        }

        if (isNotEmpty(rawBillList)) {
            rawBillList.forEach(bill => {

                bill.beginServiceDate = parseDate(bill.beginServiceDate);
                bill.beginServiceDateStr = bill.beginServiceDate.toLocaleString();
                bill.endServiceDate = parseDate(bill.endServiceDate);
                bill.endServiceDateStr = bill.endServiceDate.toLocaleString();
                bill.physcianName = `${bill.physicianFirstName} ${bill.physicianMiddleName}${bill.physicianMiddleName ? ' ' : ''} ${bill.physicianLastName} `;
                bill.billChargesStr = currencyFormat(bill.billCharges).toString();
                bill.recommendedAllowanceStr = currencyFormat(bill.recommendedAllowance).toString();
                bill.claimantName = `${bill.patientFirstName} ${bill.patientMiddleName}${bill.patientMiddleName ? ' ' : ''}${bill.patientLastName}`;
                bill.bcnDcn = `${bill.billControlNumber? bill.billControlNumber: ''} / ${bill.documentId ? bill.documentId: ''}`;
                this.state.billList.push(bill);
            });

            this.state.billList.sort((a, b) => compareIgnoreCase(a.claimantName, b.claimantName));

            this.setState({
                billList: this.state.billList,
                billsFiltered: this.state.billList,
                respCnt: --respCnt,
                isSearchEnabled: respCnt === 0
            });

        } else {
            if (respCnt <= 1) {

                this.setState({
                    isSearchEnabled: true, respCnt: 0,
                    searchResultMessage: "There are no Bills available for the criteria entered, please revise your search criteria and try again."
                });
            } else {
                this.setState({respCnt: --respCnt});
            }

        }
    }


    searchResults = () => {
        if (isNotEmpty(this.state.billList)) {
            return (
                <div>
                    <div className='result-divider'/>
                    <BillSearchResultsTable billList={this.state.billList}
                                            billsFiltered={this.state.billsFiltered}
                                            setFilter={this.setfilter}
                                            isSearchEnabled={this.state.isSearchEnabled}
                                            handleViewPaymentInfo={this.handleViewPaymentInfo}
                                            handleMitchPaymentInfo={this.handleMitchPaymentInfo}
                                            handleSearchBillRef={this.handleSearchBillRef}
                                            handleSearchBillImage={this.handleSearchBillImage}
                                            showProgressMsg={this.showProcessingIndicator}
                                            updateGlobalMessage={this.updateGlobalMessage}
                    />
                </div>
            );
        } else if (isNotEmpty(this.state.searchResultMessage)) {
            return (
                <div>
                    <div className='result-divider'/>
                    <p className="message-no-results-found">{this.state.searchResultMessage}</p>
                </div>
            );
        } else {
            return null;
        }
    }

    render() {
        let searchResultComponent = this.searchResults();

        return (
            <div className='billSearch'>
                {this.state.showLink ? this.getBillPdf() : ''}
                {this.state.showLink ? setTimeout(() => this.linkClicked(), 500) : ''}
                <h1 className="App-page-label">{this.state.pageTitle}</h1>

                <LoadingMsgBar loadMsg={this.state.processingMessage}/>
                <GlobalMessage message={this.state.globalMessage} type={this.state.globalMessageType}/>

                <Switch>
                    <AuthenticatedRoute exact path='/wcpp/billSearch/provider'
                                        render={(props) => <ProviderSearchInputComponent {...props}
                                                                                         searchTypeChange={this.searchTypeChange}
                                                                                         handleSearch={this.handleBillSearch}
                                                                                         clearSearchResults={this.clearSearchResults}
                                                                                         showProcessingIndicator={this.showProcessingIndicator}
                                                                                         updateGlobalMessage={this.updateGlobalMessage}
                                                                                         isSearchEnabled={this.state.isSearchEnabled}
                                                                                         enableDisableSearch={this.enableDisableSearch}/>}
                    />

                    <AuthenticatedRoute exact path='/wcpp/billSearch/serviceDate'
                                        render={(props) => <ServiceDateSearchInputComponent {...props}
                                                                                            searchTypeChange={this.searchTypeChange}
                                                                                            handleSearch={this.handleBillSearch}
                                                                                            clearSearchResults={this.clearSearchResults}
                                                                                            showProcessingIndicator={this.showProcessingIndicator}
                                                                                            updateGlobalMessage={this.updateGlobalMessage}
                                                                                            isSearchEnabled={this.state.isSearchEnabled}
                                                                                            enableDisableSearch={this.enableDisableSearch}/>}
                    />

                    <AuthenticatedRoute exact path='/wcpp/billSearch/claimNumber'
                                        render={(props) => <ClaimNumberInputComponent {...props}
                                                                                      searchTypeChange={this.searchTypeChange}
                                                                                      handleSearch={this.handleBillSearch}
                                                                                      clearSearchResults={this.clearSearchResults}
                                                                                      showProcessingIndicator={this.showProcessingIndicator}
                                                                                      updateGlobalMessage={this.updateGlobalMessage}
                                                                                      isSearchEnabled={this.state.isSearchEnabled}
                                                                                      enableDisableSearch={this.enableDisableSearch}/>}
                    />

                    <AuthenticatedRoute exact path='/wcpp/billSearch/claimantInformation'
                                        render={(props) => <ClaimantInformationInputComponent {...props}
                                                                                              searchTypeChange={this.searchTypeChange}
                                                                                              handleSearch={this.handleBillSearch}
                                                                                              clearSearchResults={this.clearSearchResults}
                                                                                              showProcessingIndicator={this.showProcessingIndicator}
                                                                                              updateGlobalMessage={this.updateGlobalMessage}
                                                                                              isSearchEnabled={this.state.isSearchEnabled}
                                                                                              enableDisableSearch={this.enableDisableSearch}/>}
                    />

                    <AuthenticatedRoute exact path='/wcpp/billSearch/billInformation'
                                        render={(props) => <BillInformationInputComponent {...props}
                                                                                          searchTypeChange={this.searchTypeChange}
                                                                                          handleSearch={this.handleBillSearch}
                                                                                          clearSearchResults={this.clearSearchResults}
                                                                                          showProcessingIndicator={this.showProcessingIndicator}
                                                                                          updateGlobalMessage={this.updateGlobalMessage}
                                                                                          isSearchEnabled={this.state.isSearchEnabled}
                                                                                          enableDisableSearch={this.enableDisableSearch}/>}
                    />

                </Switch>

                {searchResultComponent}

                {this.state.shouldShowPaymentInfo &&
                <PaymentInfoDialog isOpen={this.state.shouldShowPaymentInfo}
                                   handleHidePaymentInfo={this.handleHidePaymentInfo}
                                   paymentInfo={this.state.paymentInfo}/>
                }

            </div>
        );
    }
}

export default withStyles(styles)(BillSearch);
