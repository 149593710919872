import React, {Fragment} from 'react';


export default () => {
    return (
        <Fragment>
            <div className='App-content'>
                <h1 className='App-page-label'>Page Not Found</h1>
                <br/>
                <p style={{fontSize: '13px'}}>We're sorry, but we could not locate that page. For assistance, please
                    contact Coventry Provider Portal support at 800-937-6824 or&nbsp;
                    <a href='mailto:CoventryProvider@cvty.us.com' className='App-link'
                       aria-label='Opens Email Coventry Provider Support'>
                        CoventryProvider@cvty.us.com
                    </a>.</p>
            </div>
        </Fragment>
    );
}
