import React, { Component } from 'react';
import { Button, Grid, TextField} from "@material-ui/core";
import { isNotEmpty, isEmpty, isDate, isFutureDate } from '../../../utils/Validator.js';
import { daysBetweenDates, parseDate } from '../../../utils/Utils.js';
import BillSearchService from "../../../Services/BillSearchService";
import AuthenticationService from "../../../Services/AuthenticationService";
import "./BillSearch.css";
import { getEndDtMaxPreset, getEndDtMinPreset} from "../../../utils/Utils";
import DateFnsUtils from "@date-io/date-fns";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";

window.analytics ={pageName:'Bill Search: Claimant Information'};
class ClaimantInformationInputComponent extends Component {
    constructor(props) {
        super(props);

        this.state = this.initializeState();
        this.billCtrInput = React.createRef();
    }

    initializeState() {
        const initialState = {
            processingMessage: 'Please wait while we process your request.',
            wasSubmitted: false,
            tinList: [],

            claimantFirstName: '',
            claimantLastName: '',
            beginServiceDate: null,
            claimantDateOfBirth: null,
            endServiceDate: null
        };

        return initialState;
    }
    handleResetButtonOnClick = () => {
        this.clearForm();
    }

    clearForm() {
        this.clearFields();
        this.clearErrorMessages();
        this.props.clearSearchResults();
    }

    clearFields() {
        this.setState({
            claimantFirstName: '',
            claimantLastName: '',
            claimantDateOfBirth: null,
            beginServiceDate: null,
            endServiceDate: null
        });
    }

    validate = () => {
        this.props.enableDisableSearch(false);
        let syntaxResults = this.doSyntaxValidation();

        if (!syntaxResults.isValid) {
            this.setGlobalErrorMessage('An error has occurred. Please address all fields marked with “Error”.');
            this.showMessages(syntaxResults.messageList);
            this.props.enableDisableSearch(true);  
        }

        return syntaxResults.isValid;
    }

    setGlobalErrorMessage(message) {
        this.props.updateGlobalMessage(message, 'error');
    }

    clearGlobalErrorMessage() {
        this.props.updateGlobalMessage('', 'error');
    }

    showMessages(messageList) {
        messageList.forEach(message => {
            this.addErrorMessage(message.field, message.message);
        });
    }

    addErrorMessage(fieldName, errorMessage) {
        this.setState({
            [fieldName]: errorMessage
        });
    }

    clearErrorMessage(fieldName) {
        this.setState({
            [fieldName]: ''
        });
    }

    doSyntaxValidation() {
        let messageList = [];
        let isValid = false;


        if (isEmpty(this.state.claimantFirstName)) {
            messageList.push({ field: "claimantFirstNameError", message: "Error: Enter Claimant First Name" });
        } else if(this.isLessWithCard(this.state.claimantFirstName)) {
            messageList.push({ field: "claimantFirstNameError", message: "Error: Enter at least 3 characters of Claimant First Name"});
        }

        if (isEmpty(this.state.claimantLastName)) {
            messageList.push({ field: "claimantLastNameError", message: "Error: Enter Claimant Last Name" });
        } else if(this.isLessWithCard(this.state.claimantLastName)) {
            messageList.push({ field: "claimantLastNameError", message: "Error: Enter at least 3 characters of Claimant Last Name"});
        }

        if (isEmpty(this.state.claimantDateOfBirth)) {
            messageList.push({ field: "claimantDateOfBirthError", message: "Error: Enter Claimant Date of Birth" });
        } else if (!isDate(this.state.claimantDateOfBirth)) {
            messageList.push({ field: "claimantDateOfBirthError", message: "Error: Enter a valid Claimant Date of Birth" });
        } else if (isFutureDate(this.state.claimantDateOfBirth)) {
            messageList.push({ field: "claimantDateOfBirthError", message: "Error: Claimant Date of Birth cannot be a future date" });
        }

        if (isEmpty(this.state.beginServiceDate)) {
            messageList.push({ field: "beginServiceDateError", message: "Error: Enter Begin Service Date" });
        } else if (!isDate(this.state.beginServiceDate)) {
            messageList.push({ field: "beginServiceDateError", message: "Error: Enter a valid Begin Service Date" });
        } else if (isFutureDate(this.state.beginServiceDate)) {
            messageList.push({ field: "beginServiceDateError", message: "Error: Begin Service Date cannot be a future date" });
        }

        if (isEmpty(this.state.endServiceDate)) {
            messageList.push({ field: "endServiceDateError", message: "Error: Enter End Service Date" });
        } else if (!isDate(this.state.endServiceDate)) {
            messageList.push({ field: "endServiceDateError", message: "Error: Enter a valid End Service Date" });
        } else if (isFutureDate(this.state.endServiceDate)) {
            messageList.push({ field: "endServiceDateError", message: "Error: End Service Date cannot be a future date" });
        }

        let beginServiceDate = parseDate(this.state.beginServiceDate);
        let endServiceDate = parseDate(this.state.endServiceDate);

        if ((beginServiceDate !== null) && (endServiceDate !== null)) {
            if (endServiceDate < beginServiceDate) {
                messageList.push({ field: "endServiceDateError", message: "Error: End Service Date cannot be before Begin Service Date" });
            } else if (daysBetweenDates(beginServiceDate, endServiceDate) > 30) {
                messageList.push({ field: "endServiceDateError", message: "Error: Service Date range cannot exceed more than 30 days" });
            }
        }

        if (messageList.length === 0) {
            isValid = true;
        }

        return { isValid: isValid, messageList: messageList };
    }
    isLessWithCard = ( name) => {
        if (name) {
            const len = name.length;

            if (len <= 3 ) {
                if(name[len - 1] === '*') {
                    return true;
                } else if( len < 3) {
                    return true;
                }
            }
        }
        return false;
    }
    clearErrorMessages() {
        let errorFieldList = [
            'claimantFirstNameError',
            'claimantLastNameError',
            'claimantDateOfBirthError',
            'beginServiceDateError',
            'endServiceDateError'
        ];

        errorFieldList.forEach(errorFieldName => {
            this.clearErrorMessage(errorFieldName);
        });

        this.clearGlobalErrorMessage();
    }

    componentDidMount() {
        this.props.searchTypeChange('CLAIMANT');
        this.loadTinsForLoggedInUser();
        this.props.enableDisableSearch(true);
        setTimeout(()=>{
            if(this.billCtrInput && this.billCtrInput.current)this.billCtrInput.current.focus();},1);
    }

    loadTinsForLoggedInUser() {
        let userName = null;

        userName = AuthenticationService.getLoggedInUserName();

        this.loadTins(userName);
    }

    loadTins = (userName) => {
        BillSearchService.findTinsForUser(userName).then((response) => {
            let tins =[];
            response.data.forEach(tin =>{
                tins.push(tin.ftin);
            })
            this.setState({ tinList: tins });

        }, (error) => {
            console.log(error);
            this.setGlobalErrorMessage('Error: There was an error retrieving data. Please try again or contact Customer Service.');
        });

    }

    handleTinSelected = (event) => {
        let tin = event.target.value;

        this.setState({ tin: tin });
    }

    getFormData() {
        let searchParams = null;

        searchParams = {
            searchType: "CLAIMANT",
            isTinArray: true,
            tinArray: this.state.tinList,
            searchParams: {
                claimantFirstName: this.state.claimantFirstName.trim(),
                claimantLastName: this.state.claimantLastName.trim(),
                claimantDateOfBirth: this.state.claimantDateOfBirth,
                beginServiceDate: this.state.beginServiceDate,
                endServiceDate: this.state.endServiceDate
            }
        }
        return searchParams;

    }

    handleSearchButtonOnClick = () => {
        let searchParams = null;
        this.clearErrorMessages();
        this.props.clearSearchResults();

        // Used in setting focus on first error
        this.setState({ wasSubmitted: true });

        if (this.validate()) {
            searchParams = this.getFormData();
            this.props.handleSearch(searchParams);
        }
    }


    handleInputChange = (event) => {
        let target = event.target;
        let value = target.type === 'checkbox' ? target.checked : target.value;
        let name = target.name;

        this.setState({
            [name]: value
        });
    }

    render() {
        return (
            <div>

                <p className='App-error-font App-note-Field'>Note: Fields marked with an asterisk(*) are required.</p>
                <Grid container direction="column" spacing={3}>

                    <Grid item xs={12} md={5}>

                        <TextField id="claimantFirstName" name="claimantFirstName" label="Claimant First Name" required
                            error={isNotEmpty(this.state.claimantFirstNameError)} helperText={this.state.claimantFirstNameError}
                            value={this.state.claimantFirstName}
                            onChange={this.handleInputChange}
                            inputProps={{ maxLength: 40}}
                            fullWidth
                                   inputRef={this.billCtrInput}
                        />

                    </Grid>

                    <Grid item xs={12} md={5}>

                        <TextField id="claimantLastName" name="claimantLastName" label="Claimant Last Name" required
                            error={isNotEmpty(this.state.claimantLastNameError)} helperText={this.state.claimantLastNameError}
                            value={this.state.claimantLastName}
                            onChange={this.handleInputChange}
                            inputProps={{ maxLength: 40 }}
                            fullWidth
                        />

                    </Grid>
                    <Grid item xs={12} md={5}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>

                            <KeyboardDatePicker
                                error={isNotEmpty(this.state.claimantDateOfBirthError)} helperText={this.state.claimantDateOfBirthError}
                                value={this.state.claimantDateOfBirth}
                                onChange={ date => {
                                    if(date instanceof Date) {
                                        date.setHours(8, 0, 0,0);
                                        this.setState({claimantDateOfBirth: date});
                                    }
                                }}

                                format="MM/dd/yyyy"
                                margin="normal"
                                id="claimantDateOfBirth"
                                label="Claimant Date of Birth"
                                fullWidth
                                disableFuture
                                placeholder='MM/DD/YYYY'
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                                required
                            />

                        </MuiPickersUtilsProvider>

                    </Grid>
                </Grid>

                <Grid container spacing={3}>
                    <Grid item xs={12} md={5}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>

                            <KeyboardDatePicker
                                error={isNotEmpty(this.state.beginServiceDateError)} helperText={this.state.beginServiceDateError}
                                value={this.state.beginServiceDate}
                                onChange={date => {
                                    if(date instanceof Date) {
                                        date.setHours(8, 0, 0,0);
                                        this.setState({beginServiceDate: date});
                                    } else {
                                        this.setState({beginServiceDate: null, endServiceDate:null});
                                    }
                                }}

                                format="MM/dd/yyyy"
                                margin="normal"
                                id="beginServiceDate"
                                label="Begin Service Date"
                                fullWidth
                                disableFuture
                                placeholder='MM/DD/YYYY'
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                                required
                            />

                        </MuiPickersUtilsProvider>

                    </Grid>

                    <Grid item xs={12} md={5}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>

                            <KeyboardDatePicker
                                error={isNotEmpty(this.state.endServiceDateError)} helperText={this.state.endServiceDateError}
                                value={this.state.endServiceDate}
                                onChange={date => {
                                    if(date instanceof Date) {
                                        date.setHours(8, 0, 0,0);
                                        this.setState({endServiceDate: date});
                                    }
                                }}

                                format="MM/dd/yyyy"
                                margin="normal"
                                id="endServiceDate"
                                label="End Service Date"
                                fullWidth
                                minDate={getEndDtMinPreset(this.state.beginServiceDate)}
                                maxDate={getEndDtMaxPreset(this.state.beginServiceDate, 31)}

                                placeholder='MM/DD/YYYY'
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                                disabled={!isNotEmpty(this.state.beginServiceDate)}
                                required
                            />

                        </MuiPickersUtilsProvider>

                    </Grid>
                </Grid>
                <div className="buttonBar">
                    <Button disabled={!this.props.isSearchEnabled} onClick={this.handleSearchButtonOnClick} aria-label='Bill Search: Claimant Information SEARCH Button'>Search</Button>
                    <Button variant="outlined" onClick={this.handleResetButtonOnClick} aria-label='Bill Search: Claimant Information RESET Button'>Reset</Button>
                </div>
            </div>
        );
    }
}

export default ClaimantInformationInputComponent;
