import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles, useTheme} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import LastPageIcon from '@material-ui/icons/LastPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import { isNotEmpty, isDate} from '../../../utils/Validator';
import { parseDate } from '../../../utils/Utils';
import Button from '@material-ui/core/Button';
import TextField from "@material-ui/core/TextField";
import Paper from '@material-ui/core/Paper';
import { isNumber } from 'util';
import "../../../table.css";
import AuthenticationService from "../../../Services/AuthenticationService";


const headCells = [
    
    {id: 'nationalRateCd', fid:'rateCodeIdStr', numeric: true, disablePadding: true, label: 'Rate Code', width: '6rem',filterLable:'Code',sortable: true},
    {id: 'location', fid:'location',numeric: false, disablePadding: true, label: 'Practice Location', width: '6rem',filterLable:'Practice Location',sortable: true},
    {id: 'action', fid:'action', numeric: false, disablePadding: true, label: 'Action', filterLable:'action', width: '6rem',sortable: false},    
];

function desc(a, b, orderBy) {
    let c = a[orderBy];
    let d = b[orderBy];

    console.log(`typeof a[${orderBy}]: " ${typeof a[orderBy]}`);
    console.log(c);
    console.log(`typeof b[${orderBy}]: " ${typeof b[orderBy]}`);
    console.log(d);

    console.log("instanceof date == " + (c instanceof Date));

    // !!! will have to try date first because a date comes back as a string
    if (isNotEmpty(c) && !isNumber(c)) {
        if (typeof c === "string") {
            c = c.toUpperCase();
        }else if (isDate(c)) {
            c = parseDate(c);
        }
    }

    if (isNotEmpty(d) && !isNumber(d)) {
        if (typeof d === "string") {
            d = d.toUpperCase();
        } else if (isDate(d)) {
            d = parseDate(d);
        }
    }

    if (d < c) {
        return -1;
    }

    if (d > c) {
        return 1;
    }

    return 0;
}

function stableSort(array, cmp) {

    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

function EnhancedTableHead(props) {
    const { classes,  order, orderBy,  onRequestSort, onRequestFilter } = props;
    const createSortHandler = property => event => {
        onRequestSort(event, property);
    };

    const onFilter = property => event => {
        onRequestFilter(event.target.value, property);
    };

    return (
        <TableHead>
            <TableRow style={{borderBottom:'none'}}>                
                {headCells.map(headCell => (
                    <TableCell
                        key={headCell.id}
                        padding={headCell.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === headCell.id ? order : false}
                        style={{ maxWidth: headCell.width, paddingBottom:0 }}
                        autoFocus={headCell.id === 'location'}
                    >
                    <div> {headCell.sortable &&
                    <TableSortLabel                       
                        active={orderBy === headCell.id}
                        direction={order}
                        onClick={createSortHandler(headCell.id)}
                    >
                        <div>
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>) : null}</div>
                        </TableSortLabel>
                    }
                    {!headCell.sortable &&
                        <div>
                            {headCell.label}
                        </div>
                    }
                    </div>
                    </TableCell>
                ))}
            </TableRow>
            <TableRow className='filter-row' style={{borderTop:'none'}}>
                {headCells.map(headCell => (

                    <TableCell

                        key={headCell.id+1}
                        padding={headCell.disablePadding ? 'none' : 'default'}

                        style={{ minWidth: headCell.width, paddingBottom:0,borderTop:'none', paddingTop: 0}}
                        autoFocus={headCell.id === 'location'}
                    >
                        <div  style={{paddingRight:'.5rem',verticalAlign:'bottom'}}>
                            {headCell.id === 'action'? '' :
                                <TextField
                                    id="filterId"
                                    fullWidth
                                    autoFocus={headCell.id === 'location'}
                                    style={{height:'.7'}}
                                    placeholder={`Enter ${headCell.filterLable}`}
                                    margin="dense"
                                    variant="outlined"
                                    onChange={onFilter(headCell.fid)}/>
                            }
                        </div>

                    </TableCell>
                 ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = () => {

    return ({}
    );
};

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
};

const useStyles1 = makeStyles(theme => ({
    root: {
      flexShrink: 0,
      marginLeft: theme.spacing(2.5),     
    },   
  }));

function TablePaginationActions(props) {
    const classes = useStyles1();
    const theme = useTheme();

    const { count, page, rowsPerPage, onChangePage } = props;

    const handleFirstPageButtonClick = event => {
        onChangePage(event, 0);
    };

    const handleBackButtonClick = event => {
        onChangePage(event, page - 1);
    };

    const handleNextButtonClick = event => {
        onChangePage(event, page + 1);
    };

    const handleLastPageButtonClick = event => {
        onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <div className={classes.root}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}               
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </div>
      );
}


const useStyles = theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(3),
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 150,
    },
    tableWrapper: {
        overflowX: 'auto',
    },
    visuallyHidden: {

        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
});

class FeeScheduleResultsTable extends Component{
    constructor(props) {
        super(props);
        this.state = {
            order: 'asc', orderBy: 'location',
            page: 0, rowsPerPage: 10          
        }
    }

    handleRequestFilter = (value, property) => {
        console.log(`filter value->${value} and the property->${property}`)


        if( value.length < 1){
            this.props.setFilter(this.props.rateCodes)
            return true;
        }else{
            this.props.setFilter(this.props.rateCodes.filter(rateCode => {
                let cValue = value.toUpperCase();
                let pValue = rateCode[property];

                if(  pValue && pValue.toUpperCase().search(cValue) !== -1) {
                    this.setState({ page: 0 });
                    return rateCode[property];
                }
                return false;
            }))
        }

    }
    handleRequestSort = (event, property) => {
        const isDesc = this.state.orderBy === property && this.state.order === 'desc';
        this.setState({order: isDesc ? 'asc' : 'desc', orderBy: property});
    }

    handleChangePage = (event, newPage) => {
        this.setState({page: newPage})
    }

    handleChangeRowsPerPage = event => {
        this.setState({page: 0, rowsPerPage: event.target.value})
    }

    isSelected = name => this.props.selected.indexOf(name) !== -1;

    render() {
        const {classes} = this.props;

        return (
            <div className="cvsTable">
                <div className={classes.root}>
                    <Paper className={classes.paper}>

                        <div className={classes.tableWrapper}>


                            <Table
                                className={classes.table}
                                aria-labelledby="tableTitle"
                                size='small'
                                aria-label="Rate Codes Results table"
                            >
                                <TableHead> <TablePagination
                                    className='Fee-schedule'
                                    rowsPerPageOptions={[5, 10, 25, 50, 100, 200]}

                                    count={this.props.filteredRateCodes.length}
                                    rowsPerPage={this.state.rowsPerPage}
                                    page={this.state.page}
                                    ActionsComponent={TablePaginationActions}
                                    onChangePage={this.handleChangePage}
                                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                /></TableHead>

                                <EnhancedTableHead
                                classes={classes}
                                order={this.state.order}
                                orderBy={this.state.orderBy}                                
                                onRequestSort={this.handleRequestSort}
                                onRequestFilter={this.handleRequestFilter}
                                rowCount={this.props.filteredRateCodes.length}
                            />
                            <TableBody>
                                {stableSort(this.props.filteredRateCodes, getSorting(this.state.order, this.state.orderBy))
                                .slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)
                                .map((row, index) => {
                                   
                                    const labelId = `enhanced-table-Select-${index}`;

                                    return (
                                        <TableRow
                                            hover
                                            tabIndex={-1}
                                            key={index}
                                            style={{
                                                padding: '5px 20px',
                                                height: 25,
                                            }}
                                          >

                                            <TableCell component="th" id={labelId} scope="row" padding="none" size='small'
                                                align="right" style={{width:'16%'}}>
                                                {row.nationalRateCd}
                                            </TableCell>
                                            <TableCell component="th" id={labelId} scope="row" padding="none" 
                                                size='small' style={{width:'50%'}}>
                                                {row.provLctnName} <br/>
                                                {row.provLctnAddr} <br/>
                                                {row.provLctnDetails}
                                            </TableCell>                                 
                                            <TableCell component="th" id={labelId} scope="row" padding="none" 
                                                size='small' style={{width:'20%'}}>
                                            <Button disabled={isNotEmpty(AuthenticationService.getMirrorUser())}  onClick={() => {
                                                            this.props.handleClickAction(row.nationalRateCd,
                                                                 row.lctnZipCd,
                                                                row.cpdProvId,
                                                                row.provLctnName);
                                                        }}>Select</Button>                                          
                                            </TableCell>

                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                            <TableFooter>
                                <TableRow>
                                    <TablePagination
                                        rowsPerPageOptions={[5, 10, 25, 50, 100, 200]}                                    
                                        count={this.props.filteredRateCodes.length}
                                        rowsPerPage={this.state.rowsPerPage}
                                        page={this.state.page}
                                        ActionsComponent={TablePaginationActions}
                                        onChangePage={this.handleChangePage}
                                        onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                    />
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </div>               
                </Paper>
            </div>                                
        </div>
    );
  }

}
export default withStyles(useStyles)(FeeScheduleResultsTable);