import axios from 'axios';
import { config } from "./ConfigService";

class NotificationService {

    getNotifications = () => {
        return axios.get(`${config.API_URL}/notifications/list`);
    }

    getUserNotifications = (id) => {
        return axios.get(`${config.API_URL}/notifications/user/${id}`);
    }
    setUserViewed = (id) => {
         return axios.patch(`${config.API_URL}/notifications/viewed/${id}`);
    }

    deleteNotification = (id) => {
        return axios.delete(`${config.API_URL}/notifications/delete/${id}`);
    }

    deleteUserNotification = (id) => {
        return axios.delete(`${config.API_URL}/notifications/deleteUser/${id}`);
    }

    create = (notification) => {
        return axios.put(`${config.API_URL}/notifications/create`,  notification )
    }

    update = (notification) => {
        return axios.put(`${config.API_URL}/notifications/update`,  notification )
    }

}

export default new NotificationService();
