import React, {Fragment} from 'react';
import './Footer.css'
import IconButton from '@material-ui/core/IconButton';
import ArrowUpwardTwoToneIcon from '@material-ui/icons/ArrowUpwardTwoTone';
import * as versionDtls from '../../utils/build';


const footer = (props) => {
    
    return (
        <Fragment>
            <footer  className='Footer '>
                <div className='App-ltgrey-bkgrnd Footer App-font-bold' >
                    <div style={{padding: '1%',color: '#0f197d'}}>
                        <a className='App-link'
                           href='https://www.enlyte.com/terms-use'
                           aria-label='Opens Terms & Conditions in a new window' target="_blank" rel="noopener noreferrer">Terms and
                            Conditions</a>
                        <span style={{paddingLeft: '1em',paddingRight:'1em', color: '#0f197d'}}>|</span>
                        <a className='App-link'
                           href='https://www.enlyte.com/privacy-policy'
                           rel="noopener noreferrer" aria-label='Opens Privacy Policy in a new window' target="_blank">Privacy Policy</a>
                        <span style={{paddingLeft: '1em',paddingRight:'1em', color: '#0f197d'}}>|</span>

                        <IconButton disableFocusRipple className='App-link' aria-label='Goes to the top of the page' onClick={props.scrollUp} >
                        <ArrowUpwardTwoToneIcon alt="Up arrow" />

                            Go to top
                        </IconButton>
                    </div>
                </div>
               <div className="footer-more">
                    <div className="copyright">
                        &copy; {new Date().getFullYear()} Coventry Health Care Workers Compensation, Inc., All rights reserved.
                    </div>
                    <div className="version">Ver {versionDtls.buildNumber}</div>
                </div>
            </footer>

        </Fragment>
    );
}
export default footer;