import React, {Component, Fragment} from 'react';
import Grid from "@material-ui/core/Grid";
import { TextField, MenuItem, withStyles } from "@material-ui/core";
import AuthenticationService from "../../Services/AuthenticationService";
import { NavLink} from 'react-router-dom';
import Button from '@material-ui/core/Button';
import './UserProfile.css';
import { isNotEmpty,  isAlpha, isEmailAddress, validatePhone, hasLength, isAlphanumericWithSpace, isName} from '../../utils/Validator.js';
import LoadingMsgBar from "../LoadingMsgBar";
import GlobalMessage from "../GlobalMessage";

const styles = () => ({
    root: {
        width: '100%',
    },

});
window.analytics ={pageName:'User Profile'};
class UserProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {
           userDetailsInfo: null, firstName: '',  lastName: '',  middleInitial : '', phoneNumber : null, isPhoneNumValid : true, 
           emailAddress : '', enlyteEmpId : '', isAdmin : false, 
           securityQuestionList : [],  secQuestionId : 1,  
           securityAnswer: '', valid: true, securityQuestion : '',
           progMsg:'',successMsg: '', errorMsg: '', loadingMessage: '',
           isSaveEnabled: true
        }
        
        this.usrfirstnameInput = React.createRef();
    };

    addErrorMessage(fieldName, errorMessage) {
        this.setState({
            [fieldName]: errorMessage
        });
    }

    clearErrorMessage(fieldName) {
        this.setState({
            [fieldName]: ''
        });
    }

    // save any changes the user makes into the state variable so you can
    // get back to the state the user was in
    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checkecd : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    showLoadingIndicator(shouldShow) {
        this.setState({ loadingMessage: shouldShow ? `Please wait while we validate the data.` : ''});
    }

    clearErrorMessages() {
        const errorFieldList = [
           
            'firstNameError',
            'lastNameError',
            'middleInitialError',
            'phoneNumberError',
            'enlyteEmpIdError',
            'securityAnswerError',
            'emailAddressError',
            'securityQuestionError',
           
        ];

        errorFieldList.forEach(errorFieldName => {
            this.clearErrorMessage(errorFieldName);
        });
    }

    getFormData() {
        return {
         
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            middleInitial: this.state.middleInitial,
            phoneNumber: this.state.phoneNumber,
            employeeId: this.state.enlyteEmpId,
            securityAnswer: this.state.securityAnswer,
            emailAddress: this.state.emailAddress,
            securityQuestion: this.state.securityQuestion,
            
        }
    }

    handleOnBlurPhoneNum=(event) =>  {
        const changed = event.target.value;
        this.setState({phoneNumber : changed});
        if(changed.length < 10){
            this.setState({valid:false});
        }
        else{
            const phone = validatePhone(changed)
            if(phone.length < 10){
                this.setState({valid:false});
            }
            else
            {
                this.setState({phoneNumber : phone});
                this.setState({isPhoneNumValid:true, valid:true, phoneNumberError:''});
            }
        }
    }

    doSyntaxValidation() {
        let isValid = true;
        this.clearErrorMessages();

       /* if (!isAlpha(this.state.firstName)) {
            this.addErrorMessage("firstNameError", "Error: Enter First Name" );
            isValid = false;
        }
        if (!isAlpha(this.state.lastName)) {
            this.addErrorMessage("lastNameError","Error: Enter Last Name");
            isValid = false;
        }*/

        if (!isName(this.state.firstName) || !hasLength(this.state.firstName, 1, 40)) {
            this.addErrorMessage("firstNameError", "Error: Enter valid First Name" );
            isValid = false;
        }

        if (!isName(this.state.lastName) || !hasLength(this.state.lastName, 1, 40)) {
            this.addErrorMessage("lastNameError","Error: Enter valid Last Name");
            isValid = false;
        }

        if (isNotEmpty(this.state.middleInitial) && !isAlpha(this.state.middleInitial)) {
            this.addErrorMessage("middleInitialError","Error: Enter Middle Initial" );
           
        }

        if ( validatePhone (this.state.phoneNumber) === '') {
            this.addErrorMessage("phoneNumberError", "Error: Enter a valid numeric Phone Number");
            isValid = false;
        }

        if (!isAlphanumericWithSpace(this.state.securityAnswer) || !hasLength(this.state.securityAnswer, 1, 120)) {
            this.addErrorMessage("securityAnswerError","Error: Enter Secret Answer");
            isValid = false;
        }

        if (!isEmailAddress(this.state.emailAddress)) {
            this.addErrorMessage("emailAddressError","Error: Enter a valid Email Address");
            isValid = false;
        }
       
        return isValid;

    }

    
 

    handleSubmit = () => {
        this.setState({successMsg:'', errorMsg: ''}); 
        
       const isValid = this.doSyntaxValidation();
       this.setState({valid : isValid});
       if(isValid){
            this.setState({isSaveEnabled: false}); 
            this.setState({loadingMessage:`Please wait while we validate the data.`});
            this.updateUser();            
        }else{
            this.setState({isSaveEnabled: true}); 
            this.setState({errorMsg: `An error has occurred. Please address all fields marked with “Error”.`});
        }
      
    }


    updateUser()  {
        
        this.setState({progMsg: `Please wait while we update User.`, errorMsg:''});
        const usersecpref=[{"secQstnId":this.state.securityQuestion, "secVal":this.state.securityAnswer}]
        const plainPhoneNum =  this.state.phoneNumber.replace(/[^\d]/g, "");
        const newUserDetails = {"id":this.state.userDetailsInfo.id,"userNm":this.state.userDetailsInfo.userNm, "userFirstName":this.state.firstName, "userLastName":this.state.lastName, "userMidName":this.state.middleInitial,
         "emailAddr":this.state.emailAddress, "employeeId" : ((this.state.enlyteEmpId === "") ? null :this.state.enlyteEmpId), "phoneNum":plainPhoneNum, "userSecurityPreferences":usersecpref};
        
        AuthenticationService.updateUsrDtls(newUserDetails)
        .then(response => {
            console.log(`update data ${response} status--->${response.status}`)
            if(response.status === 200) {
                console.log(`update data success`)
            }
            this.setState({loadingMessage:''});
            this.setState({progMsg: '', errorMsg:''});
            this.setState({successMsg:`Your profile was successfully updated.`});
            this.setState({isSaveEnabled: true}); 
        }).catch((error) => {
       
            console.log(`update user from settings ${error.message}`);
            //let emailMsg =  error.response.data.message;
            let emailMsg = '';
            if(null!= error.response){
                if(null!=error.response.data){
                    if(error.response.data.message === 'INVALID_ENLYTE_EMPLOYEE_ID'){
                        this.setState({ errorMsg: `Error: An error has occurred. Please try again or contact Customer Service.`}); 
                        this.addErrorMessage("enlyteEmpIdError","Error: Enter a valid Enlyte Employee ID");
                    } else{
                        emailMsg =  error.response.data.message;
                    }
                }
            }
            if(isNotEmpty(emailMsg)){
                emailMsg = 'Error: ' + emailMsg;
                this.setState({loadingMessage:'', errorMsg: emailMsg});
            }else{
                this.setState({loadingMessage:'', errorMsg: `Error: An error has occurred. Please try again or contact Customer Service.`});
            }
            this.setState({isSaveEnabled: true}); 
        console.log(`update user ${error}`);
    })
      
    };

    setSecQuestion=(defaultSeqQuestion) => {
        const selected = defaultSeqQuestion;
        this.setState({defaultSeqQuestion:selected})
    }


    getUser = ()=> {
        this.setState({progMsg:'', errorMsg: ''});
        AuthenticationService.getUsrDtls(AuthenticationService.getLoggedInUserName())
            .then((response) => {
                this.setState({userDetailsInfo: response.data})                
                const fname = `${response.data.userFirstName !== null ? response.data.userFirstName :''}`;
                this.setState({firstName: fname});
                const lname = `${response.data.userLastName !== null ? response.data.userLastName :''}`;
                this.setState({lastName: lname});
                const midname = `${response.data.userMidName !== null ? response.data.userMidName :''}`;
                this.setState({middleInitial: midname});
                let phone = `${response.data.phoneNum !== null ? response.data.phoneNum :''}`;
                phone=validatePhone(phone)
                this.setState({phoneNumber: phone});
                const email = `${response.data.emailAddr !== null ? response.data.emailAddr :''}`;
                this.setState({emailAddress: email});
                const enlyteId = `${response.data.employeeId !== null ? response.data.employeeId :''}`;
                this.setState({enlyteEmpId: enlyteId});
                this.setState({userNm: response.data.userNm});
                if(response.data.enlyteEmployee === true){
                    this.setState({isAdmin : true});
                }else{
                    this.setState({isAdmin : false});
                }
                const questionArray=this.state.userDetailsInfo.userSecurityPreferences;
                console.log(questionArray);
                if(questionArray !== null){
                    questionArray.forEach(qstn => {                        
                        const qstnid = qstn.secQstnId;
                        this.setState({secQuestionId:qstnid});
                        const secAns=qstn.secVal;
                        this.setState({securityAnswer:secAns});
                        
                 });
                }
            }).catch((error) => {
            this.setState({progMsg:'', errorMsg: `Error: An error has occurred. Please try again or contact Customer Service.`})
            console.log('header getting usr dtl error:' + error);
        })
        this.loadSecurityQuestions();
        
    }

    loadSecurityQuestions() {
        const securityQuestionList = [];
        let questionList = null;

        AuthenticationService.getSecQuestionsForUserProfile().then((response) => {

            questionList = response.data;

            if (isNotEmpty(questionList)) {
                questionList.forEach(question => {
                    
                    securityQuestionList.push({
                        value: question.secQstnId,
                        label: question.secQstn
                    });
                   
                    if(question.secQstnId===this.state.secQuestionId){
                        this.setState({securityQuestion:question.secQstnId});
                    }
                });
            }

            this.setState({ securityQuestionList: securityQuestionList });
            
        }, (error) => {
            console.log(error);
            
            this.setState({progMsg:'', errorMsg: `Error: An error has occurred. Please try again or contact Customer Service.`})
            this.setState({ securityQuestionList: securityQuestionList });
        });
    }

    componentWillUnmount() {

    }
    

    componentDidMount() { 
        this.getUser();
        this.getFormData() ;
      
    };
    
    showHeader = () =>{
        return(
            <Grid container className='App-font'>
                <Grid item style={{width: '40%', textAlign: 'left'}}>
                    <div>
                    <h1 className='App-page-label'>User Profile</h1>
                    </div>
                </Grid>
                <Grid item style={{width: '60%', textAlign: 'right'}}>
                    <div >
                    <NavLink to='/wcpp/ChangePassword' aria-label='User Profile: Change Password Hyperlink'>
                    <span className='Password-Link'> CHANGE PASSWORD >>></span> </NavLink>  
                        </div>
                </Grid>
        </Grid>);
    }

    showSuccessErrorMessage = () =>{
        return(
            <>
                <GlobalMessage  message={this.state.errorMsg} />
                <GlobalMessage type ='success' message={this.state.successMsg} />
            <div className='App-note-Field App-error-font App-error-color'> Note: Fields marked with
            an asterisk(*) are required.
            </div>
            </>
        );
    }

    loadSecQuestions = () =>{
        return(
            <TextField name="securityQuestion" id="securityQuestion" label="Secret Question"
                error={isNotEmpty(this.state.securityQuestionError)} helperText={this.state.securityQuestionError}
                select
                value={this.state.securityQuestion}
                onChange={this.handleInputChange}
                fullWidth
                required
            >
            {this.state.securityQuestionList.map(question => (
                <MenuItem key={question.value} value={question.value}>
                    {question.label}
                </MenuItem>
            ))}
        </TextField>);
    }
    
    
    render() {
            
        return(            
            <Fragment>

                <div className='App-content'>
                    {this.showHeader()}
                <div>

                    <LoadingMsgBar loadMsg={this.state.loadingMessage}/>


                {this.showSuccessErrorMessage()}  
                <div className="step-main">
               
                    <Grid container direction="column">    
                  
  
                        <Grid item style={{width:'50%', textAlign:'left'}}>
                            <TextField id="firstName" name="firstName" label="First Name"
                                error={isNotEmpty(this.state.firstNameError)} helperText={this.state.firstNameError}  autoFocus 
                                value={this.state.firstName}
                                onChange={this.handleInputChange}
                                inputProps={{ maxLength: 40 }}
                                fullWidth
                                required
                            />
                     </Grid>

                     <Grid item style={{width:'50%', textAlign:'left'}}>
                         <TextField id="lastName" name="lastName" label="Last Name"
                             error={isNotEmpty(this.state.lastNameError)} helperText={this.state.lastNameError}
                             value={this.state.lastName}
                             onChange={this.handleInputChange}
                             inputProps={{ maxLength: 40 }}
                             fullWidth
                             required
                         />
                     </Grid>

                     <Grid item style={{width:'50%', textAlign:'left'}}>
                         <TextField id="middleInitial" name="middleInitial" label="Middle Initial"
                             error={isNotEmpty(this.state.middleInitialError)} helperText={this.state.middleInitialError}
                             value={this.state.middleInitial}
                             onChange={this.handleInputChange}
                             inputProps={{ maxLength: 1 }}
                             fullWidth
                         />
                     </Grid>

                     <Grid item style={{width:'50%', textAlign:'left'}}>
                         <TextField id="phoneNumber" name="phoneNumber" label="Phone Number"
                             error={isNotEmpty(this.state.phoneNumberError)} helperText={this.state.phoneNumberError}
                             value={this.state.phoneNumber}
                             onChange={this.handleInputChange} onBlur={this.handleOnBlurPhoneNum}
                             inputProps={{ maxLength: 15 }}
                             fullWidth
                             required
                         />
                     </Grid>

                     <Grid item style={{width:'50%', textAlign:'left'}}>
                         <TextField id="emailAddress" name="emailAddress" label="Email Address"
                             error={isNotEmpty(this.state.emailAddressError)} helperText={this.state.emailAddressError}
                             value={this.state.emailAddress}
                             onChange={this.handleInputChange}
                             inputProps={{ maxLength: 120 }}
                             fullWidth
                             required
                         />
                     </Grid>
                     <Grid item style={{width:'50%', textAlign:'left'}}>
                            <TextField id="userName" name="userName" label="User Name"
                                autoFocus 
                                value={this.state.userNm}
                                fullWidth disabled={true}
                            />
                     </Grid>

                     { this.state.isAdmin  ?
                     <Grid item style={{width:'50%', textAlign:'left'}}>
                         <TextField id="enlyteEmpId" name="enlyteEmpId" label="Enlyte Employee ID"
                             value={this.state.enlyteEmpId}
                             onChange={this.handleInputChange}
                             inputProps={{ maxLength: 120 }}
                             fullWidth
                             required
                             disabled={true}
                         />
                     </Grid> : null }
                     
                     <Grid item style={{width:'50%', textAlign:'left'}}>
                            {this.loadSecQuestions()}
                        </Grid>

                     <Grid item style={{width:'50%', textAlign:'left'}}>
                         <TextField id="securityAnswer" name="securityAnswer" label="Secret Answer"
                             error={isNotEmpty(this.state.securityAnswerError)} helperText={this.state.securityAnswerError}
                             value={this.state.securityAnswer}
                             onChange={this.handleInputChange}
                             inputProps={{ maxLength: 120 }}
                             fullWidth
                             required
                         />
                     </Grid>

                 </Grid>
</div>
   
   </div>
   <div className='Change-Password-btn-row'>
        <Button disabled={!this.state.isSaveEnabled} variant="contained" color="primary"  onClick={this.handleSubmit } aria-label='User Profile: SAVE Button'>SAVE</Button>
                       
    </div>

    </div>  
</Fragment>);
    }
}
export default withStyles(styles)(UserProfile);