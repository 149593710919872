import React, {Component, Fragment} from 'react'
import {withStyles} from "@material-ui/core/styles";
import GlobalMessage from "../GlobalMessage";
import ManageUsersTable from "../ManageUsersTable";
import AuthenticationService from "../../Services/AuthenticationService";
import LoadingMsgBar from "../LoadingMsgBar";
import './ManageUsers.css'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import {isNotEmpty, isNumeric} from "../../utils/Validator";
import {isEmpty} from "../../utils/Utils";
import Excel from "exceljs";
import FileSaver from "file-saver";



const styles = theme => ({
    root: {
        width: '100%',
    },
    button: {
        margin: theme.spacing.unit,    
        cursor: 'pointer',
        color: '#0f197d'
    },
    input: {
      display: 'none',
    },
    
});

window.analytics ={pageName:'User Search'};
class UserSearch extends Component {
    constructor(props) {
        super(props);
        this.state = {
           fieldError: '',fieldGidError:'', userName: '', email:'', tin:'',successMsg: '',groupId:'',
            progMsg: '', errorMsg: '',users: [],filteredUsers: [], mirrorDlgOpen: false,
            selectedUser:null, resendReg: false, searchInfo: null, terminate: false, isDownloadEnabled: false, filtered:false
        }
    }

    componentDidMount() {

        if( this.props.location.state && this.props.location.state.searchInfo !== null) {

           this.search(true, this.props.location.state.searchInfo);
        }
    }

    loadTable = () => {

    }

    startMirror = () => {
        this.handleClose();
        const user = this.state.selectedUser;

        this.props.setMirror(`Mirroring (${user.userFirstName} ${user.userLastName}) `,user);
        this.props.history.push('/wcpp/home');
    }

    handleSelectedAction = (userId, action) => {
        const selectedUser =  this.state.users.find((user) => user.id === userId);

       if(action === 'Reset Password'){ 
            this.setState ({user: selectedUser})
            this.handleClickResetPwdOpen(selectedUser);                         
        }  else  if(action === 'Mirror'){
           this.setState({mirrorDlgOpen: true, selectedUser: selectedUser});

       } else if(action ==='Resend Registration') {
           this.setState ({selectedUser: selectedUser, resendReg: true})
       } else if(action === 'Edit Profile') {
           this.props.history.push({
               pathname: '/wcpp/EditProfile',
               state: {user: selectedUser, admin:true, searchInfo: this.state.searchInfo}
           })
       } else if(action === 'Terminate'){
           this.setState ({selectedUser: selectedUser, terminate: true})

       }
    }

    handleTerminate = () => {
        this.setState({terminate:false });
        let userDtl = this.state.selectedUser;
        userDtl.mgrUserInfoId = this.props.lgUser.id;

        AuthenticationService.terminate(userDtl)
            .then(response => {

                if(response.status === 200 && response.data===1) {
                    this.search(true, this.state.searchInfo);
                    this.setState({successmessage:'The selected account was successfully terminated.'})
                }else if(response.status === 200 && response.data===600) {
                    this.setState({errorMsg:'Error: This account cannot be terminated because there is not another Admin in the group.'})
                }else{

                    this.setState({errorMsg:'Error: An error has occurred.  Please try again or contact Customer Service.'})
                }
            }).catch((error) => {
            console.log(error);

            this.setState({errorMsg:'An error has occurred. Please try again or contact Customer Service.'})
        })

    }

    handleClickResetPwdOpen = (user) => {
        this.setState({resetpwd:true}) ;
        this.setState({successmessage: '' });
        this.setState({dialogTitle: 'Are you sure you want to send a new password to ' +
        user.userFirstName + ' ' + user.userLastName+'?' });
     };
 
     resetMessages = () => {
         this.setState({resetpwd:false}) ;
         this.setState({dialogTitle: ''});
         this.setState({filteredUsers:[]});
         this.setState({successmessage: '' });
         this.setState({errorMsg: '' });                          
     };

     handleClickResetPwdClose = () => {
        this.resetMessages();
        this.search(true);                   
    };


    handleResetPwd = () =>{  
        if(null === this.state.user.emailAddr || this.state.user.emailAddr === '')      {
            this.resetMessages();     
            this.search(false);         
            this.setState({errorMsg : 'The email address is blank for the selected user.'})           
        }else{
            AuthenticationService.resetPassword(this.state.user.userNm,this.state.user.emailAddr,AuthenticationService.getLoggedInUserName())
            .then(response => {                
                if(response.status === 200) {
                    this.resetMessages();  
                    this.search(true);                    
                    this.setState({successmessage:'The selected account was successfully updated.'}) 
                }else{
                    this.resetMessages();   
                    this.search(true);                   
                    this.setState({errorMsg:'Error: An error has occurred.  Please try again or contact Customer Service'}) 
                }
            }).catch((error) => {   
            		console.log(error);
                    this.resetMessages();
                    this.search(true);  
                    this.setState({errorMsg:'Error: An error has occurred.  Please try again or contact Customer Service'}) 		
        })
        }
       
    };

    handleReset = () => {
        this.setState({fieldError: '', fieldGidError: '', userName: '', email:'', tin:'', groupId:'',
            progMsg: '', errorMsg: '',users: [],filteredUsers: [],successmessage:'',filtered:false })
    }

    search = (resetErrorMsg, searchInfo) => {

        if(!searchInfo)
          searchInfo = {userName: this.state.userName, email: this.state.email, tin: this.state.tin, typeCD: this.props.typeCD, groupId:this.state.groupId};
        else this.setState({userName: searchInfo.userName, email: searchInfo.email, tin: searchInfo.tin, groupId: searchInfo.groupId})
        this.setState({progMsg:`Please wait while we retrieve data.`,fieldError: '',fieldGidError:'',
            errorMsg: '',users: [],filteredUsers: [],successmessage: '', searchInfo: searchInfo,filtered:false } )

        AuthenticationService.searchUser(searchInfo.userName, searchInfo.email, searchInfo.tin, searchInfo.typeCD, searchInfo.groupId)
            .then(response => {

                if(response.status === 200 ) {
                    this.setState({users: response.data,filteredUsers: response.data , progMsg:'', isDownloadEnabled:true});
                    if(resetErrorMsg){
                        this.setState({errorMsg: response.data.length === 0 ?
                                `There are no users available for the criteria entered. Please revise your search criteria and try again or go to ADD USER.`:''});

                    }

                }
            }).catch((error) => {
            this.setState({errorMsg:`Error: There was an error retrieving data. Please try again or contact Customer Service.`, progMsg:''});
            console.log(`Search For User Error: ${error}`);
        })
    }

    handleSearch = () => {
        if( this.state.email === '' && this.state.userName === '' && this.state.tin === '' && this.state.groupId === '') {
            this.setState({fieldError:'Error: Enter Username or Email Address or Tax ID Number (TIN) or Group ID',
                errorMsg: `An error has occurred. Please address all fields marked with “Error".`});
        } else if(this.state.userName !== '' && this.state.userName.length < 3){
            this.setState({fieldError:'Error: Enter a minimum of 3 characters in username field',
                errorMsg: `An error has occurred. Please address all fields marked with “Error".`})
        } else if(this.state.groupId !== '' && !isNumeric(this.state.groupId)){
            this.setState({fieldGidError:'Error: Enter numeric only for Group ID field',
                      errorMsg: `An error has occurred. Please address all fields marked with “Error".`})
}       else {
            this.search(true);
        }

    }

    onChange = event => {
        const { target: { name, value } } = event;

        this.setState({[name] :value });
    }

    setfilter = (filtered) => {

        if(filtered && filtered.length > 0)
            this.setState({filteredUsers:filtered, filtered:true})
        else this.setState({filtered:false});
    }

    getExportData = () => {
        if(this.state.filtered && this.state.filteredUsers.length > 0) {
            return this.state.filteredUsers;
        } else {
            return this.state.users;
        }
    }

    getMirrorName = () => {
       if(this.state.selectedUser !== null) {
           return `${this.state.selectedUser.userFirstName} ${this.state.selectedUser.userLastName}`;
       } else return '';
    }

    handleClose = () => {
        this.setState({mirrorDlgOpen: false, selectedUser: null});
    }

    handleResendRegistration = () =>{

        this.setState({resendReg:false});
        if(null === this.state.selectedUser.emailAddr )      {
            this.setState({errorMsg : 'The email address is blank for the selected user.'})
        } else{
            AuthenticationService.resendRegistration(AuthenticationService.getLoggedInUserName(),this.state.selectedUser.emailAddr)
                .then(response => {
                    if(response.status === 200) {

                        this.setState({successmessage:'The Registration Emails were resent to the selected account.', selectedUser: null})
                    }else{

                        this.setState({errorMsg:'Error: An error has occurred.  Please try again or contact Customer Service', selectedUser: null})
                    }
                }).catch((error) => {
                console.log(error);

                this.setState({errorMsg:'Error: An error has occurred.  Please try again or contact Customer Service'})
            })
        }

    };

    getTerminateMsg = () => {
        let msgStart = 'Are you sure that you want to Terminate';

        return(`${msgStart} ${this.state.selectedUser.userFirstName} ${this.state.selectedUser.userLastName}'s account?`);
    }

    getResendRegmsg = () => {
        let msgStart = 'Are you sure you want to send another registration email to';

        return(`${msgStart} ${this.state.selectedUser.userFirstName} ${this.state.selectedUser.userLastName}?`);
    }

    getSearchFieldDisplay = () => {
        let display ={desc:'', value:''};
        if(isNotEmpty(this.state.userName)) {
            display.desc =`User Name`;
            display.value =  this.state.userName;
        } else if(isNotEmpty(this.state.email)) {
            display.desc =`User Email`;
            display.value =  this.state.email;
        } else if(isNotEmpty(this.state.tin)) {
            display.desc =`User Tin`;
            display.value =  this.state.tin;
        }  else if(isNotEmpty(this.state.groupId)) {
            display.desc =`Group ID`;
            display.value =  this.state.groupId;
        }
        return display;
    }
    getDate = () => {

        // const month = new Date().getMonth() + 1; //Current Month
        // const year = new Date().getFullYear(); //Current Year
        // const hours = new Date().getHours(); //Current Hours
        // const min = new Date().getMinutes(); //Current Minutes
        // const amPm = new Date().getHours() % 12 || 12;
        // return `${month}/${new Date().getDate()}/${year} ${hours}:${min} ${amPm ? 'AM' : 'PM'}`;
        
        const today = Date.now();
        const formatedDate = new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(today);
        return `${formatedDate}`;
    }

    handleDownload = () => {
        this.setState({isDownloadEnabled: false, progMsg:`Please wait while we download User Search Table.`})
        console.log(`Look to see new update clicked download.....`)

        const workbook = new Excel.Workbook();
        const worksheet = workbook.addWorksheet("User Search");
        const display = this.getSearchFieldDisplay();
        worksheet.mergeCells('C1', 'D1');
        worksheet.getCell('C1').value = `User Search`;
        worksheet.getCell('C1').font = {bold: true, size: 16};
        worksheet.getRow(7).values = ['First Name', 'Last Name', 'Username',
            'Email Address', 'Account Status', 'Account Type', 'Group ID'];

        worksheet.getRow(3).values = [display.desc, display.value];
        worksheet.getRow(4).values = ['Date', this.getDate() ];
        for (let i = 3; i <5; i++)
        worksheet.getCell('A' + i).font = {bold: true};


        worksheet.columns = [
            {key: 'userFirstName', width: 25},
            {key: 'userLastName', width: 25},
            {key: 'userNm', width: 25},
            {key: 'emailAddr', width: 45},
            {key: 'statusCd', width: 14},
            {key: 'typeCd', width: 14},
            {key: 'grpInfoId', width: 12}

        ]

        this.getExportData().forEach((user) => {
            worksheet.addRow(user)
        })
        worksheet.getRow(7).eachCell((cell) => {
            cell.font = {bold: true};
            cell.border = {
                top: {style: 'thin'}, bottom: {style: 'double'},
            };
        });

        workbook.xlsx.writeBuffer()
            .then(buffer => {
                FileSaver.saveAs(new Blob([buffer]), `UserSearch.xlsx`)
            })
            .catch(err => {
                this.setState({isDownloadEnabled: true, progMsg:``});
                console.log('Error writing excel export', err)
            })
        setTimeout(()=> {this.setState({isDownloadEnabled: true, progMsg:``})},500);
    }
    render() {
        const { classes } = this.props;

        return(

            <Fragment>

                <div>
                    <Dialog
                    open={this.state.resetpwd}
                    onClose={this.state.handleClickResetPwdClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    > 
                    <DialogTitle id="alert-dialog-title">{this.state.dialogTitle}
                    </DialogTitle>                       
                    <DialogActions>   
                        <Button className={classes.button} variant="text" autoFocus onClick={this.handleResetPwd}  >
                            SEND EMAIL</Button>
                        <Button variant="text" className={classes.button} onClick={this.handleClickResetPwdClose} >
                            CANCEL</Button>                          
                    </DialogActions>
                    </Dialog>
                    <Dialog
                        open={this.state.mirrorDlgOpen}
                        onClose={!this.state.mirrorDlgOpen}
                        aria-labelledby="mirror-user-dialog-title"
                        aria-describedby="mirror-user-dialog-description"
                    >
                        <DialogTitle id="mirror-dialog-title">{`Are you sure you want to Mirror ${this.getMirrorName()}?`}
                        </DialogTitle>
                        <DialogActions>
                            <Button variant="text" className={classes.button} autoFocus onClick={this.startMirror}  >
                                YES</Button>
                            <Button variant="text" className={classes.button} onClick={this.handleClose} >
                                CANCEL</Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog
                        open={this.state.resendReg}
                        onClose={() =>{console.log(`resend reg dlg closing`)}}
                        aria-labelledby="Resend-Registration-dialog-title"
                        aria-describedby="Resend-Registration-dialog-description"
                    >
                        <DialogTitle id="Resend-Registration-dialog-title">{this.state.selectedUser !== null ? this.getResendRegmsg() : ''}
                        </DialogTitle>
                        <DialogActions>
                            <Button  disabled={isNotEmpty(AuthenticationService.getMirrorUser())} variant="text" autoFocus onClick={this.handleResendRegistration}  >
                                SEND EMAIL</Button>
                            <Button variant="text"  onClick={() =>{this.setState({resendReg:false});}} >
                                CANCEL</Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog
                        open={this.state.terminate}
                        onClose={() =>{console.log(`terminate dlg closing`)}}
                        aria-labelledby="Terminate-user-dialog-title"
                        aria-describedby="Terminate-user-dialog-description"
                    >
                        <DialogTitle id="Terminate-user-dialog-title">{this.state.selectedUser !== null ? this.getTerminateMsg() : ''}
                        </DialogTitle>
                        <DialogActions>
                            <Button  disabled={isNotEmpty(AuthenticationService.getMirrorUser())} variant="text" autoFocus onClick={this.handleTerminate}  >
                                TERMINATE</Button>
                            <Button variant="text"  onClick={() =>{this.setState({terminate:false});}} >
                                CANCEL</Button>
                        </DialogActions>
                    </Dialog>
                </div>
                <div  className='App-content Manage-users'>
                    <h1 className='App-page-label'>User Search</h1>

                    <LoadingMsgBar loadMsg={this.state.progMsg}/>

                    <GlobalMessage message={this.state.errorMsg}/>

                    <GlobalMessage type='success' message={this.state.successmessage}/>
                    <div
                        style={{paddingTop: '.5rem', paddingBottom:'1rem'}}>{`Search for users by entering a Username or Email Address or Tax ID Number (TIN) or Group ID.`}</div>

                    <Tooltip  className='App-tool-tip' title={isEmpty(this.state.users) ? 'Enter at least three characters for a partial search':''} disableHoverListener placement='bottom-start'
                             aria-label="Enter at least three characters for a partial search">


                        <TextField
                            id="outlined-basic"
                            name='userName'
                            autoFocus
                            disabled={this.state.email !== ''  || this.state.tin !== ''|| this.state.groupId !== ''}
                            style={{maxWidth:'25%'}}
                            error={this.state.fieldError !== ''}
                            helperText={this.state.fieldError}
                            inputProps={{maxLength: 14}}
                            defaultValue=" "
                            label="Username"
                            margin="normal"
                            variant="outlined"
                            onChange={this.onChange}
                            value={this.state.userName}
                        />
                    </Tooltip>

                        <div style={{
                            display: 'inline',
                            paddingRight: '2%',
                            paddingLeft: '2%',
                            verticalAlign: '-3em',
                            fontWeight: 'bold'
                        }}>OR
                        </div>


                        <TextField
                            id="outlined-basic"
                            name='email'
                            disabled={this.state.userName !== ''  || this.state.tin !== '' || this.state.groupId !== ''}
                            style={{maxWidth:'25%'}}
                            inputProps={{maxLength: 120}}
                            defaultValue=" "
                            label="Email Address"
                            margin="normal"
                            variant="outlined"
                            onChange={this.onChange}
                            value={this.state.email}
                        />

                        <div style={{
                            display: 'inline',
                            paddingRight: '2%',
                            paddingLeft: '2%',
                            verticalAlign: '-3em',
                            fontWeight: 'bold'
                        }}>OR
                        </div>
                        <TextField
                            id="outlined-basic"
                            name='tin'
                            disabled={this.state.email !== ''  || this.state.userName !== '' || this.state.groupId !== ''}
                            style={{maxWidth:'25%'}}
                            inputProps={{maxLength: 9}}
                            defaultValue=" "
                            label="Tax ID Number (TIN)"
                            margin="normal"
                            variant="outlined"
                            onChange={this.onChange}
                            value={this.state.tin}
                        />
                    <div style={{
                        display: 'inline',
                        paddingRight: '2%',
                        paddingLeft: '2%',
                        verticalAlign: '-3em',
                        fontWeight: 'bold'
                    }}>OR
                    </div>
                    <TextField
                        id="outlined-basic"
                        name='groupId'
                        error={this.state.fieldGidError !== ''}
                        helperText={this.state.fieldGidError}
                        disabled={this.state.email !== ''  || this.state.userName !== '' || this.state.tin !== ''}
                        style={{maxWidth:'25%'}}
                        inputProps={{maxLength: 9}}
                        defaultValue=" "
                        label="Group ID"
                        margin="normal"
                        variant="outlined"
                        onChange={this.onChange}
                        value={this.state.groupId}
                    />

                    <div className='Manage-tins-btn-ln'>
                        <Button onClick={this.handleSearch}>
                            SEARCH
                        </Button>

                        <Button variant="outlined" onClick={this.handleReset}>
                            RESET
                        </Button>
                    </div>
                    <div />

                    {this.state.users.length === 0 ? '' :
                        <div>
                            <div   style={{textAlign:'right', paddingRight:'2%', paddingTop:'1%'}}>

                                <Button disabled={!this.state.isDownloadEnabled} variant="outlined" onClick={this.handleDownload } style={{paddingRight:10, width:'15%'}}>
                                    DOWNLOAD
                                </Button>
                            </div>
                        <ManageUsersTable users={this.state.filteredUsers} setFilter={this.setfilter} isUserSearch={true}
                                          unfilteredUsers={this.state.users} userType={this.props.typeCD}
                                          handleSelectAction={this.handleSelectedAction} searchInfo={this.state.searchInfo}/>
                        </div>
                    }
                    </div>


            </Fragment>

        );

    }
}
export default withStyles(styles)(UserSearch);