import { parseDate } from './Utils.js';
import { isNotEmpty, isEmpty } from './Validator.js';

// https://blog.abelotech.com/posts/number-currency-formatting-javascript/
export function currencyFormat(dollars) {
    if (isEmpty(dollars)) {
        return "";
    } else if (typeof dollars === "number") {
        return '$' + dollars.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    } else {
        return dollars;
    }
}

export function formatPhoneNumber(value) {
    //normalize string and remove all unnecessary characters
    let formattedPhone = value;
    let phone = null;

    if (isEmpty(value)) {
        return "";
    }

    phone = value.replace(/[^\d]/g, "");

    if (phone.length === 10) {
        formattedPhone = phone.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
    }

    return formattedPhone;
}

// Formats date into m/d/yyyy format
export function formatDate(value) {
    let date = null;
    let formattedDate = value;

    if (value instanceof Date) {
        date = value;
    } else if (isNotEmpty(value)) {
        date = parseDate(value.trim());
    }

    if (date !== null) {
        formattedDate = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
    }

    return formattedDate;
}
