import React, {Component, Fragment} from 'react'
import {withStyles} from "@material-ui/core/styles";
import './ManageUsers.css'
import ManageUsersTable from '../ManageUsersTable'
import AuthenticationService from "../../Services/AuthenticationService";
import {NavLink} from 'react-router-dom'
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import GlobalMessage from "../GlobalMessage";
import {isEmpty} from "../../utils/Utils";
import {isNotEmpty} from "../../utils/Validator";


const styles = theme => ({
    root: {
        width: '100%',
    },
    button: {
        margin: theme.spacing.unit,
        cursor: 'pointer',
        color: '#0f197d'
    },
    input: {
        display: 'none',
    },

});
window.analytics ={pageName:'Manage Users: List View'};
class ManageUsers extends Component {

    constructor(props) {
        super(props);
        this.state = {
            users: [],filteredUsers: [], selected:[], user: null, adminOpen:false,
            staffOpen:false, terminateOpen:false,resetpwd:false,
            resendReg:false,
            dialogTitle: '',
            adminGrpId: null, errorMsg: '',
            successmessage: ''
        }

    }

    handleClickAdminOpen = (user) => {
        this.setState({adminOpen:true}) ;
        this.setState({staffOpen:false}) ;
        this.setState({terminateOpen:false});
        this.setState({resetpwd:false}) ;
        this.setState({resendReg:false}) ;
        this.setState({successmessage: '' });
        this.setState({dialogTitle: 'Are you sure you want to convert ' +
                user.userFirstName + ' ' + user.userLastName
                + ' to Provider Admin?'});

    };

    handleAdminClose = () => {
        this.setState({adminOpen:false}) ;
        this.setState({staffOpen:false}) ;
        this.setState({terminateOpen:false});
        this.setState({resetpwd:false}) ;
        this.setState({resendReg:false}) ;
        this.setState({dialogTitle: ''});
        this.setState({filteredUsers:[]});
        this.setState({successmessage: '' });
        this.loadList(this.state.adminGrpId);
    };

    handleClickStaffOpen = (user) => {
        this.setState({staffOpen:true}) ;
        this.setState({adminOpen:false}) ;
        this.setState({terminateOpen:false});
        this.setState({resetpwd:false}) ;
        this.setState({resendReg:false}) ;
        this.setState({successmessage: '' });
        this.setState({dialogTitle: 'Are you sure you want to convert ' +
                user.userFirstName + ' ' + user.userLastName
                + ' to Provider User?'});
    };

    handleStaffClose = () => {
        this.setState({staffOpen:false}) ;
        this.setState({adminOpen:false}) ;
        this.setState({terminateOpen:false});
        this.setState({resetpwd:false}) ;
        this.setState({resendReg:false}) ;
        this.setState({dialogTitle: ''});
        this.setState({filteredUsers:[]});
        this.setState({successmessage: '' });
        this.loadList(this.state.adminGrpId);
    };

    handleTerminateOpen = (user) => {
        this.setState({staffOpen:false}) ;
        this.setState({adminOpen:false}) ;
        this.setState({terminateOpen:true});
        this.setState({resetpwd:false}) ;
        this.setState({resendReg:false}) ;
        this.setState({successmessage: '' });
        this.setState({dialogTitle: 'Are you sure you want to terminate ' +
                user.userFirstName + ' ' + user.userLastName
                + '\'s account?'});
    };

    handleTerminateClose = () => {
        this.setState({staffOpen:false}) ;
        this.setState({adminOpen:false}) ;
        this.setState({terminateOpen:false})
        this.setState({resetpwd:false}) ;
        this.setState({resetpwd:false}) ;
        this.setState({resendReg:false}) ;
        this.setState({dialogTitle: ''});
        this.setState({filteredUsers:[]});
        this.setState({successmessage: '' });
        this.loadList(this.state.adminGrpId);
    };

    handleClickResetPwdOpen = (user) => {
        this.setState({adminOpen:false}) ;
        this.setState({staffOpen:false}) ;
        this.setState({terminateOpen:false});
        this.setState({resetpwd:true}) ;
        this.setState({resendReg:false}) ;
        this.setState({successmessage: '' });
        this.setState({dialogTitle: 'Are you sure you want to send a new password to ' +
                user.userFirstName + ' ' + user.userLastName+'?' });
    };

    handleClickResetPwdClose = () => {
        this.setState({adminOpen:false}) ;
        this.setState({staffOpen:false}) ;
        this.setState({terminateOpen:false});
        this.setState({resetpwd:false}) ;
        this.setState({resendReg:false}) ;
        this.setState({dialogTitle: ''});
        this.setState({filteredUsers:[]});
        this.setState({successmessage: '' });
        this.setState({errorMsg: '' });
        this.loadList(this.state.adminGrpId);
    };

    handleClickResendRegOpen = (user) => {
        this.setState({adminOpen:false}) ;
        this.setState({staffOpen:false}) ;
        this.setState({terminateOpen:false});
        this.setState({resetpwd:false}) ;
        this.setState({resendReg:true}) ;
        this.setState({successmessage: '' });
        this.setState({dialogTitle: 'Are you sure you want to send another registration email to ' +
                user.userFirstName + ' ' + user.userLastName+'?' });
    };

    handleClickResendRegClose = () => {
        this.setState({adminOpen:false}) ;
        this.setState({staffOpen:false}) ;
        this.setState({terminateOpen:false});
        this.setState({resetpwd:false}) ;
        this.setState({resendReg:false}) ;
        this.setState({dialogTitle: ''});
        this.setState({filteredUsers:[]});
        this.setState({successmessage: '' });
        this.setState({errorMsg: '' });
        this.loadList(this.state.adminGrpId);
    };

    handleTerminateOld = () => {
        AuthenticationService.terminateUser(AuthenticationService.getLoggedInUserName(),this.state.user.userNm)
            .then(response => {
                if(response.status === 200 && response.data===1) {
                    this.handleTerminateClose();
                    this.loadList(this.state.adminGrpId);
                    this.setState({successmessage:'The selected account was successfully terminated.'})
                }else{
                    this.handleTerminateClose();
                    this.loadList(this.state.adminGrpId);
                    this.setState({errorMsg:'Error: An error has occurred.  Please try again or contact Customer Service.'})
                }
            }).catch((error) => {
            console.log(error);
            this.handleTerminateClose();
            this.loadList(this.state.adminGrpId);
            this.setState({errorMsg:'An error has occurred.  Please try again or contact Customer Service.'})
        })
    }

    handleTerminate = () => {
        let userDtl = this.state.user;
        userDtl.mgrUserInfoId = this.props.lgUser.id;
        AuthenticationService.terminate(userDtl)
            .then(response => {
                if(response.status === 200 && response.data===1) {
                    this.handleTerminateClose();
                    this.loadList(this.state.adminGrpId);
                    this.setState({successmessage:'The selected account was successfully terminated.'})
                }else{
                    this.handleTerminateClose();
                    this.loadList(this.state.adminGrpId);
                    this.setState({errorMsg:'Error: An error has occurred.  Please try again or contact Customer Service.'})
                }
            }).catch((error) => {
            console.log(error);
            this.handleTerminateClose();
            this.loadList(this.state.adminGrpId);
            this.setState({errorMsg:'An error has occurred.  Please try again or contact Customer Service.'})
        })
    }

    handleAdminConversion = () =>{
        AuthenticationService.convertUserToAdmin(AuthenticationService.getLoggedInUserName(),this.state.user.userNm)
            .then(response => {
                if(response.status === 200 && response.data==='success') {
                    this.handleAdminClose();
                    this.loadList(this.state.adminGrpId);
                    this.setState({successmessage:'The selected account was successfully updated.'})
                }else{
                    this.handleAdminClose();
                    this.loadList(this.state.adminGrpId);
                    this.setState({errorMsg:'Error: An error has occurred.  Please try again or contact Customer Service.'})
                }

            }).catch((error) => {
            console.log(error);
            this.handleAdminClose();
            this.loadList(this.state.adminGrpId);
            this.setState({errorMsg:'Error: An error has occurred.  Please try again or contact Customer Service.'})
        })
    };

    handleStaffConversion = () =>{
        AuthenticationService.convertAdminToUser(AuthenticationService.getLoggedInUserName(),this.state.user.userNm)
            .then(response => {
                if(response.status === 200 && response.data==='success') {
                    this.handleStaffClose();
                    this.loadList(this.state.adminGrpId);
                    this.setState({successmessage:'The selected account was successfully updated.'})
                }else{
                    this.handleStaffClose();
                    this.loadList(this.state.adminGrpId);
                    this.setState({errorMsg:'Error: An error has occurred.  Please try again or contact Customer Service.'})
                }

            }).catch((error) => {
            console.log(error);
            this.handleStaffClose();
            this.loadList(this.state.adminGrpId);
            this.setState({errorMsg:'Error: An error has occurred.  Please try again or contact Customer Service.'})
        })
    };

    handleResetPwd = () =>{
        if(null === this.state.user.emailAddr || this.state.user.emailAddr === '')      {
            this.handleClickResetPwdClose();
            this.loadList(this.state.adminGrpId);
            this.setState({errorMsg : 'The email address is blank for the selected user.'})
        }else{
            AuthenticationService.resetPassword(this.state.user.userNm,this.state.user.emailAddr,AuthenticationService.getLoggedInUserName())
                .then(response => {
                    if(response.status === 200) {
                        this.handleClickResetPwdClose();
                        this.loadList(this.state.adminGrpId);
                        this.setState({successmessage:'The selected account was successfully updated.'})
                    }else{
                        this.handleClickResetPwdClose();
                        this.loadList(this.state.adminGrpId);
                        this.setState({errorMsg:'Error: An error has occurred.  Please try again or contact Customer Service'})
                    }
                }).catch((error) => {
                console.log(error);
                this.handleClickResetPwdClose();
                this.loadList(this.state.adminGrpId);
                this.setState({errorMsg:'Error: An error has occurred.  Please try again or contact Customer Service'})
            })
        }

    };

    handleResendRegistration = () =>{
        if(null === this.state.user.emailAddr || this.state.user.emailAddr === '')      {
            this.handleClickResendRegClose();
            this.loadList(this.state.adminGrpId);
            this.setState({errorMsg : 'The email address is blank for the selected user.'})
        }else{
            AuthenticationService.resendRegistration(AuthenticationService.getLoggedInUserName(),this.state.user.emailAddr)
                .then(response => {
                    if(response.status === 200) {
                        this.handleClickResendRegClose();
                        this.loadList(this.state.adminGrpId);
                        this.setState({successmessage:'The Registration Emails were resent to the selected account.'})
                    }else{
                        this.handleClickResendRegClose();
                        this.loadList(this.state.adminGrpId);
                        this.setState({errorMsg:'Error: An error has occurred.  Please try again or contact Customer Service'})
                    }
                }).catch((error) => {
                console.log(error);
                this.handleClickResendRegClose();
                this.loadList(this.state.adminGrpId);
                this.setState({errorMsg:'Error: An error has occurred.  Please try again or contact Customer Service'})
            })
        }

    };



    componentDidMount() {
        this.getUser()
    }

    loadList = (grpId) => {
        AuthenticationService.getManageUsersUsers(grpId, AuthenticationService.getLoggedInUserName())
            .then((response) => {
                if(response.status === 200) {
                    this.setState({users: response.data, filteredUsers: response.data})
                    // console.log(`manage users'===>${JSON.stringify(this.state.users)}`)

                } else {
                    this.setState({errorMsg: `Error: There was an error retrieving data. Please try again or contact Customer Service.`})
                }
            }).catch(error => {
            console.log('Mange Users Get user List Error:' + error);
            this.setState({errorMsg: `Error: There was an error retrieving data. Please try again or contact Customer Service.`})
        })
    }

    getUser = ()=> {
        let tinUser = AuthenticationService.getMirrorUser();
        if(isEmpty(tinUser)) {
            tinUser = AuthenticationService.getLoggedInUserName();
        }
        AuthenticationService.getUsrDtls(tinUser)
            .then((response) => {
                if(response.status === 200) {
                    this.setState({adminGrpId:response.data.grpInfoId})
                    this.loadList(response.data.grpInfoId)
                }else{
                    this.setState({adminGrpId:null})
                    this.setState({errorMsg: `Error: There was an error retrieving data. Please try again or contact Customer Service.`})
                }
            }).catch(error => {
            console.log('header getting usr dtl error:' + error);
            this.setState({errorMsg: `Error: There was an error retrieving data. Please try again or contact Customer Service.`})
        })
        return true
    }

    setFilter = (filtered) => {
        console.log( `in fitler set state`)
        if(filtered && filtered.length > 0)
            this.setState({filteredUsers:filtered})

    }

    handleSelectedAction = (userId, action) => {
        console.log(`handle user action beore edit prof==`);
        const selectedUser =  this.state.users.find((user) => user.id === userId);


        if (action === 'Edit Profile') {
            this.props.history.push({
                pathname: '/wcpp/EditProfile',
                state: {user: selectedUser, admin:false}
            })
        } else if (action === 'Manage TINs'){
            this.props.history.push({
                pathname: '/wcpp/ManageTinsForUser',
                state: {user: selectedUser}
            })
        }else if(action === 'Convert to Provider Admin'){
            this.setState ({user: selectedUser})
            this.handleClickAdminOpen(selectedUser);
        }else if(action === 'Convert to Provider User'){
            this.setState ({user: selectedUser})
            this.handleClickStaffOpen(selectedUser);
        }else if(action === 'Terminate'){
            this.setState ({user: selectedUser})
            this.handleTerminateOpen(selectedUser);
        }else if(action === 'Reset Password'){
            this.setState ({user: selectedUser})
            this.handleClickResetPwdOpen(selectedUser);
        }else if(action === 'Resend Registration'){
            this.setState ({user: selectedUser})
            this.handleClickResendRegOpen(selectedUser);
        }


    }

    render() {
        const { classes } = this.props;

        return(

            <Fragment>

                <div>
                    <Dialog
                        open={this.state.adminOpen}
                        onClose={this.state.handleAdminClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">{this.state.dialogTitle}
                        </DialogTitle>
                        <DialogActions>
                            <Button className={classes.button} disabled={isNotEmpty(AuthenticationService.getMirrorUser())}
                                    variant="text" autoFocus onClick={this.handleAdminConversion}  >
                                CONVERT TO PROVIDER ADMIN</Button>
                            <Button variant="text" className={classes.button} onClick={this.handleAdminClose} >
                                CANCEL</Button>
                        </DialogActions>
                    </Dialog>

                    <Dialog
                        open={this.state.staffOpen}
                        onClose={this.state.handleStaffClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">{this.state.dialogTitle}
                        </DialogTitle>
                        <DialogActions>
                            <Button className={classes.button} variant="text" autoFocus onClick={this.handleStaffConversion}
                                    disabled={isNotEmpty(AuthenticationService.getMirrorUser())}>
                                CONVERT TO PROVIDER USER</Button>
                            <Button variant="text" className={classes.button} onClick={this.handleStaffClose} >
                                CANCEL</Button>
                        </DialogActions>
                    </Dialog>

                    <Dialog
                        open={this.state.terminateOpen}
                        onClose={this.state.handleTerminateClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">{this.state.dialogTitle}
                        </DialogTitle>
                        <DialogActions>
                            <Button className={classes.button} variant="text"
                                    disabled={isNotEmpty(AuthenticationService.getMirrorUser())} autoFocus onClick={this.handleTerminate}  >
                                TERMINATE</Button>
                            <Button variant="text" className={classes.button} onClick={this.handleTerminateClose} >
                                CANCEL</Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog
                        open={this.state.resetpwd}
                        onClose={this.state.handleClickResetPwdClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">{this.state.dialogTitle}
                        </DialogTitle>
                        <DialogActions>
                            <Button className={classes.button} variant="text" disabled={isNotEmpty(AuthenticationService.getMirrorUser())}
                                    autoFocus onClick={this.handleResetPwd}  >
                                SEND EMAIL</Button>
                            <Button variant="text" className={classes.button} onClick={this.handleClickResetPwdClose} >
                                CANCEL</Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog
                        open={this.state.resendReg}
                        onClose={this.state.handleClickResendRegClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">{this.state.dialogTitle}
                        </DialogTitle>
                        <DialogActions>
                            <Button className={classes.button} disabled={isNotEmpty(AuthenticationService.getMirrorUser())} variant="text" autoFocus onClick={this.handleResendRegistration}  >
                                SEND EMAIL</Button>
                            <Button variant="text" className={classes.button} onClick={this.handleClickResendRegClose} >
                                CANCEL</Button>
                        </DialogActions>
                    </Dialog>

                </div>
                <div className='App-content Manage-users '>
                    <div><h1 className='App-page-label' style={{display:'inline-block'}}>Manage Users</h1>
                        <NavLink style={{display:'inline-block', float:'right', fontSize:'13px', fontWeight:'bold'}}
                                 className={isNotEmpty(AuthenticationService.getMirrorUser()) ? 'link-disabled' : ''} to='/wcpp/AddUser'>+ADD USER</NavLink></div>

                    <GlobalMessage  message={this.state.errorMsg} />

                    <GlobalMessage type ='success' message={this.state.successmessage} />
                    <div className='Manage-users-tbl-div'>
                        <ManageUsersTable users={this.state.filteredUsers} setFilter={this.setFilter} isUserSearch={false}
                                          unfilteredUsers={this.state.users} handleSelectAction={this.handleSelectedAction} />
                    </div>
                </div>
            </Fragment>

        );

    }
}
export default withStyles(styles)(ManageUsers);