export const ADD_ARTICLE = "ADD_ARTICLE";

const initialState = {
    articles: [],
    selectedClaim:'',
  };
  function rootReducer(state, action) {
	  state = initialState;
    if (action.type === ADD_ARTICLE) {
        return Object.assign({}, state, {
            articles: state.articles.concat(action.payload)
          });
      }
    return state;
  }
  
  export default rootReducer;