

import React, { Component } from 'react';
import {withStyles} from "@material-ui/core";
import { Button, Grid, TextField } from "@material-ui/core";
import { isNotEmpty, isEmpty, isDate, isFutureDate } from '../../utils/Validator.js';
import ClaimSearchService from "../../Services/ClaimSearchService";
import GlobalMessage from '../GlobalMessage';
import ClaimSearchResultsTable from './ClaimSearchResultsTable.js';
import {  parseDate } from '../../utils/Utils.js';
import LoadingMsgBar from "../LoadingMsgBar";
import './ClientList.css';
import {  setFocusOnFirstError } from '../../utils/Utils.js';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';


const styles = () => ({
    root: {
        width: '100%',
    },

});
window.analytics ={pageName:'Claim Search'};
class ClaimSearch extends Component {
    constructor(props) {
        super(props);

        this.state = this.initializeState();
    }


    // save any changes the user makes into the state variable so you can
    // get back to the state the user was in
    handleInputChange = (event) => {
        let target = event.target;
        let value = target.type && target.type === 'checkbox' ? target.checked : target.value;
        let name = target.name;

        this.setState({
            [name]: value
        });
    }

    validate = () => {
        this.setState({isSearchEnabled: false});
        let syntaxResults = this.doSyntaxValidation();

        if (!syntaxResults.isValid) {
            this.setGlobalErrorMessage('An error has occurred. Please address all fields marked with “Error”.');
            this.showMessages(syntaxResults.messageList);
            this.setState({isSearchEnabled: true});
        }

        return syntaxResults.isValid;
    }

    showMessages(messageList) {
        messageList.forEach(message => {
            this.addErrorMessage(message.field, message.message);
        });
    }

    addErrorMessage(fieldName, errorMessage) {
        this.setState({
            [fieldName]: errorMessage
        });
    }

    clearErrorMessage(fieldName) {
        this.setState({
            [fieldName]: ''
        });
    }

    showProcessingIndicator(shouldShow) {
        this.setState({ shouldshowProcessingIndicator: shouldShow });
    }

    componentDidUpdate() {
        if (this.state.wasSubmitted) {
            setFocusOnFirstError();
            this.setState({ wasSubmitted: false });
        }
    }

    initializeState() {

        const initialState = {
            shouldshowProcessingIndicator: false,
            processingMessage: '',
            wasSubmitted: false,
            firstName: '',
            lastName: '',
            dateOfBirth: null,
            dateOfInquiry: null,
            filteredClaims: [],
            showTable : false,
            isSearchEnabled: true,
            claimList: [],
            claimsFiltered: [],
            wildcardFirstName: false
        };

        return initialState;
    }

    setfilter = (filtered) => {
        console.log(`in fitler set state`)
        this.setState({claimsFiltered: filtered})

    }

    getFormData() {
        let wildCardFstName ='N';
        if((this.state.wildcardFirstName) === true){
            wildCardFstName ='Y';
        }

        return {
            claimantFirstName: this.state.firstName.trim(),
            claimantLastName: this.state.lastName.trim(),
            claimantDOB: this.state.dateOfBirth,
            doi:  this.state.dateOfInquiry,
            wildCardFstName: wildCardFstName
            //claimNumber : "004401020807WC01"
        }
    }

    clearForm() {
        this.clearFields();
        this.clearErrorMessages();
        this.setState({  filteredClaims: [], showTable: false});
    }

    clearFields() {
        this.setState({            
            firstName: '',
            lastName: '',
            dateOfBirth: null,
            dateOfInquiry: null  ,
            claimList : [],
            wildcardFirstName: true
        });
    }

    handleResetButtonOnClick = () => {
        this.clearForm();
    }

   

    handleExitedDateOfBirthField = () => {
      //  const formattedDate = formatDate(this.state.dateOfBirth);
       // this.setState({ dateOfBirth: formattedDate });
    }

    handleExitedDateOfInquiryField = () => {
       // const formattedDate = formatDate(this.state.dateOfInquiry);
        //this.setState({ dateOfInquiry: formattedDate });
    }
    
    handleChangeRowsPerPage = event => {
        this.setState({page: 0, rowsPerPage: event.target.value})
    }
    

    doSyntaxValidation() {
        let messageList = [];
        let isValid = false;

        if (isEmpty(this.state.firstName)) {
            messageList.push({ field: "firstNameError", message: "Error: Enter Claimant First Name" });
        }

        if (isEmpty(this.state.lastName)) {
            messageList.push({ field: "lastNameError", message: "Error: Enter Claimant Last Name" });
        }

        if (isEmpty(this.state.dateOfBirth)) {
            messageList.push({ field: "dateOfBirthError", message: "Error: Enter Claimant Date of Birth" });
        } else if (!isDate(this.state.dateOfBirth)) {
            messageList.push({ field: "dateOfBirthError", message: "Error: Enter a valid Claimant Date of Birth" });
        } else if (isFutureDate(this.state.dateOfBirth)) {
            messageList.push({ field: "dateOfBirthError", message: "Error: Claimant Date of Birth cannot be a future date" });
        }

        if (isEmpty(this.state.dateOfInquiry)) {
            messageList.push({ field: "dateOfInquiryError", message: "Error: Enter Claimant Date of Injury" });
        } else if (!isDate(this.state.dateOfInquiry)) {
            messageList.push({ field: "dateOfInquiryError", message: "Error: Enter a valid Claimant Date of Injury" });
        } else if (isFutureDate(this.state.dateOfInquiry)) {
            messageList.push({ field: "dateOfInquiryError", message: "Error: Claimant Date of Injury cannot be a future date" });
        }

        if (messageList.length === 0) {
            isValid = true;
        }

        return { isValid: isValid, messageList: messageList };
    }

    clearErrorMessages() {
        let errorFieldList = [        
            'firstNameError',
            'lastNameError',
            'dateOfBirthError',
            'dateOfInquiryError',
        ];

        errorFieldList.forEach(errorFieldName => {
            this.clearErrorMessage(errorFieldName);
        });

        this.clearGlobalErrorMessage();
    }

    handleSearchButtonOnClick = () => {      
        let searchParams = null;

        this.clearErrorMessages();
        this.setState({noDataMsg:''});

        // Used in setting focus on first error
        this.setState({ wasSubmitted: true });

        if (this.validate()) {
            searchParams = this.getFormData();
            this.doClaimSearch(searchParams);
        }
    }

    doClaimSearch = (searchParams) => {
        console.log("search Params: " + JSON.stringify(searchParams));
        this.showProcessingIndicator(true);        
        this.setState({  filteredClaims: [],claimList: [], showTable: false});
        this.setState({ processingMessage: 'Please wait while we process your request.'});      
        ClaimSearchService.findClaimsByClaimant(searchParams).then((response) => {
            //response is not a list, coming as 1 object
            this.setState({isSearchEnabled: true});
            var respdata = response.data;
            if(respdata.resultMessage != null){               
               this.setGlobalErrorMessage('Error: ' + respdata.resultMessage);
             } else{
                let claimResults = [];
                 if(respdata.claims !=null){                   
                    claimResults = respdata.claims;
                    if (isNotEmpty(claimResults)) {
                        claimResults.forEach(claim => {                  
                            this.state.claimList.push(claim);
                        });
                    }
                 }                
                var claims = this.parseClaims(this.state.claimList);
                this.setState({  filteredClaims: claims, showTable: true, claimsFiltered: claims});
               }   
            
            
            
            this.setState({ processingMessage: ''});
        }, (error) => {
            console.log(error);
            this.showProcessingIndicator(false);
            this.setState({ processingMessage: ''});
            this.setGlobalErrorMessage('Error: There was an error retrieving data. Please try again or contact Customer Service.');
            this.setState({  filteredClaims: [], isSearchEnabled: true, claimsFiltered: []});
        });
    }

    parseClaims = () => {
        var claimData = this.state.claimList;
        var claims =[];
        claimData.forEach(claim => {                  
            if(claim.claimantDOB != null){
                claim.claimantDOB = parseDate(claim.claimantDOB);
            }
            if(claim.claimantDOI != null){
                claim.claimantDOI = parseDate(claim.claimantDOI);
            }
            var fname = (claim.claimantFirstName != null) ? claim.claimantFirstName : '';
            var lname = (claim.claimantLastName != null) ? claim.claimantLastName : '';
            var name = fname + '  ' + lname; 
            if (claim.claimNumber != null)  {
                claims.push({
                        claimNumber: claim.claimNumber,
                        claimantName: name,
                        dob:claim.claimantDOB,
                        doi:claim.claimantDOI,
                        state:claim.stateOfJurisdiction,
                        gender: claim.claimantGender
                        });                        
                }
        });
      return claims; 

   }
    
    setGlobalErrorMessage(message) {
       this.setState({globalMessage:message});
    }

    clearGlobalErrorMessage() {
        this.setState({globalMessage:''});
    }

    showSuccessErrorMessage = () =>{
        return(
            <>
                <GlobalMessage  message={this.state.globalMessage} />
               
            <div className='App-note-Field App-error-font App-error-color'> Note: Fields marked with
            an asterisk(*) are required.
            </div>
            </>
        );
    }

    render() {
        return (
            <div className="providerSearchInputComponent App-content">

                 <h1 className='App-page-label'>Claim Search</h1>
                 <LoadingMsgBar loadMsg={this.state.processingMessage}/>
                

               {this.showSuccessErrorMessage()}
               <Grid container direction='column'  item xs={12}>
                <Grid container   spacing={3} style={{paddingBottom:'.75rem'}}>                  

                    <Grid item xs={12} md={5}>
                        <TextField id="firstName" name="firstName" label="Claimant First Name" required   
                            error={isNotEmpty(this.state.firstNameError)} helperText={this.state.firstNameError}
                            value={this.state.firstName}
                            onChange={this.handleInputChange}
                            inputProps={{ maxLength: 40 }}
                            fullWidth

                        />
                    </Grid>

                    {/*<Grid item xs={12} md={5} style={{paddingLeft:70, paddingTop:30}}>
                        <Tooltip title="A wildcard search allows you to search with a minimum of 3 leading characters of the first name. Unchecking this box requires an exact match on the first name." aria-label="A wildcard search allows you to search with a partial spelling of the first name. Unchecking this box requires an exact match on the first name."
                        placement='top-start'>
                            <FormControlLabel 
                                control={
                                    <Checkbox
                                    id="wildcardFirstName" name="wildcardFirstName"
                                        color="primary"
                                        checked={this.state.wildcardFirstName}
                                        onChange={this.handleInputChange}
                                    />
                                }
                                label="Wildcard Claimant First Name"
                            />
                            </Tooltip>

                            </Grid>*/}

                       
                        
                    
                    </Grid>
                    <Grid container   spacing={3} style={{paddingBottom:'.75rem'}}>
                    <Grid item xs={12} md={5}>
                    <TextField id="lastName" name="lastName" label="Claimant Last Name" required
                            error={isNotEmpty(this.state.lastNameError)} helperText={this.state.lastNameError}
                            value={this.state.lastName}
                            onChange={this.handleInputChange}
                            inputProps={{ maxLength: 40 }}
                            fullWidth
                        />
                        </Grid></Grid>
                    <Grid container   spacing={3} style={{paddingBottom:'.75rem'}}>
                    <Grid item xs={12} md={5}>

                        <MuiPickersUtilsProvider utils={DateFnsUtils}>

                                <KeyboardDatePicker
                                    error={isNotEmpty(this.state.dateOfBirthError)} helperText={this.state.dateOfBirthError}
                                    value={this.state.dateOfBirth}
                                    onChange={ date => {
                                        if(date instanceof Date) {
                                            date.setHours(8, 0, 0,0);
                                            this.setState({dateOfBirth: date});
                                        }
                                    }}
                                    format="MM/dd/yyyy"
                                    margin="normal"
                                    id="dateOfBirth"
                                    label="Claimant Date of Birth"
                                    fullWidth
                                    disableFuture
                                    placeholder='MM/DD/YYYY'
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                    required
                                />

                        </MuiPickersUtilsProvider>
                    </Grid>
                    </Grid>
                    <Grid container   spacing={3} style={{paddingBottom:'.75rem'}}>
                   
                    {/* (date) => this.setState({dateOfInquiry: date.toString()})*/}
                    <Grid item xs={12} md={5}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>

                            <KeyboardDatePicker
                                error={isNotEmpty(this.state.dateOfInquiryError)} helperText={this.state.dateOfInquiryError}
                                value={this.state.dateOfInquiry}
                                onChange={ date => {
                                    if(date instanceof Date) {
                                        date.setHours(8, 0, 0,0);
                                        this.setState({dateOfInquiry: date});
                                    }
                                }}

                                format="MM/dd/yyyy"
                                margin="normal"
                                id="dateOfInquiry"
                                label="Claimant Date of Injury"
                                fullWidth
                                disableFuture
                                placeholder='MM/DD/YYYY'
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                                required
                            />

                        </MuiPickersUtilsProvider>

                    </Grid>
                    </Grid>
</Grid>
                <div className='Manage-tins-btn-ln'>
                    <Button disabled={!this.state.isSearchEnabled} onClick={this.handleSearchButtonOnClick} aria-label='Claim Search: Claim SEARCH Button'>Search</Button>
                    <Button variant="outlined" onClick={this.handleResetButtonOnClick} aria-label='Claim Search: Claim RESET Button'>Reset</Button>
                </div>
               
                <div className='Manage-users-tbl-div'>
                  <div hidden={!this.state.showTable}   > 
                  <div className='Manage-tins-divider'/>            
                   <ClaimSearchResultsTable claims={this.state.filteredClaims}  claimsFiltered={this.state.claimsFiltered} setFilter={this.setfilter}
                                    />
                                    </div>
    
                </div>
                </div>
               
           
        );
    }
}
export default withStyles(styles)(ClaimSearch);